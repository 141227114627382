import React from "react";
import Typography from "@mui/material/Typography";

import PositionsService from "./service";
import ExpandableSection from "../../components/ExpandableSection";
import CertificateList from "../../components/Certificate/CertificateList";
import CourseStatusIcon from "../../components/CourseStatusIcon";

const PositionTitle = ({ position: { title, status } }) => (
  <>
    <CourseStatusIcon status={status} />
    <Typography variant={"body1"} style={{ marginLeft: ".5rem" }}>
      {title}
    </Typography>
  </>
);

const Positions = ({ certificates, courses, properties }) => {
  const positions = PositionsService.getPositions(
    properties,
    courses.courses,
    courses.customCourses,
    courses.groups,
    courses.relations,
  );

  const positionsWithStatus = PositionsService.toDisplayObject(positions, certificates, courses.relations);

  return (
    <>
      {positionsWithStatus.map((position, index) => (
        <ExpandableSection key={`position-${index}`} title={<PositionTitle position={position} />}>
          <CertificateList certificates={position.certificates} />
        </ExpandableSection>
      ))}
    </>
  );
};

export default Positions;
