import React from "react";
import { Route } from "react-router-dom";
import CourseTraining from "../../features/admin/course-training";
import EditTraining from "../../features/admin/course-training/edit";
import InscribeTraining from "../../features/admin/course-training/inscribe";
import SchedulEvent from "../../pages/schedule-event";
import UsersInscribedTraining from "../../pages/user-inscribed-training";
import WrittenTestTraining from "../../pages/written-test-training";
import AssessmentTraining from "../../pages/assessment-training";
import TrainerCheckingProgress from "../../pages/trainer-checking-progress";
import InstructorCheckingProgress from "../../pages/instructor-checking-progress";
import AssessorCheckingProgress from "../../pages/assessor-checking-progress";
import CheckerCheckingProgress from "../../pages/checker-checking-progress";

const Trainings = ({ match }) => (
  <>
    <Route path={`${match.path}/add`} component={EditTraining} />
    <Route path={`${match.path}/edit/:trainingId`} component={EditTraining} />
    <Route path={`${match.path}/inscribe/:trainingId`} component={InscribeTraining} />
    <Route path={`${match.path}/schedule/:eventType/:trainingId`} component={SchedulEvent} />
    <Route path={`${match.path}/users-inscribed/:trainingId`} component={UsersInscribedTraining} />
    <Route path={`${match.path}/written-test/:trainingId`} component={WrittenTestTraining} />
    <Route
      path={`${match.path}/checking-progress/trainer/:trainingId/:classroomAttendance/:classroomTest`}
      component={TrainerCheckingProgress}
    />
    <Route
      path={`${match.path}/checking-progress/instructor/:trainingId/:instructorComplete/:assessorPassed/:checkerPassed`}
      component={InstructorCheckingProgress}
    />
    <Route
      path={`${match.path}/checking-progress/assessor/:trainingId/:instructorComplete/:assessorPassed/:checkerPassed`}
      component={AssessorCheckingProgress}
    />
    <Route
      path={`${match.path}/checking-progress/checker/:trainingId/:instructorComplete/:assessorPassed/:checkerPassed`}
      component={CheckerCheckingProgress}
    />
    <Route path={`${match.path}/assessment/:trainingId`} component={AssessmentTraining} />
    <Route exact path={`${match.path}`} component={CourseTraining} />
  </>
);

export default Trainings;
