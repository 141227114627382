import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import {
  getAssociateOperationProcessStep,
  deleteAssociateOperationProcessStepById,
} from "../../api/associate-operation-process-step";
import Confirm from "../../components/Confirm";

const AssociateOperationProcessStepTable = ({ match, history }) => {
  const [associateOperationProcessStep, setAssociateOperationProcessStep] = useState(null);
  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(operationId) {
    setSelectedOperationId(operationId);
    setIsDeleteModalShow(true);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteAssociateOperationProcessStepById(selectedOperationId);
    }
    setIsDeleteModalShow(false);
    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      const res = await getAssociateOperationProcessStep();

      setAssociateOperationProcessStep(res.sort((a, b) => a.operationId - b.operationId));
    })();
  }, [refresh]);

  return (
    <>
      <Typography variant="h4">Associate operation to Process step</Typography>
      {associateOperationProcessStep ? (
        <TableComponent
          TopComponent={
            <Button size="small" onClick={() => history.push(`/associate-operation-process-step/add`)}>
              <PostAddIcon />
              Add New association
            </Button>
          }
          search={["name"]}
          header={[{ title: "Operation" }, { title: "Process Step" }, { title: "Actions" }]}
          items={associateOperationProcessStep}
          renderChildren={(ps) => (
            <TableRow key={ps.operationId}>
              <TableCell>
                {ps.operationId} - {ps.operationName}
              </TableCell>
              <TableCell>
                {ps.data.map((o) => (
                  <li>
                    {o.processStepId} - {o.processStepName}
                  </li>
                ))}
              </TableCell>
              <TableCell>
                <Button
                  icon={<EditIcon />}
                  onClick={() => history.push(`/associate-operation-process-step/edit/${ps.operationId}`)}
                  title="Edit Associate operation"
                />
                <Button
                  icon={<DeleteForeverIcon />}
                  onClick={() => showDeleteModal(ps.operationId)}
                  title="Delete Associate operation"
                />
              </TableCell>
            </TableRow>
          )}
        />
      ) : null}

      {isDeleteModalShow && selectedOperationId ? (
        <Confirm title={"Delete Associate operation"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the operation?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default AssociateOperationProcessStepTable;
