import request from "./request";
import CourseService from "../services/course";
import { SelectableWithIdObject } from "src/components/Form/types";

interface CourseBase {
  id: number;
  name: string;
  shortName: string;
  displayName: string;
  type: string;
  startDate: number;
  summary: string;
  summary2?: string;
  visible: number;
  showAtAllCourses: number;
  isConfigured: boolean;
}

export interface CourseResponse extends CourseBase {
  buffer?: number;
  price?: string;
  refreshers?: RefresherCourseResponse[];
  validPeriod: number;
  validPeriodId: number;
  courseCategory: string;
  courseCategoryId: number;
}

export interface RefresherCourseResponse extends CourseBase {
  isActive: number;
  buffer?: number;
  price?: string;
}

export interface CourseRequest extends CourseBase {
  crewId?: string;
  bufferZone?: number;
  price?: number;
  validPeriodId: number;
  refreshers?: { id: number; isActive: number }[];
  positionValidPeriods?: { positionId: number; validPeriodId: number }[];
  courseCategoryId: number;
}

interface CourseShortResponse {
  id: number;
  displayname: string;
}

export class CourseShort extends SelectableWithIdObject {
  public displayname: string;

  constructor(data: CourseShortResponse) {
    super(data.id);
    this.displayname = data.displayname;
  }

  getDisplayName(): string {
    return `(${this.id}) ${this.displayname}`;
  }
}

export const mapCourseShort = (data: CourseShortResponse): CourseShort => {
  return new CourseShort(data);
};

export const getAllCourses = async (crewId = null) => {
  let url = `/courses`;
  if (crewId) {
    url += `?crewId=${crewId}`;
  }
  const json = await request(url);

  const hidden = CourseService.hide(json);
  return hidden;
};

export const getMoodleCourses = async (): Promise<CourseResponse[]> => {
  const json = await request(`/courses/moodle`);
  return json;
};

export const getCustomCourses = async () => {
  const json = await request(`/courses/custom`);
  return json;
};

export const getCompanyCourses = async (crewId: string) => {
  const json = await request(`/courses/custom/crew/${crewId}`);
  return json;
};

export const getCustomCourseById = async (courseId: string) => {
  const json = await request(`/courses/custom/${courseId}`);

  return json;
};

export const getMoodleCourseById = async (courseId: string): Promise<CourseResponse> => {
  const json = await request(`./courses/moodle/${courseId}`);

  return json;
};

export const deleteCustomCourseById = async (courseId: string) => {
  const json = await request(`./courses/custom/${courseId}`, "DELETE");

  return json;
};

export const updateCustomCourse = async (courseId: string, newCourse: CourseRequest) => {
  const crew = newCourse.crewId ? `/${newCourse.crewId}` : "";
  const response = await request(`/courses/custom/${courseId}${crew}`, "PUT", newCourse);

  return response;
};

export const updateMoodleCourse = async (courseId: string, newCourse: CourseRequest) => {
  const response = await request(`/courses/moodle/${courseId}`, "PUT", newCourse);

  return response;
};

// TODO: replace any type
export const addCustomCourse = async (newCourse: any) => {
  const crew = newCourse.crewId ? `/${newCourse.crewId}` : "";
  const response = await request(`/courses/custom${crew}`, "POST", newCourse);
  return response;
};

export const getCourse = async (courseId: string, isCustom: boolean) => {
  let url = `/courses/${courseId}`;
  if (isCustom) {
    url += "?custom=true";
  }
  const json = await request(url);
  return json;
};

// TODO: replace any type
export const uploadCourseCertificate = async ({
  courseId,
  userId,
  custom,
  files,
  completed,
  validUntil,
  comment,
  crewId,
}: any) => {
  const formData = new FormData();
  for (const file of files) {
    formData.append("file", file);
  }
  completed && formData.append("completed", completed.format("YYYY-MM-DD"));
  validUntil && formData.append("validUntil", validUntil.format("YYYY-MM-DD"));
  comment && formData.append("comment", comment);
  crewId && formData.append("crewId", crewId);
  userId && formData.append("userId", userId);

  const status = await request(`/certificate/${courseId}?userId=${userId}&custom=${custom}`, "POST", formData);

  return status;
};

// TODO: replace any type
export const toggleCourseVisibility = async (crewId: string, course: any) => {
  const json = await request.post(`/courses/toggle/${crewId}`, {
    id: course.id,
    groupId: course.groupId,
    isCustom: course.isCustom,
    categoryId: course.categoryId,
    positionId: course.positionId,
    operationId: course.operationId,
    aircraftTypeId: course?.aircraftTypeId,
    specializationId: course?.specializationId,
  });

  return json;
};

export const getAvailableCourses = async (crewId: string) => {
  const json = await request(`/courses/available/${crewId}`);
  return json;
};

// TODO: replace any type
export const createCourseRelation = async (crewId: string, relation: any) => {
  const url = !crewId ? `/courses/relation` : `/courses/relation/${crewId}`;
  const json = await request.post(url, relation);
  return json;
};

// TODO: replace any type
export const deleteRelation = async (crewId: string, relation: any) => {
  const url = !crewId ? `/courses/relation` : `/courses/relation/${crewId}`;
  const id = relation.groupId || relation.id;
  const isGroup = !!relation.groupId;
  let query = `id=${id}`;
  if (isGroup) {
    query += `&isGroup=true`;
  } else if (relation.isCustom) {
    query += `&isCustom=true`;
  }
  if (relation.categoryId) query += `&categoryId=${relation.categoryId}`;
  if (relation.positionId) query += `&positionId=${relation.positionId}`;
  if (relation.operationId) query += `&operationId=${relation.operationId}`;
  if (relation.aircraftTypeId) query += `&aircraftTypeId=${relation.aircraftTypeId}`;
  if (relation.specializationId) query += `&specializationId=${relation.specializationId}`;

  const json = await request.delete(`${url}?${query}`);
  return json;
};

export const getMoodleActiveCourses = async () => {
  const response = await request("/courses/active");
  return response;
};

export const getMoodleCoursesForTraining = async () => {
  const response = await request("/courses/training");
  return response;
};
