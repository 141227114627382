import request from "./request";

export const getSpecializations = async () => {
  const json = await request(`/specializations`);

  return json;
};

export const getSpecializationById = async (specializationId) => {
  const json = await request(`./specializations/${specializationId}`);

  return json;
};

export const addSpecialization = async (specializationName) => {
  const response = await request(`/specializations`, "POST", {
    name: specializationName,
  });

  return response;
};

export const updateSpecialization = async (specializationId, newSpecialization) => {
  const response = await request(`/specializations/${specializationId}`, "PUT", newSpecialization);

  return response;
};

export const deleteSpecializationById = async (specializationId) => {
  const json = await request(`./specializations/${specializationId}`, "DELETE");

  return json;
};
