import React from "react";

import PermIdentityIcon from "@mui/icons-material/PermIdentity";

import CollapsibleListItem from "../List/CollapsibleList";
import CoursesList from "./CoursesList";

import { useCourseRelationsContext } from "../../context/CourseRelationsContext";

const SpecializationTreeItem = ({ nodeId, specialization }) => {
  const actions = useCourseRelationsContext();
  const key = `${nodeId}/s${specialization.id}`;
  const { courses, customCourses, groups } = specialization;
  const hasItems = courses?.length || customCourses?.length || groups?.length;

  return (
    <CollapsibleListItem
      icon={<PermIdentityIcon />}
      title={`(${specialization.id}) ${specialization.name}`}
      isExpandable={hasItems}
      nodeId={key}
      onClick={() => actions.nodeClicked(key)}
    >
      <CoursesList nodeId={key} courses={courses} customCourses={customCourses} groups={groups} />
    </CollapsibleListItem>
  );
};

export default SpecializationTreeItem;
