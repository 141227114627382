import React, { useState, useEffect } from "react";

import UserRoleForm from "../../components/UserRoleForm";

import { BasePaper } from "../../components/Base";

import { getUserRoles, setUserRoles } from "../../api/crew";
import { getRoles, getRolesApps } from "../../api/roles";

const RolesEdit = ({ match }) => {
  const { crewId, userId } = match.params;
  const [userRoles, setUserBaseRoles] = useState(null);
  const [availableRoles, setAvailableRoles] = useState(null);

  useEffect(() => {
    (async () => {
      const userRoles = await getUserRoles(crewId, userId);
      const roles = await getRoles();
      const rolesApps = await getRolesApps(crewId);

      const filteredRoles = roles.filter((role) => rolesApps.some((association) => association.role_id === role.id));
      setUserBaseRoles(userRoles);
      setAvailableRoles(filteredRoles);
    })();
  }, [crewId, userId]);

  const save = async (roleIds) => {
    const status = await setUserRoles(crewId, userId, roleIds);
    return status?.ok;
  };

  return (
    <>
      {availableRoles && userRoles ? (
        <BasePaper>
          <UserRoleForm selected={userRoles} available={availableRoles} onSave={save} />
        </BasePaper>
      ) : null}
    </>
  );
};

export default RolesEdit;
