import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useCrew from "../../hooks/useCrew";
import useUser from "../../hooks/useUser";
import React, { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getTrainings } from "../../api/training";
import DateRangeIcon from "@mui/icons-material/DateRange";
import QuizIcon from "@mui/icons-material/Quiz";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { convertDateTime } from "../../services/formatters";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const TrainerTrainings = ({ match, history }) => {
  const { t } = useTranslation();
  const [trainings, setTrainings] = useState([]);
  const user = useUser();
  const { crew } = useCrew();
  const eventType = "trainer";

  useEffect(() => {
    (async () => {
      console.log("user.id");

      const res = await getTrainings();
      console.log("res", res);
      setTrainings(res);
    })();
  }, []);

  return (
    <>
      <Typography variant="h4">Trainings List</Typography>

      {trainings ? (
        <TableComponent
          header={[
            { title: "ID", style: { width: "5%" } },
            { title: "Course ID", style: { width: "5%" } },
            { title: "Training" },

            { title: "Status" },
            { title: "e-learning" },
            { title: "Classroom" },
            { title: "Practical" },

            { title: "Actions" },
          ]}
          renderChildren={(training) => (
            <TableRow key={training.id}>
              <TableCell>{training.id}</TableCell>
              <TableCell>{training.courseId}</TableCell>
              <TableCell>{training.displayname}</TableCell>

              <TableCell>{training.status}</TableCell>

              <TableCell>
                {training.isELearning ? <CheckCircleIcon htmlColor="green" /> : <ErrorIcon htmlColor="red" />}
              </TableCell>

              <TableCell>
                {training.isClassroom ? <CheckCircleIcon htmlColor="green" /> : <ErrorIcon htmlColor="red" />}
              </TableCell>

              <TableCell>
                {training.isPractical ? <CheckCircleIcon htmlColor="green" /> : <ErrorIcon htmlColor="red" />}
              </TableCell>

              <TableCell>
                <Button
                  icon={<ArrowForward />}
                  onClick={() => history.push(`/training-users/user/${training.id}`)}
                  title="Users list"
                />
              </TableCell>
            </TableRow>
          )}
          search={["displayname", "id", "courseId"]}
          pagination
          items={trainings}
        />
      ) : null}
    </>
  );
};

export default TrainerTrainings;
