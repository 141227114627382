import request from "./request";

const passCondition2Request = (passCondition) => ({
  name: passCondition.name,
  role: passCondition.role,
  module: passCondition.module,
  type: passCondition.type,
  value: passCondition.value,
});

export const getPassConditions = async () => {
  const json = await request(`/pass-condition`);
  return json;
};

//getpassCondition
export const getpassCondition = async (passConditionId) => {
  const passCondition = await request(`/pass-condition/${passConditionId}`);
  return passCondition;
};

export const createPassCondition = async (passCondition) => {
  const response = await request.post(`/pass-condition`, passCondition2Request(passCondition));
  return response;
};

export const updatePassCondition = async (passConditionId, passCondition) => {
  const response = await request.put(`/pass-condition/${passConditionId}`, passCondition2Request(passCondition));
  return response;
};

export const deletePassConditionsById = async (passConditionId) => {
  const json = await request(`/pass-condition/${passConditionId}`, "DELETE");
  return json;
};
