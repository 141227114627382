import React, { useState } from "react";
import Paper from "@mui/material/Paper";

import TextField from "@mui/material/TextField";
import Button from "../../components/Button";

import { addGroupAircraft } from "../../api/group-aircraft";

import "./index.scss";

const CATEGORY_ERROR_TEXT = "Please enter the name of group airccraft";

const GroupAircraftAdd = ({ match, history }) => {
  const { crewId } = match.params;
  const [groupAircraftName, setGroupAircraftName] = useState("");
  const [groupAircraftErrorText, setGroupAircraftErrorText] = useState(null);

  function handleGroupAircraftName(value) {
    setGroupAircraftName(value);
    if (!value) return setGroupAircraftErrorText(CATEGORY_ERROR_TEXT);
    setGroupAircraftErrorText(null);
  }

  async function addNewGroupAircraft() {
    if (!groupAircraftName) return handleGroupAircraftName(groupAircraftName);

    const result = await addGroupAircraft(groupAircraftName, crewId);
    if (result.ok) {
      history.goBack();
    }
  }

  return (
    <>
      <h2>Add Group Aircraft</h2>
      <Paper variant="outlined" style={{ padding: "1rem 1.5rem" }}>
        <TextField
          className="add-field"
          variant="outlined"
          size="small"
          label="Group Aircraft name*"
          value={groupAircraftName}
          onChange={(event) => handleGroupAircraftName(event.target.value)}
          error={!!groupAircraftErrorText}
          helperText={groupAircraftErrorText}
        />
        <Button title="Add new Group Aircraft" onClick={addNewGroupAircraft}>
          Add
        </Button>
        <Button title="Go back" onClick={() => history.goBack()}>
          Cancel
        </Button>
      </Paper>
    </>
  );
};

export default GroupAircraftAdd;
