import { FC, HTMLInputTypeAttribute, ChangeEvent, useContext } from "react";

import TextField, { TextFieldProps } from "@mui/material/TextField";

import FormContext from "./FormContext";

interface InputProps extends Omit<TextFieldProps, "onChange" | "error"> {
  label: string;
  value: string;
  error?: string | null;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  onChange?: (value: string) => void;
}

export const Input: FC<InputProps> = ({
  label,
  value,
  error,
  disabled = false,
  type = "text",
  onChange = () => {},
  ...otherProps
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target?.value);
  };

  return (
    <TextField
      variant="outlined"
      type={type}
      value={value || ""}
      label={label}
      error={!!error}
      disabled={disabled}
      size="small"
      helperText={error || undefined}
      onChange={handleChange}
      fullWidth
      {...otherProps}
    />
  );
};

interface InputFormProps extends Omit<TextFieldProps, "onChange"> {
  name: string;
  label: string;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  onChange?: (value: string) => void;
}

const InputForm: FC<InputFormProps> = ({ name, label, disabled = false, type = "text", onChange, ...otherProps }) => {
  const context = useContext(FormContext);
  const { value, error } = context.get(name);

  const handleChange = (newValue: string) => {
    context.set(name, newValue);
    onChange?.(newValue);
  };

  return (
    <Input
      label={label}
      value={value || ""}
      error={error}
      disabled={disabled}
      type={type}
      onChange={handleChange}
      {...otherProps}
    />
  );
};

export default InputForm;
