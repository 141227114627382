import React from "react";

import { Switch, Route } from "react-router-dom";

import UploadCertificate from "../../features/upload-certificate";

const Certificate = ({ match }) => {
  return (
    <Switch>
      <Route path={[`${match.path}/:crewId/members/:userId/upload/:certificateId`]} component={UploadCertificate} />
    </Switch>
  );
};

export default Certificate;
