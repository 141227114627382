import React from "react";

import LabelIcon from "@mui/icons-material/Label";

import CollapsibleListItem from "../List/CollapsibleList";
import CoursesList from "./CoursesList";
import AircraftTreeItem from "./AircraftTreeItem";
import PositionTreeItem from "./PositionTreeItem";
import { useCourseRelationsContext } from "../../context/CourseRelationsContext";

const CategoryTreeItem = ({ nodeId, category }) => {
  const key = `${nodeId}/cat${category.id}`;
  const actions = useCourseRelationsContext();
  const { courses, customCourses, groups, aircraft, positions } = category;
  const hasItems = courses?.length || customCourses?.length || groups?.length || aircraft?.length || positions?.length;
  return (
    <CollapsibleListItem
      nodeId={key}
      title={`${category.name}`}
      isExpandable={hasItems}
      icon={<LabelIcon />}
      onClick={() => actions.nodeClicked(key)}
    >
      <CoursesList nodeId={key} courses={courses} customCourses={customCourses} groups={groups} />
      {aircraft?.length
        ? aircraft.map((aircraft) => (
            <AircraftTreeItem key={`${key}/a${aircraft.id}`} aircraft={aircraft} nodeId={key} />
          ))
        : null}
      {positions?.length
        ? positions.map((position) => (
            <PositionTreeItem key={`${key}/p${position.id}`} position={position} nodeId={key} />
          ))
        : null}
    </CollapsibleListItem>
  );
};

export default CategoryTreeItem;
