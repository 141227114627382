import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useCrew from "../../hooks/useCrew";
import useUser from "../../hooks/useUser";
import React, { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import PlayCircleIcon from "@mui/icons-material/PlayCircleFilled";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TableComponent from "../../components/TableComponent";
import { getUserCrewTrainings } from "../../api/training";
import { openExportWindow } from "../../api/index";
import { concatRolesUsers } from "../../services/user";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import GetAppIcon from "@mui/icons-material/GetApp";

const UserTrainings = ({ match, history }) => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(1);
  const { crew } = useCrew();
  const [userCrewTrainings, setUserCrewTrainings] = useState([]);
  //const { user } = useUser();
  const user = useUser();

  useEffect(() => {
    (async () => {
      const res = await getUserCrewTrainings("user", user.id, crew.id);
      setUserCrewTrainings(res);
    })();
  }, [refresh]);

  return (
    <>
      <Typography variant="h4">User List</Typography>
      {userCrewTrainings ? (
        <TableComponent
          header={[
            { title: "Training ID", style: { width: "5%" } },
            { title: "Training" },
            { title: "e-learning" },
            { title: "Classroom" },
            { title: "Practical" },
            { title: "Actions" },
          ]}
          renderChildren={(userCrewTraining) => (
            <TableRow key={userCrewTraining.id}>
              <TableCell>{userCrewTraining.training_id}</TableCell>
              <TableCell>{userCrewTraining.displayname}</TableCell>

              <TableCell>
                {userCrewTraining.is_e_learning ? (
                  userCrewTraining.elearning_status ? (
                    <CheckCircleIcon htmlColor="green" />
                  ) : (
                    <ErrorIcon htmlColor="red" />
                  )
                ) : (
                  ""
                )}
              </TableCell>
              <TableCell>
                {userCrewTraining.is_classroom ? (
                  userCrewTraining.classroom_status ? (
                    <CheckCircleIcon htmlColor="green" />
                  ) : (
                    <ErrorIcon htmlColor="red" />
                  )
                ) : (
                  ""
                )}
              </TableCell>
              <TableCell>
                {userCrewTraining.is_practical ? (
                  userCrewTraining.practical_status ? (
                    <CheckCircleIcon htmlColor="green" />
                  ) : (
                    <ErrorIcon htmlColor="red" />
                  )
                ) : (
                  ""
                )}
              </TableCell>

              <TableCell>
                {userCrewTraining.training.is_e_learning == userCrewTraining.elearning_status &&
                userCrewTraining.training.is_classroom == userCrewTraining.classroom_status &&
                userCrewTraining.training.is_practical == userCrewTraining.practical_status ? (
                  <Button
                    title="Download Certificate"
                    icon={<GetAppIcon />}
                    onClick={() => openExportWindow(userCrewTraining.course_id, user.id)}
                  />
                ) : (
                  <Button
                    icon={<PlayCircleIcon />}
                    onClick={() =>
                      history.push(
                        `training-users/user/details/${userCrewTraining.training_id}/${user.id}/${userCrewTraining.course_id}`,
                      )
                    }
                    title="Go to course modules"
                  />
                )}
              </TableCell>
            </TableRow>
          )}
          search={["displayname", "fullname", "company_name"]}
          pagination
          items={userCrewTrainings}
        />
      ) : null}
    </>
  );
};

export default UserTrainings;
