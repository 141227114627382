import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { StyledSwitch } from "../../components/Base";
import Button from "../../components/Button";
import { Autocomplete } from "../../components/Form";
import { Model, SelectableWithIdObject } from "../../components/Form/types";

interface RefresherRowProps {
  item: RefreshCourseRowModel;
  onChange: (updatedItem: RefreshCourseRowModel) => void;
  onRemove: () => void;
  allOptions: SelectableWithIdObject[];
  excludeOptions: SelectableWithIdObject[];
}

export class RefreshCourseRowModel extends SelectableWithIdObject implements Model {
  shortName?: string;
  isActive?: boolean;
  getDisplayName!: () => string;

  constructor(data?: any) {
    super(data?.id);
    if (data && typeof data === "object") {
      this.shortName = data.shortName;
      this.isActive = Boolean(data.isActive);
      this.getDisplayName = () => (data.shortName ? `${data.shortName} (${data.id})` : "");
    }
  }

  validate(): boolean {
    return this && Object.keys(this).length > 0;
  }
}

const RefresherRow = ({ item, onChange, onRemove, allOptions, excludeOptions }: RefresherRowProps) => {
  const { t } = useTranslation();

  const getAvailableOptions = useCallback(() => {
    return allOptions.filter(
      (refresher) =>
        !excludeOptions.some((selected) => selected?.getId() === refresher.getId()) || refresher.getId() === item?.id,
    );
  }, [allOptions, excludeOptions, item]);

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
      })}
    >
      <Autocomplete
        sx={{ width: "100%" }}
        label={`${t("course.select_refresher")}*`}
        items={getAvailableOptions()}
        selected={item}
        onChange={(updatedItem) => {
          updatedItem.isActive = true;
          onChange(updatedItem);
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography component="div">{t("is_active")}</Typography>
        <StyledSwitch
          title={t("course.refresher_active_toggle")}
          checked={item?.isActive ?? false}
          onChange={() => {
            if (item) {
              const updatedItem = new RefreshCourseRowModel({
                ...item,
                isActive: !item.isActive,
              });
              onChange(updatedItem);
            }
          }}
        />
      </Box>
      <Button title={t("course.remove_refresher_action")} icon={<ClearIcon />} onClick={onRemove} />
    </Box>
  );
};

export default RefresherRow;
