import request from "./request";

export const getOrders = async () => {
  const json = await request(`/orders`);
  return json;
};

export const getTransactions = async () => {
  const json = await request(`/orders/transactions`);
  return json;
};

export const getTransactionById = async (transactionId) => {
  const json = await request(`/orders/transactions/${transactionId}`);
  return json;
};

export const getOrderCrew = async (crewId) => {
  const json = await request(`./orders/${crewId}`);
  return json;
};

export const getOrderById = async (orderId) => {
  const json = await request(`./orders/${orderId}`);

  return json;
};

export const addOrder = async (orderName) => {
  const response = await request(`/orders`, "POST", {
    name: orderName,
  });

  return response;
};

export const updateOrder = async (orderId, newOrder) => {
  const response = await request(`/orders/${orderId}`, "PUT", newOrder);

  return response;
};

export const deleteOrderById = async (orderId) => {
  const json = await request(`./orders/${orderId}`, "DELETE");
  return json;
};

export const downloadInvoice = async (orderId) => {
  const json = await request(`/orders/invoice/${orderId}`);
  return json;
};

export const uploadBankFile = async ({ files }) => {
  const formData = new FormData();
  for (const file of files) {
    formData.append("file", file);
  }
  const status = await request(`/orders/upload-bank-file`, "POST", formData);
  return status;
};
