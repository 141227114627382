import React from "react";

import CourseTreeItem from "./CourseTreeItem";

const CoursesList = ({ courses, customCourses, groups, nodeId }) => (
  <>
    {courses?.length
      ? courses.map((course) => <CourseTreeItem key={`${nodeId}/c${course.id}`} course={course} nodeId={nodeId} />)
      : null}
    {groups?.length
      ? groups.map((group) => <CourseTreeItem key={`${nodeId}/g${group.id}`} group={group} nodeId={nodeId} />)
      : null}
    {customCourses?.length
      ? customCourses.map((cc) => <CourseTreeItem key={`${nodeId}/cc${cc.id}`} customCourse={cc} nodeId={nodeId} />)
      : null}
  </>
);

export default CoursesList;
