import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";

// import UpArrowIcon from "@mui/icons-material/ExpandLess";
// import DownArrowIcon from "@mui/icons-material/ExpandMore";

import { FlexRow, StyledInput, Separator } from "../../components/Base";

import Button from "../../components/Button";
import CountriesList from "../../components/CountriesList";

import useCrew from "../../hooks/useCrew";
import useUser from "../../hooks/useUser";
import { fireEvent } from "../../hooks/usePubsub";

import { getBillingAddress, createBillingAddress, updateBillingAddress, deleteBillingAddress } from "../../api/user";

import { parseUserName } from "../../services/user";
import { Validation } from "../../components/Form";

const BillingForm = ({ onSubmit, id }) => {
  const user = useUser();
  const { crew } = useCrew();

  const parsedName = parseUserName(user.fullname);
  const [firstName, setFirstName] = useState(parsedName.firstName);
  const [lastName, setLastName] = useState(parsedName.lastName);
  const [country, setCountry] = useState(user.country || "CH");
  const [crewId, setCrewId] = useState(crew.id);
  const [companyName, setCompanyName] = useState(crew.name);
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState(user.city);
  const [state, setState] = useState(user.state);
  const [postalCode, setPostalCode] = useState("");
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const [additionalEmail, setAdditionalEmail] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    (async () => {
      if (id) {
        const address = await getBillingAddress(id);
        if (address) {
          address.firstName && setFirstName(address.firstName);
          address.lastName && setLastName(address.lastName);
          address.country && setCountry(address.country);
          address.crewId && setCrewId(crew.id);
          address.companyName && setCompanyName(address.companyName);
          address.addressLine1 && setAddressLine1(address.addressLine1);
          address.addressLine2 && setAddressLine2(address.addressLine2);
          address.city && setCity(address.city);
          address.state && setState(address.state);
          address.postalCode && setPostalCode(address.postalCode);
          address.email && setEmail(address.email);
          address.phone && setPhone(address.phone);
          address.additionalEmail && setAdditionalEmail(address.additionalEmail);
        }
      }
    })();
  }, [id]);

  const onSave = async () => {
    if (validate()) {
      const addr = {
        firstName,
        lastName,
        country,
        addressLine1,
        addressLine2,
        city,
        state,
        postalCode,
        email,
        phone,
        companyName,
        crewId,
        additionalEmail,
      };
      let res;
      if (!id) {
        res = await createBillingAddress(addr);
      } else {
        res = await updateBillingAddress(id, addr);
      }
      if (res?.ok) {
        onSubmit();
        fireEvent("billing/address-changed");
      } else {
        setErrors({
          error: "Error occured, please try again later",
        });
      }
    }
  };

  const onRemove = async () => {
    let res = await deleteBillingAddress(id);
    if (res?.ok) {
      onSubmit();
    } else {
      setErrors({
        error: "Error occured, please try again later",
      });
    }
  };

  const validate = () => {
    const errors = {};
    if (!firstName) {
      errors.firstName = "Please specify first name";
    }
    if (!lastName) {
      errors.lastName = "Please specify last name";
    }
    if (!addressLine1) {
      errors.addressLine1 = "Please specify address";
    }
    if (!city) {
      errors.city = "Please specify city";
    }
    if (!postalCode) {
      errors.postalCode = "Please specify postal code";
    }
    if (!email) {
      errors.email = "Please specify email";
    } else if (Validation.email(email)) {
      errors.email = Validation.email(email);
    }
    /* 
    if (!phone) {
      errors.phone = "Please specify phone";
    }
     */
    if (!country) {
      errors.error = "Please specify country";
    }

    const hasErrors = !!Object.keys(errors || {}).length;
    setErrors(hasErrors ? errors : null);
    return !hasErrors;
  };

  return (
    <>
      <FlexRow alignItems="flex-start">
        <StyledInput label="First Name*" error={errors?.firstName} required value={firstName} onChange={setFirstName} />
        <Separator />
        <StyledInput label="Last Name*" error={errors?.lastName} required value={lastName} onChange={setLastName} />
      </FlexRow>
      <FlexRow alignItems="flex-start">
        <StyledInput label="Company" value={companyName} onChange={setCompanyName} />
      </FlexRow>
      <FlexRow alignItems="flex-start">
        <StyledInput
          label="Address Line 1*"
          error={errors?.addressLine1}
          required
          value={addressLine1}
          onChange={setAddressLine1}
        />
        <Separator />
        <StyledInput label="Address Line 2" value={addressLine2} onChange={setAddressLine2} />
      </FlexRow>
      <FlexRow alignItems="flex-start">
        <StyledInput label="City*" error={errors?.city} required value={city} onChange={setCity} />
        <Separator />
        <StyledInput label="State" value={state} onChange={setState} />
      </FlexRow>
      <FlexRow alignItems="flex-start">
        <StyledInput label="Postal Code*" error={errors?.postalCode} value={postalCode} onChange={setPostalCode} />
        <Separator />
        <CountriesList selected={country} onChange={setCountry} />
      </FlexRow>
      <FlexRow alignItems="flex-start">
        <StyledInput label="Email*" error={errors?.email} required value={email} onChange={setEmail} />
        <Separator />
        <StyledInput
          label="Phone"
          /* 
          error={errors?.phone}
          required
           */
          value={phone}
          onChange={setPhone}
        />
      </FlexRow>
      <FlexRow alignItems="flex-start">
        <StyledInput
          label="Additional email address"
          error={errors?.additionalEmail}
          value={additionalEmail}
          onChange={setAdditionalEmail}
        />
        <Separator />
      </FlexRow>
      {errors?.error ? (
        <FlexRow>
          <Typography color="error">{errors?.error}</Typography>
        </FlexRow>
      ) : null}
      <FlexRow justifyContent="space-between">
        <Button title="Save changes" onClick={onSave}>
          Save Changes
        </Button>
        {id ? (
          <Button title="Remove Billing Address" onClick={onRemove}>
            Remove Billing Address
          </Button>
        ) : null}
      </FlexRow>
    </>
  );
};

export default BillingForm;
