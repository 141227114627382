import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Button from "../../components/Button";
import { StyledSwitch } from "../../components/Base";
import TableComponent from "../../components/TableComponent";
import { getProcessStep, deleteProcessStepById, updateProcessStep } from "../../api/process-step";
import Confirm from "../../components/Confirm";

const ProcessStepTable = ({ match, history }) => {
  const [processStep, setProcessStep] = useState(null);

  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id) {
    setSelectedOperationId(id);
    setIsDeleteModalShow(true);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteProcessStepById(selectedOperationId);
    }
    setIsDeleteModalShow(false);
    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      const res = await getProcessStep();
      setProcessStep(res);
    })();
  }, [refresh]);

  return (
    <>
      <Typography variant="h4">Process Step</Typography>
      {processStep ? (
        <TableComponent
          TopComponent={
            <Button size="small" onClick={() => history.push(`/process-step/add`)}>
              <PostAddIcon />
              Add New process step
            </Button>
          }
          search={["name"]}
          header={[
            { title: "ID", style: { width: "5%" } },
            { title: "Name" },
            { title: "Type" },
            { title: "Status" },
            { title: "Activity Trainings" },
            { title: "Actions" },
          ]}
          items={processStep}
          renderChildren={(ps) => (
            <TableRow key={ps.id}>
              <TableCell>{ps.id}</TableCell>
              <TableCell>{ps.name}</TableCell>
              <TableCell>{ps.type}</TableCell>
              <TableCell>{ps.status === "active" ? "Active" : "Not Active"}</TableCell>
              <TableCell>
                {ps.activitiesTrainings.map((at) => (
                  <li key={`${ps.id}-${at.id}`}>
                    {at.id} - {at.name}
                  </li>
                ))}
              </TableCell>
              <TableCell>
                <Button
                  icon={<EditIcon />}
                  onClick={() => history.push(`/process-step/edit/${ps.id}`)}
                  title="Edit Process Step"
                />
                <Button
                  icon={<DeleteForeverIcon />}
                  onClick={() => showDeleteModal(ps.id)}
                  title="Delete Process Step"
                />
              </TableCell>
            </TableRow>
          )}
        />
      ) : null}

      {isDeleteModalShow && selectedOperationId ? (
        <Confirm title={"Delete Process Step"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the operation?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default ProcessStepTable;
