import React from "react";
import Box from "@mui/material/Box";
import { Switch, Route } from "react-router";

import UserEdit from "../../features/user-edit";
import RolesEdit from "../../features/member-roles";
import MemberEditNavigation from "../../features/user-navigation/MemberEditNavigation";
import PropertiesEdit from "../../features/properties-edit";

import AircraftSpecialization from "../../features/aircraft-specialization";

const MemberEdit = ({ match, history }) => {
  return (
    <>
      <Box marginBottom={"1.5rem"}>
        <Route path={`${match.path}/:pageId`} component={MemberEditNavigation} />
      </Box>
      <Switch>
        <Route path={`${match.path}/member`} component={UserEdit} />
        <Route path={`${match.path}/properties`} component={PropertiesEdit} />
        <Route path={`${match.path}/aircraft`} component={AircraftSpecialization} />
        <Route path={`${match.path}/roles`} component={RolesEdit} />
      </Switch>
    </>
  );
};

export default MemberEdit;
