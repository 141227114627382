import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useCrew from "../../hooks/useCrew";
import useUser from "../../hooks/useUser";
import React, { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getUserCrewTrainings } from "../../api/training";
import DateRangeIcon from "@mui/icons-material/DateRange";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { convertDateTime } from "../../services/formatters";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const InstructurTrainings = ({ match, history }) => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(1);
  const { crew } = useCrew();
  const [userCrewTrainings, setUserCrewTrainings] = useState([]);
  //const { user } = useUser();
  const user = useUser();

  useEffect(() => {
    (async () => {
      const res = await getUserCrewTrainings("instructor", user.id, crew.id);
      setUserCrewTrainings(res);
    })();
  }, [refresh]);

  return (
    <>
      <Typography variant="h4">Instructor List</Typography>
      {userCrewTrainings ? (
        <TableComponent
          header={[
            { title: "ID", style: { width: "5%" } },
            { title: "Training" },
            { title: "Status" },
            { title: "e-learning" },
            { title: "Classroom" },
            { title: "Practical" },
            { title: "Instructor tasks done" },
            { title: "Actions" },
          ]}
          renderChildren={(userCrewTraining) => (
            <TableRow key={userCrewTraining.id}>
              <TableCell>{userCrewTraining.training_id}</TableCell>
              <TableCell>{userCrewTraining.displayname}</TableCell>
              <TableCell>{userCrewTraining.status}</TableCell>

              <TableCell>{userCrewTraining.is_e_learning ? "Yes" : "No"}</TableCell>
              <TableCell>{userCrewTraining.is_classroom ? "Yes" : "No"}</TableCell>
              <TableCell>{userCrewTraining.is_practical ? "Yes" : "No"}</TableCell>
              <TableCell>
                {userCrewTraining.allDone ? <CheckCircleIcon htmlColor="green" /> : <ErrorIcon htmlColor="red" />}
              </TableCell>

              <TableCell>
                <Button
                  icon={<PublishedWithChangesIcon />}
                  onClick={() => history.push(`/training-users/user/${userCrewTraining.training_id}`)}
                  title="Users Checking progress"
                />

                <Button
                  icon={<DateRangeIcon />}
                  onClick={() => history.push(`/trainings/schedule/instructor/${userCrewTraining.training_id}`)}
                  title="Schedule practical instruction"
                />

                <Button
                  icon={<LibraryAddCheckIcon />}
                  onClick={() =>
                    history.push(
                      `/trainings/checking-progress/instructor/${userCrewTraining.training_id}/${userCrewTraining.instructor_complete}/${userCrewTraining.assessor_passed}/${userCrewTraining.checker_passed}`,
                    )
                  }
                  title="Sign-off Instructor"
                />
              </TableCell>
            </TableRow>
          )}
          search={["displayname", "fullname"]}
          pagination
          items={userCrewTrainings}
        />
      ) : null}
    </>
  );
};

export default InstructurTrainings;
