import request from "./request";

export const getAssociatePositionProcessStep = async () => {
  const json = await request(`/associate-position-process-step`);
  return json;
};

//getpassCondition
export const getAssociationPositionProcessStepById = async (associatePositionProcessStepId) => {
  const processStep = await request(`associate-position-process-step/${associatePositionProcessStepId}`);
  return processStep;
};

export const createAssociationPositionProcessStep = async (positionId, processStepIds) => {
  const response = await request.post(`associate-position-process-step`, {
    positionId,
    processStepIds,
  });
  return response;
};

export const updateAssociationPositionProcessStep = async (positionId, processStepIds) => {
  const response = await request.put(`associate-position-process-step/${positionId}`, { processStepIds });
  return response;
};

export const deleteAssociatePositionProcessStepById = async (associatePositionProcessStepId) => {
  const json = await request(`associate-position-process-step/${associatePositionProcessStepId}`, "DELETE");
  return json;
};
