import React from "react";

import { Switch, Route } from "react-router";
import UserEditNavigation from "../../features/user-navigation/UserEditNavigation";
import UsersTable from "../../features/users-table";
import UserEdit from "../../features/user-edit";
import UserRolesEdit from "../../features/user-roles-edit";
import UserCrewRolesEdit from "../../features/user-crew-roles-edit";

const Users = ({ match, history }) => {
  return (
    <>
      <Route exact path={`${match.path}/:userId/:pageId`} component={UserEditNavigation} />
      <Switch>
        <Route path={`${match.path}/:userId/roles`} component={UserRolesEdit} />
        <Route path={`${match.path}/:crewId/:userId/roles`} component={UserCrewRolesEdit} />
        <Route path={`${match.path}/:userId/edit`} component={UserEdit} />
        <Route exact path={`${match.path}`} component={UsersTable} />
      </Switch>
    </>
  );
};

export default Users;
