import request from "./request";

export const getRoles = async (crewId) => {
  const json = await request(`/roles/${crewId || ""}`);

  return json;
};

export const getRolesApps = async (crewId) => {
  const json = await request(`/roles/apps/${crewId || ""}`);
  return json;
};

export const getRolesWithPermissions = async (crewId) => {
  const url = crewId ? `/roles/permissions/${crewId}` : `/roles/permissions`;
  const json = await request(url);

  return json;
};

export const setNewPermissions = async (roleId, permissionsId, crewId) => {
  const url = crewId ? `/roles/permissions/${crewId}` : `/roles/permissions`;
  const json = await request.post(url, {
    roleId: roleId,
    permissions: permissionsId,
  });

  return json;
};

export const deletePermissionFromRole = async (roleId, permissionId, crewId) => {
  const url = crewId ? `/roles/permissions/${crewId}` : `/roles/permissions`;

  const json = await request.delete(`${url}?roleId=${roleId}&permissionId=${permissionId}`);

  return json;
};

export const resetRolePermissions = async (roleId, crewId) => {
  const json = await request.post(`/roles/permissions/${crewId}/reset/${roleId}`, {});
  return json;
};

export const getRoleTrainers = async () => {
  const json = await request(`/roles/trainers`);
  return json;
};
