import React, { useEffect, useMemo, useState } from "react";

import { PageHeader, Row } from "../../components/Base";

import AircraftSpecializationsForm from "../../components/CrewAircraftForm/AircraftSpecializationForm";
import {
  getAircraftWithSpecialization,
  addCrewAircraft,
  addCrewAircraftSpecializations,
  deleteCrewAircraft,
} from "../../api/aircraft";

/* 
import {
  getCrewGroupAircraft
} from "../../api/group-aircraft";

 */
import AircraftService from "./service";

const CrewAircraft = ({ match }) => {
  const { crewId } = match.params;
  const [refresh, setRefresh] = useState(0);
  //const [crewGroupAircraft, setCrewGroupAircraft] = useState(null);
  const [crewAircraft, setCrewAircraft] = useState(null);
  const [allAircraft, setAllAircraft] = useState(null);
  const [allSpecializations, setAllSpecializations] = useState(null);
  const crewAircraftId = useMemo(() => crewAircraft?.map((aircraft) => aircraft.id), [crewAircraft]);
  useEffect(() => {
    (async () => {
      //const crewGroupAircraft = await getCrewGroupAircraft(crewId);
      const allAircraft = await getAircraftWithSpecialization(crewId);
      const crewAircraft = AircraftService.getCrewAircraft(allAircraft);
      //setCrewGroupAircraft(crewGroupAircraft)
      setAllAircraft(allAircraft.aircraft);
      setAllSpecializations(allAircraft.specializations);
      setCrewAircraft(crewAircraft);
    })();
  }, [refresh, crewId]);

  const saveAircraft = async (aircraftIds) => {
    const status = await addCrewAircraft(crewId, aircraftIds);
    setRefresh(refresh + 1);
    return status?.ok;
  };

  const deleteAircraftSpecializations = async (aircraft) => {
    const aircraftId = aircraft.id;
    await deleteCrewAircraft(crewId, aircraftId);
    setRefresh(refresh + 1);
  };

  const saveSpecializations = async (groupAircraftId, aircraftId, specializations) => {
    if (!crewAircraftId.includes(aircraftId)) {
      await saveAircraft([...crewAircraftId, aircraftId]);
    }

    const specializationStatus = await addCrewAircraftSpecializations(
      crewId,
      groupAircraftId,
      aircraftId,
      specializations,
    );
    setRefresh(refresh + 1);
    return specializationStatus?.ok;
  };

  return (
    <>
      <PageHeader>Aircraft And Specializations</PageHeader>
      {allAircraft && crewAircraft && allSpecializations ? (
        <Row>
          <AircraftSpecializationsForm
            //crewGroupAircraft = {crewGroupAircraft}
            crewAircraft={crewAircraft}
            allAircraft={allAircraft}
            specializations={allSpecializations}
            onSave={saveSpecializations}
            onDelete={deleteAircraftSpecializations}
          />
        </Row>
      ) : null}
    </>
  );
};

export default CrewAircraft;
