import request from "./request";

export const getActivitiesTraining = async () => {
  const json = await request(`/activities-training`);
  return json;
};

export const getActivitiesTrainingById = async (activitiesTrainingId) => {
  const activitiesTraining = await request(`activities-training/${activitiesTrainingId}`);
  return activitiesTraining;
};

export const createActivitiesTraining = async (activitiesTraining, observablesIds) => {
  const response = await request.post(`activities-training`, {
    activitiesTraining,
    observablesIds,
  });
  return response;
};

export const updateActivitiesTraining = async (activitiesTrainingId, activitiesTraining, observablesIds) => {
  const response = await request.put(`activities-training/${activitiesTrainingId}`, {
    activitiesTraining,
    observablesIds,
  });
  return response;
};

export const deleteActivitiesTrainingById = async (activitiesTrainingId) => {
  const json = await request(`activities-training/${activitiesTrainingId}`, "DELETE");
  return json;
};
