import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";

import Button from "../../components/Button";

import { addCrew } from "../../api/crew";
import { getRoles } from "../../api/roles";

import "./index.scss";
import { getCountries } from "../../api/country";
import { Form, Row, InputForm, Validation, Textarea, AutocompleteForm } from "../../components/Form";
import Autocomplete from "../../components/Form/Autocomplete.deprecated";

const validation = {
  companyName: Validation.required,
  firstName: Validation.required,
  lastName: Validation.required,
  email: Validation.required,
  managerRole: Validation.required,
  //phone: Validation.required,
  country: Validation.requiredObjectAutocomplete,
  address: Validation.required,
  postcode: Validation.required,
  city: Validation.required,
};

const CrewsAdd = ({ history }) => {
  const [managerRoles, setManagerRoles] = useState([]);
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    (async () => {
      const newManagerRoles = await getRoles();
      const newCountries = await getCountries();
      setManagerRoles(newManagerRoles);
      setCountries(newCountries);
    })();
  }, []);
  async function addNewCrew(data) {
    const newCrew = {
      companyName: data.companyName,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      managerRoleId: data.managerRole.id,
      //phone: data.phone,
      countryCode: data.country.code,
      address: data.address,
      postcode: data.postcode,
      city: data.city,
    };
    if (data.description) newCrew.description = data.description;
    const result = await addCrew(newCrew);
    if (result.ok) {
      history.push("/crews");
    }
  }

  return managerRoles.length ? (
    <>
      <h2>Add Company</h2>
      <Paper variant="outlined" className="add-crew-wrapper" style={{ padding: "1rem 1.5rem" }}>
        <Form onSubmit={addNewCrew} validation={validation}>
          <Row>
            <InputForm label="Company Name*" name="companyName" />
          </Row>
          <Row>
            <InputForm label="First Name*" name="firstName" />
          </Row>
          <Row>
            <InputForm label="Last Name*" name="lastName" />
          </Row>
          <Row>
            <InputForm label="Contact Email*" name="email" />
          </Row>
          <Row>
            <Autocomplete items={managerRoles} label="Manager Role*" name="managerRole" />
          </Row>
          <Row>
            <InputForm label="Contact Phone" name="phone" />
          </Row>
          <Row>
            <AutocompleteForm items={countries} label="Select country*" name="country" />
          </Row>
          <Row>
            <InputForm label="Address*" name="address" />
          </Row>
          <Row>
            <InputForm label="Postcode*" name="postcode" />
          </Row>
          <Row>
            <InputForm label="City*" name="city" />
          </Row>
          <Row>
            <Textarea label="Description" name="description" />
          </Row>
          <Button type="submit" title="Add new Company">
            Add Company
          </Button>
          <Button title="Go back" type="button" onClick={() => history.goBack()}>
            Cancel
          </Button>
        </Form>
      </Paper>
    </>
  ) : null;
};

export default CrewsAdd;
