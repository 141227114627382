import Typography from "@mui/material/Typography";

import ExpandableSection from "../../components/ExpandableSection";
import AircraftList from "../../components/Certificate/Aircraft";
import CourseStatusIcon from "../../components/CourseStatusIcon";

import AircraftService from "./service";

const Title = ({ item: { title, status } }) => (
  <>
    <CourseStatusIcon status={status} />
    <Typography variant={"body1"} style={{ marginLeft: ".5rem" }}>
      {title}
    </Typography>
  </>
);

const Aircraft = ({ certificates, courses, properties, aircraft }) => {
  const aircraftWithPositions = AircraftService.getAircraft(aircraft, courses, properties);
  const aircraftDisplay = AircraftService.toDisplayObject(aircraftWithPositions, certificates, courses.relations);

  // TODO: Find out why aircraftWithPositions sometimes duplicates certificates
  // Workaround: Deduplicate certificates within each aircraft's certificates array
  const deduplicatedDisplay = aircraftDisplay.map((displayObj) => ({
    ...displayObj,
    aircraft: displayObj.aircraft.map((aircraft) => ({
      ...aircraft,
      certificates: aircraft.certificates.filter(
        (cert, index, self) => index === self.findIndex((c) => c.id === cert.id),
      ),
    })),
  }));

  return (
    <>
      {deduplicatedDisplay.map((displayObj, index) => (
        <ExpandableSection key={`aircraft-${index}`} title={<Title item={displayObj} />}>
          <AircraftList
            aircraft={displayObj.aircraft}
            getCertificateKey={(cert, index) => `${cert.id}_${index}_${displayObj.title}`}
          />
        </ExpandableSection>
      ))}
    </>
  );
};

export default Aircraft;
