import React from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";

import Button from "../Button";
import TableComponent from "../TableComponent";

const PropertiesList = ({ userProperties, onDelete }) => (
  <TableComponent
    header={[
      { title: "Property", style: { width: "95%" } },
      { title: "Actions", style: { width: "5%" } },
    ]}
    items={userProperties}
    renderChildren={(property) => (
      <TableRow key={`${property.categoryId}-${property.positionId}-${property.operationId}`}>
        <TableCell>{property.label}</TableCell>
        <TableCell>
          <Button title="Delete Property" onClick={() => onDelete(property)} icon={<DeleteIcon color="error" />} />
        </TableCell>
      </TableRow>
    )}
  />
);

export default PropertiesList;
