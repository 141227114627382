import React from "react";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import Autocomplete from "../Form/StyledAutocomplete";
import Button from "../Button";

const AddRelationsForm = ({ aircraftList, onChange, courses, error, values, selectedValues }) => {
  return (
    <Paper variant="outlined" style={{ marginBottom: "2rem", padding: "1rem" }}>
      <Box mb={1} display={"flex"}>
        <Box width={"33%"} mr={1}>
          <Autocomplete
            onChange={onChange.category}
            selected={selectedValues?.category}
            items={values?.categories}
            label="Select Category"
          />
        </Box>
        <Box width={"33%"} mr={1}>
          <Autocomplete
            onChange={onChange.position}
            selected={selectedValues?.position}
            items={selectedValues?.category?.positions}
            label="Select Position"
          />
        </Box>
        <Box width={"33%"}>
          <Autocomplete
            onChange={onChange.operation}
            selected={selectedValues?.operation}
            items={selectedValues?.position?.operations}
            label="Select Operation"
          />
        </Box>
      </Box>
      <Box display={"flex"} mb={1}>
        <Box width={"50%"} mr={1}>
          <Autocomplete
            onChange={onChange.aircraft}
            selected={selectedValues?.aircraft}
            items={aircraftList}
            label="Select Aircraft"
          />
        </Box>
        <Box width={"50%"}>
          <Autocomplete
            onChange={onChange.specialization}
            selected={selectedValues?.specialization}
            items={selectedValues?.aircraft?.specializations}
            label="Select Specialization"
          />
        </Box>
      </Box>
      <Box mb={1}>
        <Autocomplete
          onChange={onChange.courses}
          selected={courses}
          multiple
          formatLabel={(item) => `(${item.id}) ${item.name}`}
          items={values?.courses}
          label="Select Courses"
        />
      </Box>
      <Box>
        <Button title="Add relation" onClick={onChange.submit}>
          Add
        </Button>
        {error ? <Typography color="error">{error}</Typography> : null}
      </Box>
    </Paper>
  );
};

export default AddRelationsForm;
