import { useState } from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import Button from "../Button";
import StyledListItemIcon from "./StyledListItemIcon";

const StyledList = styled(List)({
  margin: 0,
  padding: 0,
});

const CollapsibleListItem = ({ children, title, icon, nodeId, isExpandable, onClick }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <ListItemButton
        key={nodeId}
        onClick={() => {
          onClick && onClick();
        }}
      >
        <StyledListItemIcon>{icon}</StyledListItemIcon>
        <ListItemText>
          <Box display="flex" alignItems="center">
            {title}
            {isExpandable ? (
              <Button
                title={expanded ? "Show less" : "Show more"}
                icon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded(!expanded);
                }}
              />
            ) : null}
          </Box>
        </ListItemText>
      </ListItemButton>
      {isExpandable ? (
        <Collapse key={`${nodeId}/col`} in={expanded} timeout="auto" unmountOnExit style={{ marginLeft: "2rem" }}>
          <StyledList dense>{children}</StyledList>
        </Collapse>
      ) : null}
    </>
  );
};

export default CollapsibleListItem;
