import { useState, useEffect } from "react";
import Grid2 from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import NewsSidebar from "../news-sidebar";
import { getNgftApp, getCockpitPermitRoles, getNgftCrewAppsById } from "../../api/ngftApp";
import { getUserRoles } from "../../api/crew";
import useUser from "../../hooks/useUser";

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: (theme) => (theme.palette.mode === "dark" ? "#101010" : "#fff"),
        color: (theme) => (theme.palette.mode === "dark" ? "grey.300" : "grey.800"),
        border: "1px solid",
        borderColor: (theme) => (theme.palette.mode === "dark" ? "grey.800" : "grey.300"),
        p: 1,
        m: 1,
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

function ItemElement(props) {
  const { sx, ...other } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        color: (theme) => (theme.palette.mode === "dark" ? "grey.300" : "grey.800"),
        border: "1px solid",
        borderColor: (theme) => (theme.palette.mode === "dark" ? "grey.800" : "grey.300"),
        p: 1,
        m: 1,
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        ...sx,
      }}
      {...other}
    />
  );
}

ItemElement.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

function Cockpit({ showCockpit, triggerShowCockpit, crew }) {
  const [applications, setApplications] = useState([]);
  const user = useUser();

  useEffect(() => {
    (async () => {
      const allApplication = await getNgftApp();
      const crewOverviewApp = allApplication.filter((app) => app.name === "Crew overview");

      //get all apps active for the crew
      const crewActiveApps = await getNgftCrewAppsById(crew.id);
      const activeAppsId = crewActiveApps.map((app) => app.app_id);
      const activeApps = allApplication.filter((newApp) => activeAppsId.includes(newApp.id));
      // check if user has a role for access to other NGFT APPs
      const userRoles = await getUserRoles(crew.id, user.id);
      const CockpitPermitRoles = await getCockpitPermitRoles();
      const listCockpit = CockpitPermitRoles.map((role) => role.roleId);
      const isCockpitAppEnabled = userRoles.filter((role) => listCockpit.includes(role.id)).length;

      //crewOverviewApp not in the list of active apps

      if (!activeApps.includes(crewOverviewApp[0])) {
        activeApps.push(crewOverviewApp[0]);
        activeApps.sort((a, b) => a.id - b.id);
      }

      if (crew.id == 0) {
        setApplications(allApplication);
      } else if (isCockpitAppEnabled > 0) {
        setApplications(activeApps);
      } else {
        setApplications(crewOverviewApp);
      }
    })();
  }, [crew]);

  //getNgftApp

  return (
    <div style={{ width: "100%" }}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="h2">NGFT COCKPIT</Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridAutoColumns: "1fr",
          gap: 5,
        }}
      >
        <Item sx={{ gridRow: "1", gridColumn: "1 / 2" }}>
          <NewsSidebar />
        </Item>

        <Item sx={{ gridRow: "1", gridColumn: "2 / 5" }}>
          <Box
            sx={{
              display: "grid",
              gap: 1,
              gridTemplateRows: "repeat(3, 250px)",
              gridTemplateColumns: "repeat(3, 1fr)",
            }}
          >
            {applications.map((application) => (
              <ItemElement
                key={application.id}
                sx={{ bgcolor: application.color }}
                onClick={() => {
                  if (application.link) {
                    window.open(application.link);
                  } else {
                    triggerShowCockpit();
                  }
                }}
              >
                <Grid2 container direction="column" justifyContent="center" alignItems="center">
                  <SvgIcon sx={{ fontSize: 100, color: application.textColor }}>
                    <path d={application.icon} />
                  </SvgIcon>

                  <Box
                    sx={{
                      bgcolor: (theme) => (theme.palette.mode === "dark" ? "#101010" : application.color),
                      fontSize: "1.8rem",
                      fontWeight: "700",
                    }}
                  >
                    <Button
                      disabled={application.disabled}
                      onClick={() => {
                        if (application.link) {
                          window.open(application.link);
                        } else {
                          triggerShowCockpit();
                        }
                      }}
                      sx={{ fontSize: "1.2rem", color: application.textColor }}
                    >
                      {application.name}
                    </Button>
                  </Box>
                </Grid2>
              </ItemElement>
            ))}
          </Box>
        </Item>
      </Box>
    </div>
  );
}

export default Cockpit;
