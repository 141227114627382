import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getUsersInfoTraining } from "../../api/training";
import { openExportWindow } from "../../api/index";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import GetAppIcon from "@mui/icons-material/GetApp";
import useCrew from "../../hooks/useCrew";
import useUser from "../../hooks/useUser";
import DateRangeIcon from "@mui/icons-material/DateRange";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { StyledSwitch } from "../../components/Base";
import {
  getUsersInscribed,
  updateCheckingProgress,
  updateModuleStatus,
  updateStatusCertificate,
} from "../../api/training";

const UsersTrainingInscribedTable = ({ match, history }) => {
  const [usersInfo, setUsersInfo] = useState(null);
  const [refresh, setRefresh] = useState(1);
  const [isElearning, setIsElearning] = useState(0);
  const [isClassroom, setIsClassroom] = useState(0);
  const [isPractical, setIsPractical] = useState(0);
  const [isClassroomAttendance, setIsClassroomAttendance] = useState(0);
  const [isClassroomCheck, setIsClassroomCheck] = useState(0);

  const [isInstructorComplete, setIsInstructorComplete] = useState(0);
  const [isAssessorPassed, setIsAssessorPassed] = useState(0);
  const [isCheckerPassed, setIsCheckerPassed] = useState(0);

  const [courseId, setCourseId] = useState(null);
  const [validPeriod, setValidPeriod] = useState(null);

  const { crew } = useCrew();
  const evaluator = useUser();
  const eventType = "trainer";
  useEffect(() => {
    (async () => {
      try {
        console.log("FFSD");
        const trainingId = match.params.trainingId;
        const trainingRole = match.params.trainingRole;

        const res = await getUsersInfoTraining(trainingId, crew.id, trainingRole);

        // Filter users based on crew ID and training role
        let filteredUsers = res.User2Trainings;

        // Only filter by crew if crew.id > 0
        if (crew.id > 0) {
          // First, check if the user has a role in this crew
          const userHasRoleInCrew = filteredUsers.some(
            (item) => item.Crew?.id === crew.id && item.training_role === trainingRole,
          );

          // If user has a role in this crew, only show users from this crew
          if (userHasRoleInCrew) {
            filteredUsers = filteredUsers.filter((item) => item.Crew?.id === crew.id);
          }
        }

        // Additionally filter by training_role if specified
        if (trainingRole) {
          filteredUsers = filteredUsers.filter((item) => item.training_role === "user");
        }

        // Set state values from response
        setIsElearning(res.is_e_learning ?? 0);
        setIsClassroom(res.is_classroom ?? 0);
        setIsPractical(res.is_practical ?? 0);
        setIsClassroomAttendance(res.classroom_attendance ?? 0);
        setIsClassroomCheck(res.classroom_check ?? 0);
        setIsInstructorComplete(res.instructor_complete ?? 0);
        setIsAssessorPassed(res.assessor_passed ?? 0);
        setIsCheckerPassed(res.checker_passed ?? 0);

        // Set course info
        if (res.Course) {
          setCourseId(res.Course.id);
          setValidPeriod(res.Course?.ValidPeriod?.valid_period);
        }

        console.log({ filteredUsers });
        setUsersInfo(filteredUsers);
      } catch (error) {
        console.error("Error fetching training info:", error);
        setUsersInfo([]);
      }
    })();
  }, [refresh, crew, match.params]);

  const onEventSwitch = async (id, field, value, userInfo) => {
    const res = await updateCheckingProgress(id, field, value, evaluator.id);

    let resAfterUpdateModule = null;
    if (isClassroom) {
      if (isClassroomAttendance == res.classroom_attendance && isClassroomCheck == res.classroom_test) {
        resAfterUpdateModule = await updateModuleStatus(id, "classroom_status", 1);
      } else {
        resAfterUpdateModule = await updateModuleStatus(id, "classroom_status", 0);
      }
    }

    if (isPractical) {
      if (
        isInstructorComplete == res.instructor_complete &&
        isAssessorPassed == res.assessor_passed &&
        isCheckerPassed == res.checker_passed
      ) {
        resAfterUpdateModule = await updateModuleStatus(id, "practical_status", 1);
      } else {
        resAfterUpdateModule = await updateModuleStatus(id, "practical_status", 0);
      }
    }

    const userId = userInfo.User.id;

    if (
      isElearning == resAfterUpdateModule.elearning_status &&
      isClassroom == resAfterUpdateModule.classroom_status &&
      isPractical == resAfterUpdateModule.practical_status
    ) {
      await updateStatusCertificate(userId, courseId, 1, validPeriod);
    } else {
      await updateStatusCertificate(userId, courseId, 0, validPeriod);
    }

    setRefresh(refresh * -1);
  };

  return (
    <>
      <Typography variant="h4">Users training</Typography>
      {usersInfo ? (
        <TableComponent
          header={[
            { title: "Training User ID", style: { width: "5%" } },
            // { title: "Training" },
            { title: "User" },

            { title: "Crew" },
            /* 
            { title: "Role"},
             */
            { title: "E learning" },
            { title: "Classroom" },
            { title: "Practical" },
            { title: "Actions" },
          ]}
          renderChildren={(userInfo) => (
            <TableRow key={userInfo.id}>
              <TableCell>{userInfo.id}</TableCell>
              <TableCell>{userInfo.User.fullname}</TableCell>
              <TableCell>{userInfo.Crew.company_name}</TableCell>

              <TableCell>
                {isElearning == 1 ? (
                  userInfo.elearning_status ? (
                    <CheckCircleIcon htmlColor="green" />
                  ) : (
                    <ErrorIcon htmlColor="red" />
                  )
                ) : (
                  ""
                )}
              </TableCell>

              <TableCell>
                {isClassroom == 1 ? (
                  userInfo.classroom_status ? (
                    <CheckCircleIcon htmlColor="green" />
                  ) : (
                    <>
                      <ErrorIcon htmlColor="red" />
                      {isClassroomAttendance == 1 ? (
                        <p>
                          Attendance:
                          <StyledSwitch
                            title="Classroom Attendance"
                            checked={userInfo.classroom_attendance === 1}
                            onChange={() => {
                              const valSwitch = userInfo.classroom_attendance === 1 ? 0 : 1;
                              onEventSwitch(userInfo.id, "classroom_attendance", valSwitch, userInfo);
                            }}
                          />
                        </p>
                      ) : (
                        ""
                      )}
                      {isClassroomCheck == 1 ? (
                        <p>
                          Check:
                          <StyledSwitch
                            title="Classroom Attendance"
                            checked={userInfo.classroom_test === 1}
                            onChange={() => {
                              const valSwitch = userInfo.classroom_test === 1 ? 0 : 1;
                              onEventSwitch(userInfo.id, "classroom_test", valSwitch, userInfo);
                            }}
                          />
                        </p>
                      ) : (
                        ""
                      )}
                    </>
                  )
                ) : (
                  ""
                )}
              </TableCell>

              <TableCell>
                {isPractical == 1 ? (
                  userInfo.practical_status ? (
                    <CheckCircleIcon htmlColor="green" />
                  ) : (
                    <>
                      <ErrorIcon htmlColor="red" />
                      {isInstructorComplete == 1 ? (
                        <p>
                          Instructor:
                          <StyledSwitch
                            title="Instructor complete"
                            checked={userInfo.instructor_complete === 1}
                            onChange={() => {
                              const valSwitch = userInfo.instructor_complete === 1 ? 0 : 1;
                              onEventSwitch(userInfo.id, "instructor_complete", valSwitch, userInfo);
                            }}
                          />
                        </p>
                      ) : (
                        ""
                      )}
                      {isAssessorPassed == 1 ? (
                        <p>
                          Assessor:
                          <StyledSwitch
                            title="Assessor passed"
                            checked={userInfo.assessor_passed === 1}
                            onChange={() => {
                              const valSwitch = userInfo.assessor_passed === 1 ? 0 : 1;
                              onEventSwitch(userInfo.id, "assessor_passed", valSwitch, userInfo);
                            }}
                          />
                        </p>
                      ) : (
                        ""
                      )}
                      {isCheckerPassed == 1 ? (
                        <p>
                          Checker:
                          <StyledSwitch
                            title="Checker passed"
                            checked={userInfo.checker_passed === 1}
                            onChange={() => {
                              const valSwitch = userInfo.checker_passed === 1 ? 0 : 1;
                              onEventSwitch(userInfo.id, "checker_passed", valSwitch, userInfo);
                            }}
                          />
                        </p>
                      ) : (
                        ""
                      )}
                    </>
                  )
                ) : (
                  ""
                )}
              </TableCell>

              <TableCell>
                {isElearning == userInfo.elearning_status &&
                isClassroom == userInfo.classroom_status &&
                isPractical == userInfo.practical_status ? (
                  <Button
                    title="Download Certificate"
                    icon={<GetAppIcon />}
                    onClick={() => openExportWindow(courseId, userInfo.user_id)}
                  />
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
          )}
          search={["displayname", "fullname", "company_name"]}
          pagination
          items={usersInfo}
        />
      ) : null}

      <Button title="Go back" type="button" onClick={() => history.goBack()}>
        Return to all Trainings
      </Button>
    </>
  );
};

export default UsersTrainingInscribedTable;
