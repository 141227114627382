import React from "react";

import { Switch, Route } from "react-router";
import OperationsAdd from "../../features/operations-add";
import OperationsEdit from "../../features/operations-edit";
import OperationsTable from "../../features/operations-table";

const Operations = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={OperationsTable} />
        <Route exact path={`${match.path}/add_operation`} component={OperationsAdd} />
        <Route exact path={`${match.path}/:operationId`} component={OperationsEdit} />
      </Switch>
    </>
  );
};

export default Operations;
