import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, InputForm, Validation, DatePicker } from "../../components/Form";
import Autocomplete from "../../components/Form/StyledAutocomplete";
import { BasePaper, Row } from "../../components/Base";
import Button from "../../components/Button";
import { StyledSwitch } from "../../components/Base";
import { getProcessStepById, createProcessStep, updateProcessStep } from "../../api/process-step";
import processStepList from "../../services/typeProcessStep.json";
import { getActivitiesTraining } from "../../api/activities-training";

const EditProcessStep = ({ match, history }) => {
  const { processStepId } = match.params;
  const isAdd = !processStepId;
  const [processStep, setProcessStepRes] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [selectedProcessStepType, setProcessStepTypes] = useState();
  const [ActivitiesTrainings, setActivitiesTrainings] = useState([]);
  const [selectedActivitiesTrainings, setSelectedActivitiesTrainings] = useState([]);

  const [selectedStatus, setStatus] = useState("active");

  useEffect(() => {
    (async () => {
      const activitiesTrainings = await getActivitiesTraining();
      setActivitiesTrainings(activitiesTrainings);
      if (processStepId) {
        const processStepRes = await getProcessStepById(processStepId);
        setProcessStepRes(processStepRes);
        const itemProcessStep = processStepList.find((c) => c.code === processStepRes.type);
        setProcessStepTypes(itemProcessStep);
        setStatus(processStepRes.status);
        setSelectedActivitiesTrainings(processStepRes.activitiesTrainings);
      }
      setLoaded(true);
    })();
  }, [processStepId]);

  const goBack = () => history.goBack();
  const onSubmit = async (values) => {
    if (selectedProcessStepType) {
      values.type = selectedProcessStepType?.code;
    } else {
      values.type = "";
    }
    values.status = selectedStatus;

    const activitiesTrainingsIds = selectedActivitiesTrainings.map((c) => c.id);

    let result;
    if (isAdd) {
      result = await createProcessStep(values, activitiesTrainingsIds);
    } else {
      result = await updateProcessStep(processStepId, values, activitiesTrainingsIds);
    }
    if (result.ok) {
      history.push("/process-step");
    }
  };

  const title = isAdd ? "Add new process step" : "Edit process step";

  return (
    <>
      <Typography variant="h4">
        <Button icon={<ArrowBackIcon />} onClick={goBack} />
        {title}
      </Typography>
      {loaded ? (
        <BasePaper>
          <Form
            defaultValues={{
              name: processStep?.name,
              type: processStep?.type,
            }}
            validation={{
              name: Validation.required,
            }}
            onSubmit={onSubmit}
          >
            <Row>
              <InputForm name="name" label="Process Step name*" />
            </Row>
            <Row>
              <Autocomplete
                label="Process Step type"
                items={processStepList}
                selected={selectedProcessStepType}
                onChange={(items) => {
                  setProcessStepTypes(items);
                }}
                formatLabel={(item) => `${item.name}`}
              />
            </Row>

            <Row>
              <Autocomplete
                label="Activities Trainings List"
                items={ActivitiesTrainings}
                selected={selectedActivitiesTrainings}
                multiple
                onChange={(items) => {
                  setSelectedActivitiesTrainings(items);
                }}
                formatLabel={(item) => `(${item.id}) ${item.name}`}
              />
            </Row>

            <Row>
              Status{" "}
              <StyledSwitch
                title="Change status"
                checked={selectedStatus === "active"}
                onChange={() => setStatus(selectedStatus === "active" ? "passive" : "active")}
              />
            </Row>

            <Row>
              <Button type="submit">Save</Button>
              <Button onClick={goBack}>Cancel</Button>
            </Row>
          </Form>
        </BasePaper>
      ) : null}
    </>
  );
};

export default EditProcessStep;
