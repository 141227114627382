import { COURSE_STATUS } from "../../constants";
import CertificateService, * as certificate from "../../services/certificate";
import CourseService from "../../services/course";

const userMatchOperation = (user, operation) => {
  const courses = [];

  for (const course of operation.courses) {
    courses.push(certificate.userCourseMatch(course, user));
  }

  for (const customCourse of operation.customCourses) {
    courses.push(CertificateService.userCustomCertificateMatch(customCourse, user));
  }

  for (const grp of operation.groups) {
    courses.push(certificate.userGroups(grp, user));
  }

  return courses;
};

const isNotOk = (course) => course.status === COURSE_STATUS.MISSING || course.status === COURSE_STATUS.EXPIRED;

const isBuffer = (course) => course.status === COURSE_STATUS.BUFFER;

const operationStatus = (courses) => {
  if (courses.some(isNotOk)) {
    return COURSE_STATUS.MISSING;
  }
  if (courses.some(isBuffer)) return COURSE_STATUS.BUFFER;
  return COURSE_STATUS.OK;
};

export const getUserPositionsDescription = (user, crew) => {
  const mappedUser = certificate.mapUserCertificates(user);

  const positions = [];
  const userPositions = Array.from(new Set(user.assignedRoles.map((p) => p.position_id))).map((pid) => ({
    ...user.assignedRoles.find((p) => p.position_id === pid),
  }));

  for (const position of userPositions) {
    const crewPosition = crew.find((co) => co.position_id === position.position_id);
    if (crewPosition) {
      const courses = userMatchOperation(mappedUser, crewPosition);
      positions.push({
        category_name: crewPosition.category_name,
        position_name: crewPosition.position_name,
        operation_name: crewPosition.operation_name,
        position_id: crewPosition.position_id,
        operation_id: crewPosition.operation_id,
        category_id: crewPosition.category_id,
        status: operationStatus(courses),
        courses,
      });
    }
  }

  const sorted = positions.sort(
    (a, b) => a.category_id - b.category_id || a.position_id - b.position_id || a.operation_id - b.operation_id,
  );

  return {
    user_id: user.user_id,
    name: user.name,
    email: user.email,
    certificates: sorted,
  };
};

const byPosition = (categoryId, positionId) => (course) => {
  if (CourseService.isAircraftCourse(course) || course.hidden) {
    return false;
  }

  return (
    !course.operationId && (course.positionId === positionId || !course.positionId) && course.categoryId === categoryId
  );
};

const toDisplayObject = (certificates, metadata) => (position) => {
  const title = `${metadata.categories.find((category) => category.id === position.categoryId).name}  →  ${
    metadata.positions.find((pos) => pos.id === position.positionId).name
  }`;
  const certificatesRet = [];

  for (const course of position.courses) {
    const matched = CertificateService.mapToCourse(course, certificates);
    certificatesRet.push(matched);
  }

  for (const customCourse of position.customCourses) {
    const matched = CertificateService.mapToCustomCourse(customCourse, certificates);
    certificatesRet.push(matched);
  }

  for (const group of position.groups) {
    const matched = CertificateService.mapToGroup(group, certificates);
    certificatesRet.push(matched);
  }

  return {
    title,
    status: CertificateService.groupStatus(certificatesRet),
    certificates: certificatesRet,
  };
};

const PositionsService = {
  getPositions(properties, courses, customCourses, groups) {
    const positions = {};
    properties.forEach((property) => {
      const positionId = property.positionId;
      const fn = byPosition(property.categoryId, positionId);
      if (!positions[positionId]) {
        positions[positionId] = {
          categoryId: property.categoryId,
          positionId: positionId,
          courses: courses.filter(fn).reduce(CourseService.deduplicate, []),
          customCourses: customCourses.filter(fn).reduce(CourseService.deduplicate, []),
          groups: groups.filter(fn).reduce(CourseService.groupDeduplicate, []),
        };
      }
    });
    return Object.values(positions).sort(CourseService.sortCourses);
  },
  toDisplayObject(positions, certificates, metadata) {
    return positions.map(toDisplayObject(certificates, metadata));
  },
};

export default PositionsService;
