import React from "react";
import { Route } from "react-router-dom";

import AssociateOperationProcessStepTable from "../../features/associate-operation-process-step";
import EditAssociateOperationProcessStep from "../../features/associate-operation-process-step/edit";

const AssociateOperationProcessStep = ({ match }) => (
  <>
    <Route path={`${match.path}/add`} component={EditAssociateOperationProcessStep} />
    <Route path={`${match.path}/edit/:associateOperationProcessStepId`} component={EditAssociateOperationProcessStep} />
    <Route exact path={`${match.path}`} component={AssociateOperationProcessStepTable} />
  </>
);

export default AssociateOperationProcessStep;
