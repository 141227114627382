import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { BasePaper, Row } from "../../../components/Base";
import Button from "../../../components/Button";
import { AutocompleteForm, Form, InputForm, Validation } from "../../../components/Form";

import { getMoodleActiveCourses, mapCourseShort, CourseShort } from "../../../api/courses";
import { createBundle, getBundle, getBundles, updateBundle, BundleResponse } from "../../../api/courseBundle";
import { getCountries, Country } from "../../../api/country";
import { getCrews, Crew } from "../../../api/crew";

interface BundleForm {
  id: number;
  displayname: string;
  description: string;
  active: boolean;
  isRefresher?: boolean;
  price?: number;
  validPeriod?: number;
  courses: CourseShort[];
  countries: Country[];
  crews: Crew[];
  refresher: BundleResponse;
}

const BundleEditForm = () => {
  const history = useHistory();
  const goBack = () => history.goBack();
  const { t } = useTranslation();

  const { bundleId } = useParams<{ bundleId: string }>();
  const isAdd = !bundleId;

  const [loaded, setLoaded] = useState(false);
  const [bundle, setBundle] = useState<BundleForm | null>(null);
  const [countries, setCountries] = useState<Country[]>([]);
  const [courses, setCourses] = useState<CourseShort[]>([]);
  const [crews, setCrews] = useState<Crew[]>([]);
  const [allAvailableRefreshers, setAllAvailableRefreshers] = useState<BundleResponse[]>([]);

  useEffect(() => {
    (async () => {
      const [countries, crews, courses, bundleResponse, availableRefreshers] = await Promise.all([
        getCountries(),
        getCrews(),
        getMoodleActiveCourses().then((courses) => courses.map(mapCourseShort)),
        bundleId ? getBundle(bundleId) : Promise.resolve(null),
        getBundles({ activeOnly: true, refresher: true }),
      ]);

      setCountries(countries);
      setCrews(crews);
      setCourses(courses);
      setAllAvailableRefreshers(availableRefreshers);

      if (bundleResponse) {
        const form = {
          ...bundleResponse,
          countries: countries.filter((c: Country) => bundleResponse.countries.includes(c.id)),
          crews: crews.filter((c: Crew) => bundleResponse.crews.includes(c.id)),
          refresher: availableRefreshers.find((r: BundleResponse) => r.id === bundleResponse.refresher),
        };
        setBundle(form);
      }

      setLoaded(true);
    })();
  }, [bundleId]);

  const onSubmit = async (bundleForm: BundleForm) => {
    const bundleRequest = {
      ...bundleForm,
      active: bundleForm.active ?? true,
      countries: bundleForm.countries?.map((c: Country) => c.id) ?? [],
      courses: bundleForm.courses?.map((c: CourseShort) => c.id) ?? [],
      crews: bundleForm.crews?.map((c: Crew) => c.id) ?? [],
      isRefresher: !!bundleForm.isRefresher,
      price: bundleForm.price !== undefined ? +bundleForm.price : undefined,
      refresher: bundleForm.refresher?.id ?? null,
      validPeriod: bundleForm.validPeriod !== undefined ? +bundleForm.validPeriod : undefined,
    };

    const result = isAdd ? await createBundle(bundleRequest) : await updateBundle(bundleId, bundleRequest);

    if (result.ok) {
      history.push("/bundles");
    }
  };

  const title = isAdd ? t("course_bundle.add_action") : t("course_bundle.edit_action");

  return (
    <>
      <Typography component="h2" variant="h4">
        <Button icon={<ArrowBackIcon />} onClick={goBack} />
        {title}
      </Typography>
      {loaded && (
        <BasePaper>
          <Form
            defaultValues={{ ...bundle }}
            validation={{
              displayname: Validation.required,
            }}
            onSubmit={onSubmit}
          >
            <Row>
              <InputForm name="displayname" label={`${t("course_bundle.name")}*`} />
            </Row>
            <Row>
              <InputForm name="description" label={t("course_bundle.description")} />
            </Row>
            <Row>
              <InputForm name="price" label={t("course_bundle.price")} type="number" />
            </Row>
            <Row>
              <InputForm name="validPeriod" label={t("course_bundle.validPeriod")} type="number" />
            </Row>
            <Row>
              <AutocompleteForm name="courses" label={t("course_bundle.courses")} items={courses} multiple />
            </Row>
            <Row>
              <AutocompleteForm name="countries" label={t("course_bundle.countries")} items={countries} multiple />
            </Row>
            <Row>
              <AutocompleteForm name="crews" label={t("course_bundle.crews")} items={crews} multiple />
            </Row>
            {!bundle?.isRefresher && (
              <Row>
                <AutocompleteForm
                  name="refresher"
                  label={t("course_bundle.refresher")}
                  items={allAvailableRefreshers}
                />
              </Row>
            )}
            <Row>
              <Button type="submit">{t("save")}</Button>
              <Button onClick={goBack}>{t("cancel")}</Button>
            </Row>
          </Form>
        </BasePaper>
      )}
    </>
  );
};

export default BundleEditForm;
