import React, { useState, useEffect } from "react";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import CircularProgress from "@mui/material/CircularProgress";
import CrewItem from "./CrewItem";
import { getCrews, updateCrew, inscribeCrewToEfb, uninscribeCrewToEfb } from "../../api/crew";
import Stack from "@mui/material/Stack";

const CrewsTable = ({ match, history }) => {
  const [crews, setCrews] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [loading, setLoading] = React.useState(false);

  function onEdit(id) {
    history.push(`${match.path}/${id}`);
  }

  async function onStatusChange(id, newStatus) {
    await updateCrew(id, { status: newStatus });
    setRefresh(refresh * -1);
  }

  async function onEfbChange(id, newEfb, companyName) {
    setLoading(true);

    await updateCrew(id, { efb: newEfb });

    if (newEfb == "yes") {
      await inscribeCrewToEfb(id, companyName);
    } else {
      await uninscribeCrewToEfb(id, companyName);
    }

    //await new Promise(r => setTimeout(r, 3000));
    setLoading(false);
    //await inscribeCrewToEfb(id, companyName)
    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      const crews = await getCrews();
      setCrews(crews);
    })();
  }, [refresh]);

  return (
    <>
      <h2>Companies</h2>
      {loading && (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      <TableComponent
        TopComponent={
          <Button size="small" onClick={() => history.push(`${match.path}/add_crew`)}>
            <PostAddIcon />
            Add new company
          </Button>
        }
        header={[
          { title: "ID", sort: "id", style: { width: "5%" } },
          { title: "Company Name", sort: "companyName" },
          { title: "Contact Person", sort: "contactPerson" },
          { title: "Contact Email", sort: "email" },
          { title: "Contact Phone", sort: "phone" },
          { title: "Status", sort: "status" },
          //{ title: "Enable Cloud"},
          { title: "Address", sort: "address" },
          { title: "Postcode", sort: "postcode" },
          { title: "City", sort: "city" },
          { title: "Actions" },
        ]}
        renderChildren={(crew) => (
          <CrewItem
            key={crew.id}
            onStatusChange={onStatusChange}
            onEfbChange={onEfbChange}
            loading={loading}
            onEdit={onEdit}
            crew={crew}
          />
        )}
        items={crews}
        search={["companyName", "id"]}
        pagination
      />
    </>
  );
};

export default CrewsTable;
