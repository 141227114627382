import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "../../components/Button";

import { getCategories } from "../../api/categories";
import { updatePosition, getPositionById } from "../../api/positions";

import "./index.scss";

const POSITION_ERROR_TEXT = "Please enter the name of position";

const PositionsEdit = ({ match, history }) => {
  const [newPositionName, setNewPositionName] = useState("");
  const [categories, setCategories] = useState([]);
  const [newPositionCategoryId, setNewPositionCategoryId] = useState("");
  const [isDataFetching, setIsDataFetching] = useState(false);

  useEffect(() => {
    (async () => {
      setIsDataFetching(true);
      const position = await getPositionById(match.params.positionId);

      setNewPositionName(position.name);
      setNewPositionCategoryId(position.category.id);

      let newCategories = await getCategories();
      setCategories(newCategories);
      setIsDataFetching(false);
    })();
  }, [match.params.positionId]);

  async function editPosition() {
    if (!newPositionName) return;
    const positionId = match.params.positionId;

    const result = await updatePosition(positionId, {
      name: newPositionName,
      categoryId: newPositionCategoryId,
    });
    if (result.ok) {
      history.push("/positions");
    }
  }

  return (
    <>
      <h2>Edit position</h2>
      <Paper className="add-position" variant="outlined" style={{ padding: "1rem 1.5rem" }}>
        {!isDataFetching ? (
          <>
            <FormControl variant="outlined" size="small">
              <InputLabel id="selectCategory">Select Category</InputLabel>
              <Select
                className="select-category"
                labelId="selectCategory"
                label="Select Category"
                value={newPositionCategoryId}
                onChange={(event) => setNewPositionCategoryId(event.target.value)}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              className="add-field"
              variant="outlined"
              size="small"
              label="Position name*"
              value={newPositionName}
              onChange={(event) => setNewPositionName(event.target.value)}
              error={!newPositionName}
              helperText={newPositionName ? null : POSITION_ERROR_TEXT}
            />
            <Button title="Save position" onClick={editPosition}>
              Save
            </Button>
            <Button title="Go back" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </>
        ) : null}
      </Paper>
    </>
  );
};

export default PositionsEdit;
