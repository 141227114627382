import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import CancelIcon from "@mui/icons-material/Cancel";

import Button from "../../../components/Button";
import { Row, FlexRow, BackButton, StyledPaper, StyledInput } from "../../../components/Base";

import { saveFrat, getFrat } from "../../../api/frat";
import { checklistActions, onFratLoadUpdateIdGen } from "./actions";

const CheckList = ({ item, actions, title }) => {
  const add = () => {
    actions.addSection();
  };
  return (
    <>
      <Row>
        <Typography variant="h5">{title}</Typography>
      </Row>
      {item?.sections?.map((section) => (
        <SectionItem key={item.id + ":" + section.id} actions={actions} section={section} />
      ))}
      <Button title="Add Section To FRAT" onClick={add}>
        Add Section
      </Button>
    </>
  );
};

const Div = () => <div style={{ display: "inline-block", marginRight: "1rem" }} />;

const SectionItem = ({ section, actions }) => {
  return (
    <>
      <FlexRow lineHeight={"2.5rem"}>
        <StyledInput
          label="Section Title"
          size="small"
          value={section.name}
          onChange={(value) => actions.setSectionName(section.id, value)}
        />
        <Div />
        <Button
          title="Remove Section From FRAT"
          onClick={() => actions.removeSection(section.id)}
          icon={<CancelIcon />}
        />
      </FlexRow>
      {section?.questions.map((question) => (
        <QuestionItem key={section.id + ":" + question.id} sectionId={section.id} actions={actions} item={question} />
      ))}
      <Row>
        <Button
          onClick={() =>
            actions.addQuestion({
              sectionId: section.id,
            })
          }
          title="Add Question To Section"
        >
          Add Question
        </Button>
      </Row>
      <Row>
        <Divider variant="inset" />
      </Row>
    </>
  );
};

const SubSectionRow = ({ children }) => (
  <Row ml={1} pl={1} borderLeft={"1px dotted lightgrey"}>
    {children}
  </Row>
);

const QuestionItem = ({ sectionId, item, actions }) => (
  <SubSectionRow>
    <FlexRow lineHeight={"2.5rem"}>
      <StyledInput
        name="name"
        label="Question"
        value={item.question}
        onChange={(value) => actions.setQuestionProp(sectionId, item.id, "question", value)}
      />
      <Div />
      <StyledInput
        name="description"
        label="Question Description"
        value={item.description}
        onChange={(value) => actions.setQuestionProp(sectionId, item.id, "description", value)}
      />
      <Div />
      <Button
        title="Remove Question From Section"
        onClick={() => actions.removeQuestion(sectionId, item.id)}
        icon={<CancelIcon />}
      />
    </FlexRow>
    <AnswersList question={item} sectionId={sectionId} actions={actions} />
    <MitigationList question={item} sectionId={sectionId} actions={actions} />
  </SubSectionRow>
);

const AnswersList = ({ question, sectionId, actions }) => (
  <SubSectionRow>
    <Row>
      <Typography variant="h6">Answers:</Typography>
    </Row>
    {question.answers.map((answer) => (
      <AnswerItem
        key={sectionId + ":" + question.id + ":" + answer.id}
        question={question}
        answer={answer}
        actions={actions}
        sectionId={sectionId}
      />
    ))}
    <Button onClick={() => actions.addAnswer(sectionId, question.id)}>Add Answer</Button>
  </SubSectionRow>
);

const AnswerItem = ({ answer, sectionId, question, actions }) => (
  <FlexRow lineHeight={"2.5rem"}>
    <StyledInput
      label="Answer"
      value={answer.answer}
      onChange={(value) => actions.setAnswerProp(sectionId, question.id, answer.id, "answer", value)}
    />
    <Div />
    <label style={{ display: "inline-block", color: "gray" }}>Severity: </label>
    <Div />
    <Select
      label="Severity"
      id={`${sectionId}${question.id}${answer.id}`}
      value={answer.severity}
      onChange={({ target }) => actions.setAnswerProp(sectionId, question.id, answer.id, "severity", target.value)}
    >
      <MenuItem value={0}>0</MenuItem>
      <MenuItem value={1}>1</MenuItem>
      <MenuItem value={2}>2</MenuItem>
    </Select>
    <Div />
    <Button
      title="Remove Answer From Question"
      onClick={() => actions.removeAnswer(sectionId, question.id, answer.id)}
      icon={<CancelIcon />}
    />
  </FlexRow>
);

const MitigationList = ({ sectionId, question, actions }) => (
  <SubSectionRow>
    <Row>
      <Typography variant="h6">Mitigation Measures:</Typography>
    </Row>
    {question.mitigationMeasures.map((mitigation) => (
      <MitigationItem
        key={sectionId + ":" + question.id + ":" + mitigation.id}
        mitigation={mitigation}
        sectionId={sectionId}
        question={question}
        actions={actions}
      />
    ))}
    <Button onClick={() => actions.addMitigation(sectionId, question.id)}>Add Mitigation</Button>
  </SubSectionRow>
);

const MitigationItem = ({ mitigation, sectionId, question, actions }) => (
  <FlexRow lineHeight={"2.5rem"}>
    <StyledInput
      label="Mitigation Measure"
      value={mitigation.mitigationMeasure}
      onChange={(value) => actions.setMitigationProp(sectionId, question.id, mitigation.id, "mitigationMeasure", value)}
    />
    <Div />
    <label style={{ display: "inline-block", color: "gray" }}>Level: </label>
    <Div />
    <Select
      label="Level"
      value={mitigation.level}
      onChange={({ target }) => actions.setMitigationProp(sectionId, question.id, mitigation.id, "level", target.value)}
    >
      <MenuItem value={0}>0</MenuItem>
      <MenuItem value={1}>1</MenuItem>
      <MenuItem value={2}>2</MenuItem>
    </Select>
    <Div />
    <label style={{ display: "inline-block", color: "gray" }}>Reduction: </label>
    <Div />
    <Select
      label="Reduction"
      value={mitigation.reduction}
      onChange={({ target }) =>
        actions.setMitigationProp(sectionId, question.id, mitigation.id, "reduction", target.value)
      }
    >
      <MenuItem value={0}>0</MenuItem>
      <MenuItem value={1}>1</MenuItem>
      <MenuItem value={2}>2</MenuItem>
    </Select>
    <Div />
    <Button
      title="Remove Mitigation From Question"
      onClick={() => actions.removeMitigation(sectionId, question.id, mitigation.id)}
      icon={<CancelIcon />}
    />
  </FlexRow>
);

const EditFrat = ({ match, history }) => {
  const { fratId } = match.params;
  const [name, setName] = useState(null);
  // const [missionType, setMissionType] = useState(null);
  const [preflightChecklist, setPreflightChecklist] = useState(null);
  const [inflightChecklist, setInflightChecklist] = useState(null);
  const [postflightChecklist, setPostflightChecklist] = useState(null);

  const preflightActions = checklistActions(
    () => preflightChecklist,
    (items) => {
      setPreflightChecklist(items);
    },
  );
  const inflightActions = checklistActions(
    () => inflightChecklist,
    (items) => {
      setInflightChecklist(items);
    },
  );
  const postflightActions = checklistActions(
    () => postflightChecklist,
    (items) => {
      setPostflightChecklist(items);
    },
  );

  useEffect(() => {
    (async () => {
      if (fratId) {
        const existingFrat = await getFrat(fratId);
        setName(existingFrat.name);
        setPreflightChecklist(existingFrat.preflightChecklist);
        setInflightChecklist(existingFrat.inflightChecklist);
        setPostflightChecklist(existingFrat.postflightChecklist);
        onFratLoadUpdateIdGen(existingFrat);
      }
    })();
  }, [fratId]);

  const save = async () => {
    const { crewId } = match.params;
    const res = await saveFrat(crewId, fratId, {
      name,
      preflightChecklist: preflightChecklist || {},
      postflightChecklist: postflightChecklist || {},
      inflightChecklist: inflightChecklist || {},
    });
    if (res.ok) {
      history.replace(`/crew/${crewId}/st/frat`);
    }
  };

  const pageTitle = !!fratId ? "Edit FRAT" : "Add Frat";

  return (
    <>
      <FlexRow justifyContent="left">
        <BackButton history={history} />
        <Typography variant="h4">{pageTitle}</Typography>
      </FlexRow>
      <StyledPaper variant="outlined">
        <Row>
          <Row>
            <StyledInput fullWidth={true} value={name || ""} label="FRAT Name" onChange={(value) => setName(value)} />
          </Row>
          <StyledInput fullWidth disabled name="mt" label="Mission Type" />
        </Row>
        <Row>
          {preflightChecklist ? (
            <CheckList item={preflightChecklist} actions={preflightActions} title="Pre-Flight Checklist" />
          ) : (
            <Button onClick={preflightActions.add}>Add Pre-Flight Checklist</Button>
          )}
        </Row>
        <Row>
          <Divider />
        </Row>
        <Row>
          {inflightChecklist ? (
            <CheckList item={inflightChecklist} actions={inflightActions} title="In-Flight Checklist" />
          ) : (
            <Button onClick={inflightActions.add}>Add In-Flight Checklist</Button>
          )}
        </Row>
        <Row>
          <Divider />
        </Row>
        <Row>
          {postflightChecklist ? (
            <CheckList item={postflightChecklist} actions={postflightActions} title="Post-Flight Checklist" />
          ) : (
            <Button onClick={postflightActions.add}>Add Post-Flight Checklist</Button>
          )}
        </Row>
        <Row>
          <Divider />
        </Row>
        <Button onClick={save} title="Save FRAT">
          Save FRAT
        </Button>
      </StyledPaper>
    </>
  );
};

export default EditFrat;
