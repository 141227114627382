import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import { StyledSwitch } from "../../components/Base";
import {
  getProgressPassCondition,
  getUserCertificate,
  addPassConditionTrainingUser,
  updateTrainingModuleStatus,
  updateCertificate,
} from "../../api/training";
import { getMoodleCourseById } from "../../api/courses";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";

const getModuleStatus = (passConditions, moduleTraining) => {
  const modulePassed = passConditions.filter((elem) => elem.module === moduleTraining).map((i) => i.status);
  if (!modulePassed.includes(false)) {
    return 1;
  } else {
    return 0;
  }
};

const ProgressTraining = ({ match, history }) => {
  const [refresh, setRefresh] = useState(1);
  const [passCondition, setPassCondition] = useState([]);
  const [userId, setUserId] = useState([]);

  useEffect(() => {
    (async () => {
      const trainingId = match.params.trainingId;
      const userId = parseInt(match.params.userId);
      const courseId = parseInt(match.params.courseId);

      const passConditionList = await getProgressPassCondition(trainingId, userId);
      const certificate = await getUserCertificate(courseId, userId);
      const course = await getMoodleCourseById(courseId);

      const eLearningStatus = getModuleStatus(passConditionList, "elearning");
      const classroomStatus = getModuleStatus(passConditionList, "classroom");
      const practicalStatus = getModuleStatus(passConditionList, "practical");

      /*       
      updateTrainingModuleStatus(trainingId, userId, eLearningStatus , classroomStatus, practicalStatus) 

      if (certificate.length === 0 && eLearningStatus === 1 && classroomStatus === 1 && practicalStatus === 1 ){
        updateCertificate(courseId, userId, 1, course.validPeriod)
      }  else if (certificate.length > 0 && (eLearningStatus === 0 || classroomStatus === 0 || practicalStatus === 0) ){
        updateCertificate(courseId, userId, 0, course.validPeriod)
      } 
 */
      setPassCondition(passConditionList);
    })();
  }, [refresh]);
  return (
    <>
      <Typography variant="h4">Checking Progress</Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Module</TableCell>
              <TableCell>Pass Conditions</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {passCondition.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.module}</TableCell>
                <TableCell>{row.passConditionName}</TableCell>
                <TableCell>{row.passConditionType}</TableCell>
                <TableCell>{row.passConditionValue}</TableCell>
                <TableCell>
                  <StyledSwitch
                    title="Passed"
                    checked={row.passConditionTrainingUserId != null}
                    onChange={async () => {
                      await addPassConditionTrainingUser(row.passConditionTrainingUserId, row.userId, row.id);

                      setRefresh(refresh + 1);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button title="Go back" type="button" onClick={() => history.push("/training-users/user")}>
        Return to list Training Users
      </Button>
    </>
  );
};

export default ProgressTraining;
