import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getActivitiesTraining, deleteActivitiesTrainingById } from "../../api/activities-training";
import Confirm from "../../components/Confirm";

//import ActivitiesTrainingTable from "../../features/activities-training";
//import EditActivitiesTraining from "../../features/activities-training/edit";

const ActivitiesTrainingTable = ({ match, history }) => {
  const [activitiesTraining, setActivitiesTraining] = useState(null);

  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id) {
    setSelectedOperationId(id);
    setIsDeleteModalShow(true);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteActivitiesTrainingById(selectedOperationId);
    }
    setIsDeleteModalShow(false);
    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      const res = await getActivitiesTraining();

      setActivitiesTraining(res);
    })();
  }, [refresh]);

  return (
    <>
      <Typography variant="h4">Activities Training</Typography>
      {activitiesTraining ? (
        <TableComponent
          TopComponent={
            <Button size="small" onClick={() => history.push(`/activities-training/add`)}>
              <PostAddIcon />
              Add New activity
            </Button>
          }
          search={["name"]}
          header={[
            { title: "ID", style: { width: "5%" } },
            { title: "Name" },
            { title: "Status" },
            { title: "Observable" },
            { title: "Actions" },
          ]}
          items={activitiesTraining}
          renderChildren={(ps) => (
            <TableRow key={ps.id}>
              <TableCell>{ps.id}</TableCell>
              <TableCell>{ps.name}</TableCell>
              <TableCell>{ps.status === "active" ? "Active" : "Not Active"}</TableCell>
              <TableCell>
                {ps.observable.map((o) => (
                  <li key={`${ps.id}-${o.id}`}>
                    {o.id} - {o.name}
                  </li>
                ))}
              </TableCell>

              <TableCell>
                <Button
                  icon={<EditIcon />}
                  onClick={() => history.push(`/activities-training/edit/${ps.id}`)}
                  title="Edit Activities Training"
                />
                <Button
                  icon={<DeleteForeverIcon />}
                  onClick={() => showDeleteModal(ps.id)}
                  title="Delete Activities Training"
                />
              </TableCell>
            </TableRow>
          )}
        />
      ) : null}

      {isDeleteModalShow && selectedOperationId ? (
        <Confirm title={"Delete Activities Training"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the operation?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default ActivitiesTrainingTable;
