import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router";
import Box from "@mui/material/Box";
import SafetyTools from "../../safety-tools";
import Sidebar from "../../features/sidebar";
import CrewPage from "../crew-page";
import Orders from "../orders";
import OrdersCrew from "../orders-crew";
import UploadBankFile from "../upload-bank-file";
import TransactionsBank from "../transactions-bank";
import Categories from "../categories";
import GroupAircraft from "../group-aircraft";
import Positions from "../positions";
import Operations from "../operations";
import Aircraft from "../aircraft";
import Specializations from "../specializations";
import Users from "../users";
import Crews from "../crews";
import Certificate from "../certificate";
import CoursesGroups from "../courses-groups";
import News from "../news";
import NgftApps from "../ngft-apps";
import CrewNgftApps from "../crew-ngft-apps";
import CockpitPermissionRoles from "../cockpit-permission-roles";
import RelationsPage from "../relations";
import UserPage from "../user-page";
import { OrderPaidFail, OrderPaidSuccess, OrderPaidSuccessUpdateFailed } from "../order-paid";
import Courses from "../courses";
import Profile from "../profile";
import Properties from "../properties";
import AllUserCertificates from "../../features/all-user-certificates";
import CrewSubscriptions from "../../features/crew-subscriptions";
import Bundles from "../bundles";
import Trainings from "../trainings";
import PassConditions from "../pass-conditions";
import Observable from "../observable";
import ProcessStep from "../process-step";
import ActivitiesTraining from "../activities-training";
import AssociateOperationProcessStep from "../associate-operation-process-step";
import AssociatePositionProcessStep from "../associate-position-process-step";
import TrainingUsers from "../training-users";
import RolesTree from "../../features/roles-tree";
import Welcome from "../../components/Welcome";
import OverviewTrainingCourses from "../overview-training-courses";
import TrainingDependencies from "../../features/training-dependencies";
import UserOverview from "../user-overview";
import UserTrainings from "../user-trainings";
import TrainerOverview from "../trainer-overview";
import TrainerTrainings from "../trainer-trainings";
import SystemTrainerTrainings from "../system-trainer-trainings";
import CrewTrainings from "../crew-trainings";
import CrewUsersTrainings from "../crew-users-trainings";
import InstructurTrainings from "../instructor-trainings";
import AssessorTrainings from "../assessor-trainings";
import CheckerTrainings from "../checker-trainings";

//system-trainer-trainings

import "./index.scss";

const CrewOverview = ({ menuOpen, setMenuOpen }) => {
  return (
    <>
      <Box display="flex" flexDirection={"row"} padding="1rem 0rem 0 1rem" alignItems={"stretch"}>
        <Box flex={"0 0 22rem"} marginRight="3rem" className={"sidebar-wrapper " + (menuOpen && "active")}>
          <Sidebar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </Box>
        <Box
          flex={"1 1 auto"}
          style={{
            paddingRight: "1rem",
            overflow: "auto",
            maxHeight: "calc(100vh - 6rem)",
          }}
        >
          <Switch>
            CrewOverview
            <Route path="/order-paid=0" component={OrderPaidFail} />
            <Route path="/order-paid=1" component={OrderPaidSuccess} />
            <Route path="/order-paid=2" component={OrderPaidSuccessUpdateFailed} />
            <Route path="/courses" component={Courses} />
            <Route path="/courses_groups" component={CoursesGroups} />
            <Route path="/news" component={News} />
            <Route path="/ngft_apps" component={NgftApps} />
            <Route path="/crew_ngft_apps" component={CrewNgftApps} />
            <Route path="/cockpit_permission_roles" component={CockpitPermissionRoles} />
            <Route path="/trainings" component={Trainings} />
            <Route path="/pass-conditions" component={PassConditions} />
            <Route path="/observable" component={Observable} />
            <Route path="/process-step" component={ProcessStep} />
            <Route path="/activities-training" component={ActivitiesTraining} />
            <Route path="/associate-operation-process-step" component={AssociateOperationProcessStep} />
            <Route path="/associate-position-process-step" component={AssociatePositionProcessStep} />
            <Route path="/training-users/:trainingRole" component={TrainingUsers} />
            <Route path="/overview-training-courses" component={OverviewTrainingCourses} />
            <Route path="/trainings-dependencies" component={TrainingDependencies} />
            <Route path="/user-overview" component={UserOverview} />
            <Route path="/user-trainings" component={UserTrainings} />
            <Route path="/trainer-overview" component={TrainerOverview} />
            <Route path={["/crew-trainings/:crewId"]} component={CrewTrainings} />
            <Route path={["/crew-users-trainings/:crewId"]} component={CrewUsersTrainings} />
            <Route path="/trainer-trainings" component={TrainerTrainings} />
            <Route path="/system-trainer-trainings" component={SystemTrainerTrainings} />
            <Route path="/instructor-trainings" component={InstructurTrainings} />
            <Route path="/assessor-trainings" component={AssessorTrainings} />
            <Route path="/checker-trainings" component={CheckerTrainings} />
            <Route path={["/user/:userId/certificates", "/user/certificates"]} component={AllUserCertificates} />
            <Route path="/user" component={UserPage} />
            <Route path={["/crew/:crewId/relations", "/relations"]} component={RelationsPage} />
            <Route path={["/crew/:crewId/roles", "/roles"]} component={RolesTree} />
            <Route path="/orders" component={Orders} />
            <Route path="/upload-bank-file" component={UploadBankFile} />
            <Route path="/transactions-bank" component={TransactionsBank} />
            <Route path={["/crew/:crewId/st"]} component={SafetyTools} />
            <Route path="/crew" component={CrewPage} />
            <Route path="/categories" component={Categories} />
            <Route path="/positions" component={Positions} />
            <Route path="/operations" component={Operations} />
            <Route path="/aircraft" component={Aircraft} />
            <Route path="/orders-crew" component={OrdersCrew} />
            <Route path="/specializations" component={Specializations} />
            <Route path="/crews" component={Crews} />
            <Route path="/subscriptions" component={CrewSubscriptions} />
            <Route path="/users" component={Users} />
            <Route path="/certificates" component={Certificate} />
            <Route path="/profile" component={Profile} />
            <Route path="/bundles" component={Bundles} />
            <Route path="/properties" component={Properties} />
            <Route exact path="/">
              <UserOverview />
            </Route>
          </Switch>
        </Box>
      </Box>
    </>
  );
};

export default CrewOverview;
