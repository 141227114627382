import React from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

import Actions from "./Actions";
import CertificateStatus from "./CertificateStatus";
import CourseStatusIcon from "../CourseStatusIcon";

const Group = ({ certificate }) => {
  return (
    <TableRow>
      <TableCell style={{ width: "2%" }}>
        <CourseStatusIcon status={certificate.status} />
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          ({certificate.id}) {certificate.name}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          (g_{certificate.group.id}) {certificate.group.name}
        </Typography>
      </TableCell>
      <TableCell>
        {certificate.validPeriod && !isNaN(certificate.validPeriod) ? `${certificate.validPeriod} months` : ""}
      </TableCell>
      <TableCell style={{ width: "15%" }}>
        <CertificateStatus validUntil={certificate.validUntil} status={certificate.status} />
      </TableCell>
      <TableCell style={{ width: "12%" }} align={"justify"}>
        <Actions certificate={certificate} />
      </TableCell>
    </TableRow>
  );
};

export default Group;
