import { useEffect, useState } from "react";
import { Switch, Route } from "react-router";

import Box from "@mui/material/Box";

import Button from "../../components/Button";
import NavigationBack from "../../components/Navigation";
import UserTitle from "../../components/UserTitle";

import Operations from "../../features/operations";
import Positions from "../../features/positions";
import UserNavigation from "../../features/user-navigation";
import CrewCourses, { BundleCourses } from "../../features/crew-courses";
import Aircraft from "../../features/aircraft";
import Specializations from "../../features/specializations";
import CourseHistory from "../../features/course-history";
import CurrentUserCrewContext from "../../context/CurrentUserCrewContext";
import CertificateActions from "../../context/CertificateActions";
import { getAllCourses } from "../../api/courses";
import { getUserBoughtCourses } from "../../api/payment";
import { getUserCertificates, getUserProperties, getUserAircraft, getUserInfo } from "../../api/user";
import { getTrainingPurchaseCandidates } from "../../api/training";
import { addCoursesHierarchy } from "../../services/trainingHierarchy";

const UserCoursesPage = ({
  match,
  history,
  userId: propUserId,
  crewId: propCrewId,
  user: propUser,
  showBackButton = false,
  headerCaptions,
}) => {
  const [historyItem, setHistoryItem] = useState(null);
  const [certificates, setUserCertificates] = useState(null);
  const [courses, setCourses] = useState(null);
  const [properties, setProperties] = useState(null);
  const [aircraft, setAircraft] = useState(null);
  const [userInfo, setUserInfo] = useState(propUser || null);
  const [boughtCourses, setBoughtCourses] = useState(null);

  // Use props if provided, otherwise use from route params
  const userId = propUserId || match.params?.userId;
  const crewId = propCrewId || match.params?.crewId;

  useEffect(() => {
    (async () => {
      // If we don't have user info and we have a userId, fetch it
      let currentUserInfo = userInfo;
      if (!currentUserInfo && userId) {
        currentUserInfo = await getUserInfo(userId);
        setUserInfo(currentUserInfo);
      }

      const [courses, userAircraft, userBoughtCourses, properties, userCertificates, trainingPurchaseCandidates] =
        await Promise.all([
          getAllCourses(crewId),
          getUserAircraft(crewId, userId),
          getUserBoughtCourses(userId, crewId),
          getUserProperties(crewId, userId),
          getUserCertificates(userId, crewId),
          getTrainingPurchaseCandidates(userId, crewId),
        ]);

      // Update courses with course highest in the training hierarchy
      const updatedCourses = addCoursesHierarchy(courses, trainingPurchaseCandidates);

      setAircraft(userAircraft);
      setBoughtCourses(userBoughtCourses);
      setProperties(properties);
      setUserCertificates(userCertificates);
      setCourses(updatedCourses);
    })();
  }, [userId, crewId]);

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Route
          path={`${match.path}/:pageId`}
          children={({ match }) => (
            <h2>
              {showBackButton && <NavigationBack />} {headerCaptions[match?.params?.pageId] || ""}
            </h2>
          )}
        />
        {userInfo && <UserTitle id={`${userInfo.id}`} name={userInfo.name} email={userInfo.email} />}
      </Box>
      <Box marginBottom={"1.5rem"}>
        <Route path={`${match.path}/:pageId`} component={UserNavigation} />
      </Box>
      {courses && certificates && properties && aircraft ? (
        <Box style={{ overflow: "auto" }}>
          <CertificateActions.Provider
            value={{
              showHistory(certificate) {
                setHistoryItem(certificate);
              },
              getBoughtCourses() {
                return boughtCourses;
              },
            }}
          >
            <CurrentUserCrewContext.Provider value={{ userId, crewId }}>
              <Switch>
                <Route path={`${match.path}/positions`}>
                  <Positions
                    userId={userId}
                    crewId={crewId}
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                  />
                </Route>
                <Route path={`${match.path}/operations`}>
                  <Operations
                    userId={userId}
                    crewId={crewId}
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                  />
                </Route>
                <Route path={`${match.path}/aircraft`}>
                  <Aircraft certificates={certificates} courses={courses} properties={properties} aircraft={aircraft} />
                </Route>
                <Route path={`${match.path}/specializations`}>
                  <Specializations
                    userId={userId}
                    crewId={crewId}
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                    aircraft={aircraft}
                  />
                </Route>
                <Route path={`${match.path}/all`}>
                  <CrewCourses
                    userId={userId}
                    crewId={crewId}
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                  />
                </Route>
                <Route path={`${match.path}/bundles`}>
                  <BundleCourses
                    crewId={crewId}
                    certificates={certificates}
                    courses={courses}
                    properties={properties}
                  />
                </Route>
              </Switch>
            </CurrentUserCrewContext.Provider>
          </CertificateActions.Provider>
        </Box>
      ) : null}
      {historyItem ? (
        <CourseHistory
          userId={userId}
          certificate={historyItem}
          userCertificates={certificates}
          courses={courses}
          onClose={() => setHistoryItem(null)}
        />
      ) : null}
      {showBackButton && (
        <Button title="Go back" type="button" onClick={() => history.push(`/crew/${crewId}/members`)}>
          Return to members
        </Button>
      )}
    </>
  );
};

export default UserCoursesPage;
