import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getUsersAssessment } from "../../api/training";
import AssignmentIcon from "@mui/icons-material/Assignment";
import useCrew from "../../hooks/useCrew";

const AssessmentTraining = ({ match, history }) => {
  const [usersInscribed, setUsersInscribed] = useState([]);
  const [selectedTrainingId, setTrainingId] = useState(null);

  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [refresh, setRefresh] = useState(1);
  const { crew } = useCrew();

  useEffect(() => {
    (async () => {
      const trainingId = match.params.trainingId;
      const usersInscribed = await getUsersAssessment(trainingId, crew.id);
      setTrainingId(trainingId);
      setUsersInscribed(usersInscribed);
    })();
  }, [refresh]);

  return (
    <>
      <Typography variant="h4">Assessment list users</Typography>
      {usersInscribed ? (
        <TableComponent
          header={[
            { title: "User ID", style: { width: "5%" } },
            { title: "User" },
            { title: "Crew" },
            { title: "Position" },
            // { title: "Operation"},
            { title: "Actions" },
          ]}
          //writtenTestId
          renderChildren={(userInscribed, index) => (
            <TableRow key={index}>
              <TableCell>{userInscribed.id}</TableCell>
              <TableCell>{userInscribed.fullname}</TableCell>
              <TableCell>{userInscribed.companyName}</TableCell>
              <TableCell>{userInscribed.positionName}</TableCell>
              {/* <TableCell>{userInscribed.operationName}</TableCell> */}
              <TableCell>
                {/*               
              {(userInscribed.operationId) ? (
              <Button
                  icon={<AssignmentIcon />}
                  onClick={() => history.push(`/training-users/user/assessment/${userInscribed.id}/${userInscribed.operationId}`)} 
                  title="Take assessment"  
              />   
              ) : null} 
            */}

                {userInscribed.positionId ? (
                  <Button
                    icon={<AssignmentIcon />}
                    onClick={() =>
                      history.push(`/training-users/user/assessment/${userInscribed.id}/${userInscribed.positionId}`)
                    }
                    title="Take assessment"
                  />
                ) : null}
              </TableCell>
            </TableRow>
          )}
          search={["user_id", "fullname", "companyName"]}
          pagination
          items={usersInscribed}
        />
      ) : null}
      <Button title="Back" onClick={() => history.goBack()}>
        Back
      </Button>
    </>
  );
};

export default AssessmentTraining;
