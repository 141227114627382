import React, { useState, useEffect } from "react";

import PostAddIcon from "@mui/icons-material/PostAdd";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";

import Confirm from "../../components/Confirm";
import TransactionItem from "./TransactionItem";

import { getTransactions } from "../../api/orders";

const TransactionsBankTable = ({ match, history }) => {
  const [refresh, setRefresh] = useState(1);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    (async () => {
      let transactions = await getTransactions();
      setTransactions(transactions);
    })();
  }, [refresh]);

  return (
    <>
      <h2>Transactions</h2>
      <TableComponent
        header={[
          { title: "ID", sort: "id", style: { width: "5%" } },
          { title: "Company", sort: "company" },
          { title: "Similarity Company", sort: "similarity_company" },
          { title: "Price", sort: "price", style: { width: "10%" } },

          { title: "Info", sort: "tranaction_info" },
          { title: "Booking Date" },
          { title: "Validity Date" },
          { title: "Reference" },
          { title: "Matched" },
          { title: "Actions" },
        ]}
        renderChildren={(transaction) => <TransactionItem key={transaction.id} transaction={transaction} />}
        items={transactions}
        search={["company", "price", "tranaction_info"]}
        pagination
      />
    </>
  );
};

export default TransactionsBankTable;
