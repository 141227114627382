import React from "react";

import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { StyledSwitch } from "../../components/Base";
import Button from "../../components/Button";

const AircraftRow = ({ onEdit, onDelete, onStatusChange, aircraft }) => {
  return (
    <TableRow>
      <TableCell>{aircraft.id}</TableCell>
      <TableCell>{aircraft.name}</TableCell>
      <TableCell>
        <StyledSwitch
          title="Change aircraft status"
          checked={aircraft.status === "active"}
          onChange={() => onStatusChange(aircraft.id, aircraft.status === "active" ? "passive" : "active")}
        />
      </TableCell>
      <TableCell>
        <Link style={{ marginRight: "1rem" }} onClick={() => onEdit(aircraft.id)}>
          <Button title="Edit Aircraft" icon={<EditIcon />} />
        </Link>
        <Link onClick={() => onDelete(aircraft.id)}>
          <Button title="Delete Aircraft" icon={<DeleteForeverIcon color="error" />} />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default AircraftRow;
