import MuiAutocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const Autocomplete = ({ label, items, selected, onChange, formatLabel, multiple = false }) => (
  <MuiAutocomplete
    size="small"
    options={items || []}
    onChange={(_, v) => onChange(v)}
    value={selected || (multiple ? [] : null)}
    getOptionLabel={formatLabel || ((item) => (item?.name ? `(${item.id}) ${item.name}` : ""))}
    multiple={multiple}
    renderInput={(params) => <TextField {...params} variant="outlined" label={label} />}
  />
);

export default Autocomplete;
