import React from "react";

import Tab from "@mui/material/Tab";

import { Switch, Route } from "react-router";
import PropertiesEdit from "../../features/properties-edit";
import { StyledTabs } from "../../components/Base";

const PropertiesNavigation = ({ match, history }) => {
  const { pageId } = match.params;

  const navigateTo = (_, value) => {
    const subpage = v(value);
    history.push(`/profile/${subpage}`);
  };

  const pages = ["edit", "aircraft_specialization"];

  const rv = (subpage) => pages.indexOf(subpage);
  const v = (i) => pages[i];

  return (
    <StyledTabs variant="fullWidth" value={rv(pageId)} indicatorColor="primary" onChange={navigateTo}>
      <Tab label="Properties" />
      <Tab label="Aircraft and Specialization" />
    </StyledTabs>
  );
};

const Properties = ({ match, history }) => {
  return (
    <>
      <Route path={`${match.path}/:pageId`} component={PropertiesNavigation} />
      <Switch>
        <Route exact path={`${match.path}/edit`} component={PropertiesEdit} />
        {/*
        <Route
          exact
          path={`${match.path}/aircraft_specialization`}
          component={UserCrewsList}
        />*/}
      </Switch>
    </>
  );
};

export default Properties;
