import { useState, useEffect, useMemo } from "react";

import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import Button from "../../components/Button";
import { StyledSwitch } from "../../components/Base";
import "./index.scss";
import { getValidPeriods } from "../../api/validPeriods";
import { getPositionsActive } from "../../api/positions";
import { addCustomCourse, getCustomCourseById, updateCustomCourse } from "../../api/courses";
import { BUFFER_ZONES } from "../../constants";
import IdGenerator from "../../utils/IdGenerator";
import { getCustomPositionValidPeriods } from "../../api/positionValidPeriods";
import { formatBufferZone, formatValidPeriod } from "../../services/formatters";
import { getcourseCategories } from "src/api/courseCategory";

const COURSE_NAME_ERROR_TEXT = "Please enter the name of custom course";
const VALID_PERIOD_ERROR_TEXT = "Please select validity period";
const POSITION_VALID_PERIOD_ERROR_TEXT = "Please, select correct data";

const idGenerator = new IdGenerator();

const CustomCourseAdd = ({ history, match }) => {
  const { courseId, crewId } = match.params;

  const isEdit = useMemo(() => {
    return !!courseId;
  }, [courseId]);

  const [status, setStatus] = useState("active");
  const [isCrewSpecific, setIsCrewSpecific] = useState(false);

  const [courseName, setCourseName] = useState("");
  const [courseNameErrorText, setGroupNameErrorText] = useState(null);

  const [validPeriods, setValidPeriods] = useState([]);
  const [courseCategories, setcourseCategories] = useState([]);
  const [courseCategoryId, setCourseCategoryId] = useState("");

  const [validPeriodId, setValidPeriodId] = useState("");

  const [validPeriodErrorText, setValidPeriodErrorText] = useState(null);
  const [bufferZone, setBufferZone] = useState(BUFFER_ZONES[0]);

  const [positions, setPositions] = useState([]);
  const [positionValidPeriods, setPositionValidPeriods] = useState([]);

  function handleCourseName(value) {
    setCourseName(value);
    if (!value) return setGroupNameErrorText(COURSE_NAME_ERROR_TEXT);
    setGroupNameErrorText(null);
  }

  function handleValidPeriod(value) {
    setValidPeriodId(value);
    if (!value) return setValidPeriodErrorText(VALID_PERIOD_ERROR_TEXT);
    setValidPeriodErrorText(null);
  }

  function addPositionValidPeriod(value, validPeriodId = "") {
    let position = {};
    if (value.id) position = value;

    const positionValidPeriod = {
      id: idGenerator.getGeneratorId(),
      position: position,
      validPeriodId: validPeriodId,
      isValid: null,
    };
    setPositionValidPeriods((oldValue) => [...oldValue, positionValidPeriod]);
  }

  const simplePositions = useMemo(() => {
    return positionValidPeriods.map((positionValidPeriodWrap) => positionValidPeriodWrap.position);
  }, [positionValidPeriods]);

  const filteredPositions = useMemo(() => {
    return positions.filter(
      (item) =>
        simplePositions.findIndex((selectedItem) => {
          if (selectedItem) return selectedItem.id === item.id;
          return false;
        }) === -1,
    );
  }, [positions, simplePositions]);

  function handleSelectPosition(value, id) {
    // Handle removing text in autocomplete
    let position = value;
    if (!value) position = {};

    const index = positionValidPeriods.findIndex((item) => item.id === id);
    setPositionValidPeriods((oldValue) => {
      const newPositionValidPeriods = [...oldValue];
      newPositionValidPeriods[index].position = position;
      return newPositionValidPeriods;
    });
  }
  function handleSelectPositionPeriod(validPeriodId, id) {
    const index = positionValidPeriods.findIndex((item) => item.id === id);
    setPositionValidPeriods((oldValue) => {
      const newPositionValidPeriods = [...oldValue];
      newPositionValidPeriods[index].validPeriodId = validPeriodId;
      return newPositionValidPeriods;
    });
  }
  function deletePositionValidPeriod(id) {
    const index = positionValidPeriods.findIndex((item) => item.id === id);
    setPositionValidPeriods((oldValue) => {
      const newPositionValidPeriods = [...oldValue];
      newPositionValidPeriods.splice(index, 1);
      return newPositionValidPeriods;
    });
  }
  async function addEditCourse() {
    if (!courseName) return handleCourseName(courseName);
    if (!validPeriodId) return handleValidPeriod(validPeriodId);
    let isPositionPeriodsValid = true;
    if (positionValidPeriods.length) {
      positionValidPeriods.forEach((positionValidPeriod, index) => {
        if (Object.keys(positionValidPeriod.position).length === 0 || positionValidPeriod.validPeriodId === "") {
          isPositionPeriodsValid = false;
          setPositionValidPeriods((oldValue) => {
            const newPositionValidPeriods = [...oldValue];
            newPositionValidPeriods[index].isValid = false;
            return newPositionValidPeriods;
          });
        } else {
          setPositionValidPeriods((oldValue) => {
            const newPositionValidPeriods = [...oldValue];
            newPositionValidPeriods[index].isValid = true;
            return newPositionValidPeriods;
          });
        }
      });
    }
    if (!isPositionPeriodsValid) return;
    const newPositionValidPeriods = positionValidPeriods.map((positionValidPeriod) => ({
      positionId: positionValidPeriod.position.id,
      validPeriodId: positionValidPeriod.validPeriodId,
    }));
    const newCustomCourse = {
      name: courseName,
      validPeriodId: validPeriodId,
      status: status,
      bufferZone: isValidPeriodExpire ? bufferZone : null,
      positionValidPeriods: newPositionValidPeriods,
      courseCategoryId: courseCategoryId,
    };
    if (crewId) {
      newCustomCourse.crewId = crewId;
    } else {
      newCustomCourse.isCrewSpecific = Number(isCrewSpecific);
    }
    let result;
    if (isEdit) {
      result = await updateCustomCourse(courseId, newCustomCourse);
    } else {
      result = await addCustomCourse(newCustomCourse);
    }

    if (result.ok) {
      if (crewId) {
        history.push(`/crew/${crewId}/customCourses`);
      } else {
        history.push(`/courses/custom`);
      }
    }
  }

  const isValidPeriodExpire = useMemo(() => {
    return validPeriodId > 1;
  }, [validPeriodId]);

  useEffect(() => {
    (async () => {
      const newValidPeriods = await getValidPeriods();
      setValidPeriods(newValidPeriods);

      const newPositions = await getPositionsActive();
      setPositions(newPositions);

      const allcourseCategories = await getcourseCategories();
      setcourseCategories(allcourseCategories);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (isEdit) {
        const customCourse = await getCustomCourseById(courseId);
        const positionValidPeriods = await getCustomPositionValidPeriods(courseId);
        positionValidPeriods.forEach((positionValidPeriod) => {
          addPositionValidPeriod(positionValidPeriod.position, positionValidPeriod.validPeriod.id);
        });
        setCourseName(customCourse.name);
        setStatus(customCourse.status);
        setIsCrewSpecific(!!customCourse.isCrewSpecific);
        setBufferZone(customCourse.buffer ? customCourse.buffer : BUFFER_ZONES[0]);
        handleValidPeriod(customCourse.validPeriodId);
        if (customCourse.courseCategoryId) setCourseCategoryId(customCourse.courseCategoryId);
      }
    })();
  }, [courseId, isEdit]);

  return (
    <>
      <h2>{isEdit ? `Edit Custom Course` : `Add Custom Course`}</h2>

      <Paper variant="outlined" className="add-custom-course" style={{ padding: "1rem 1.5rem" }}>
        {(isEdit && (positions.length || positionValidPeriods.length)) || !isEdit ? (
          <>
            <TextField
              className="add-field"
              variant="outlined"
              size="small"
              label="Custom Course Full Name*"
              value={courseName}
              onChange={(event) => handleCourseName(event.target.value)}
              error={!!courseNameErrorText}
              helperText={courseNameErrorText}
            />
            <FormControl
              variant="outlined"
              size="small"
              error={!!validPeriodErrorText}
              className="select-course-period"
            >
              <InputLabel id="selectCoursePeriod">Select default validity period*</InputLabel>
              <Select
                labelId="selectCoursePeriod"
                label="Select default validity period*"
                value={validPeriodId}
                onChange={(event) => handleValidPeriod(event.target.value)}
              >
                {validPeriods.map((period) => (
                  <MenuItem key={period.id} value={period.id}>
                    {formatValidPeriod(period.validPeriod)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{validPeriodErrorText}</FormHelperText>
            </FormControl>
            <FormControl variant="outlined" size="small" className="select-course-period">
              <InputLabel id="selectCourseTheme">Select course theme</InputLabel>
              <Select
                labelId="selectCourseTheme"
                label="Select course theme"
                value={courseCategoryId}
                onChange={(event) => setCourseCategoryId(event.target.value)}
              >
                {courseCategories.map((t) => (
                  <MenuItem key={t.id} value={t.id}>
                    {t.courseCategory}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {isValidPeriodExpire ? (
              <FormControl variant="outlined" size="small" className="select-buffer">
                <InputLabel id="selectBufferZone">Select Buffer Zone</InputLabel>
                <Select
                  className="select-buffer-zone"
                  labelId="selectBufferZone"
                  label="Select Buffer Zone"
                  value={bufferZone}
                  onChange={(event) => {
                    setBufferZone(event.target.value);
                  }}
                >
                  {BUFFER_ZONES.map((buffer) => (
                    <MenuItem key={buffer} value={buffer}>
                      {formatBufferZone(buffer)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            <Button onClick={addPositionValidPeriod}>Add Position and Validity period</Button>
            <Box>
              {positionValidPeriods.map((positionValidPeriod, index) => (
                <FormControl
                  key={positionValidPeriod.id}
                  error={positionValidPeriod.isValid === false}
                  className="position-period-row-wrapper"
                >
                  <div className="position-period-row">
                    <Autocomplete
                      className="select-position"
                      size="small"
                      options={filteredPositions}
                      getOptionLabel={(position) => (position.name ? position.name : "")}
                      filterSelectedOptions
                      value={positionValidPeriod.position}
                      onChange={(_, newPosition) => handleSelectPosition(newPosition, positionValidPeriod.id)}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Select position*" size="small" />
                      )}
                    />
                    <FormControl className="select-period" variant="outlined" size="small">
                      <InputLabel id={"selectPositionPeriod" + positionValidPeriod.id}>
                        Select validity period
                      </InputLabel>
                      <Select
                        labelId={"selectCoursePeriod" + positionValidPeriod.id}
                        label="Select validity period*"
                        value={positionValidPeriod.validPeriodId}
                        onChange={(event) => {
                          handleSelectPositionPeriod(event.target.value, positionValidPeriod.id);
                        }}
                      >
                        {validPeriods.map((period) => (
                          <MenuItem key={period.id} value={period.id}>
                            {formatValidPeriod(period.validPeriod)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      title="Delete position validity period"
                      icon={<ClearIcon />}
                      onClick={() => deletePositionValidPeriod(positionValidPeriod.id)}
                    />
                  </div>
                  <FormHelperText>
                    {positionValidPeriod.isValid === false ? POSITION_VALID_PERIOD_ERROR_TEXT : null}
                  </FormHelperText>
                </FormControl>
              ))}
            </Box>
            <div className="change-status">
              <label>Status</label>
              <StyledSwitch
                title="Change custom course status"
                checked={status === "active"}
                onChange={() => setStatus(status === "active" ? "passive" : "active")}
              />
            </div>
            {!crewId ? (
              <div className="change-status">
                <label>Company specific</label>
                <StyledSwitch
                  title="Change company specific status"
                  checked={isCrewSpecific}
                  onChange={() => setIsCrewSpecific(!isCrewSpecific)}
                />
              </div>
            ) : null}
            <div>
              <Button title="Edit Course" onClick={addEditCourse}>
                {isEdit ? `Update` : `Save`}
              </Button>
              <Button title="Go back" onClick={() => history.goBack()}>
                Cancel
              </Button>
            </div>
          </>
        ) : null}
      </Paper>
    </>
  );
};

export default CustomCourseAdd;
