import React, { useContext, useEffect, useRef, useState } from "react";
import { groupBy, o } from "ramda";
import { keyBy } from "lodash";

import Typography from "@mui/material/Typography";
import RemoveIcon from "@mui/icons-material/HighlightOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { InputAdornment, Paper } from "@mui/material";

import { getCrewOverview } from "../../api/crew";

import PositionOverviewTable from "../../components/PositionOverviewTable";
import SearchField from "../../components/TableComponent/SearchField";
import { formatLastFirstName } from "../../services/formatters";
import Button from "../../components/Button";

import "./index.scss";
import DisplayContext from "../../context/DisplayContext";
import { getUserCertificates, getUserProperties } from "../../api/user";
import { getAllCourses } from "../../api/courses";
import OperationsService from "../operations/service";
import html2canvas from "html2canvas";

function takeScreenshot(fullscreen) {
  const param = {
    allowTaint: false,
    useCORS: true,
    scale: 5,
  };
  let myDiv = "myDiv";
  if (fullscreen) {
    myDiv = "main";
  }
  const input = document.getElementById(myDiv);
  html2canvas(input, param).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = "screenshot.png";
    link.href = imgData;
    document.body.appendChild(link);
    link.click();
  });
}

const collectMyCourses = (operations, user, courses, properties, certificates) => {
  const operationsList = OperationsService.getOperations(
    properties,
    courses.courses,
    courses.customCourses,
    courses.groups,
    courses.relations,
  );
  const opInfos = keyBy(OperationsService.toDisplayObject(operationsList, certificates, courses.relations), "id");

  const res = [user.name];
  for (const op of operations) {
    const opInfo = opInfos[`${op.category_id}-${op.position_id}-${op.operation_id}`];
    if (opInfo) {
      res.push(opInfo.status);
    } else {
      res.push("");
    }
  }
  return res;
};

const hasOperations = (user) => !!user.assignedRoles.find((u) => !!u.operation_id);

// position_name consists of a function (e.g. pilot)
// and something like "high-level operation" / "operations group" (e.g. CAT)
const functions = [
  ["pilot"],
  ["task specialist operator", "TSOP"],
  ["flight dispatch"],
  ["flight instructor", "FI"],
  ["flight examiner", "FE"],
  ["type rating examiner", "TRE"],
  ["type rating instructor", "TRI"],
  ["ground instructor", "GI"],
].map(([f, short]) => [new RegExp(`\\b(?:${f}${short ? "|" + short : ""})\\b`, "i"), f, short]);

const getPosFuncPrefix = (pos) => {
  for (const [re, f, short] of functions) {
    if (re.test(pos)) {
      // prefix with 0 so these get shown first
      // then add short if exists, or long form, for proper sorting by function
      // then add the full position name for further SORTING beyond / ignoring function
      return `0${short || f}${pos}`;
    }
  }
  return pos;
};

const PositionOverview = (params) => {
  const crewId = params.match.params.crewId;
  const [positions, setPositions] = useState([]);
  const [users, setUsers] = useState([]);
  const [noData, setNoData] = useState(false);
  const [search, setSearch] = useState("");
  const [searchOp, setSearchOp] = useState("");
  const searchRef = useRef();
  const { fullscreen, toggleFullscreen } = useContext(DisplayContext);

  useEffect(() => {
    if (!crewId) return;
    (async () => {
      const [overview, courses, properties, certificates] = await Promise.all([
        getCrewOverview(crewId),
        getAllCourses(crewId),
        getUserProperties(crewId),
        getUserCertificates(null, crewId),
      ]);

      if (overview) {
        const positions = Object.values(
          groupBy(
            (x) => x.position_id,
            overview.positions
              .filter((p) => !!p.operation_id)
              .sort((a, b) => a?.operation_name.localeCompare(b?.operation_name)),
          ),
        ).sort(([a], [b]) => getPosFuncPrefix(a?.position_name).localeCompare(getPosFuncPrefix(b?.position_name)));
        const operations = positions.reduce((acc, pos) => [...acc, ...pos], []);
        const users = overview.users
          .map((user) => {
            if (!hasOperations(user)) {
              return null;
            }
            const collected = collectMyCourses(
              operations,
              user,
              courses,
              properties[user.user_id],
              certificates[user.user_id],
            );
            return {
              positions: collected,
              id: user.user_id,
            };
          })
          .filter((x) => !!x)
          .sort(({ positions: [name1] }, { positions: [name2] }) =>
            formatLastFirstName(name1).localeCompare(formatLastFirstName(name2)),
          );

        setPositions(positions);
        setUsers(users);
        setNoData(!positions.length);
      }
    })();
  }, [crewId]);

  return (
    <>
      <Paper className={"tools left"} elevation={5}>
        <Button
          icon={fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          onClick={() => toggleFullscreen()}
          iconSize={"large"}
          title={"Toggle fullscreen view"}
        />
        <Button
          icon={fullscreen ? <AddAPhotoIcon /> : <CameraAltIcon />}
          onClick={() => takeScreenshot(fullscreen)}
          iconSize={"large"}
          title={"Take screenshot"}
        />
      </Paper>
      <Paper className={"tools right"} elevation={5}>
        <SearchField
          placeholder="Filter users"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button icon={<RemoveIcon />} onClick={() => setSearch("")} title={"Clear filter"} />
              </InputAdornment>
            ),
          }}
        />
        <SearchField
          placeholder="Filter operations"
          value={searchOp}
          onChange={(e) => setSearchOp(e.target.value)}
          InputProps={{
            ref: searchRef,
            endAdornment: (
              <InputAdornment position="end">
                <Button icon={<RemoveIcon />} onClick={() => setSearchOp("")} title={"Clear filter"} />
              </InputAdornment>
            ),
          }}
        />
      </Paper>
      <div id="myDiv">
        {noData ? <Typography variant="h4">No Data Found</Typography> : null}
        {positions?.length && users?.length ? (
          <PositionOverviewTable
            crewId={crewId}
            positions={positions}
            users={users.filter(
              ({ positions: [name] }) => !search || name.toLowerCase().includes(search.toLowerCase()),
            )}
            onUserSelected={(name) => (search === name ? setSearch("") : setSearch(name))}
            onOperationSelected={(it) => setSearchOp(searchOp === it ? "" : it)}
            onPositionSelected={(it) => setSearchOp(searchOp === it ? "" : it)}
            searchOp={searchOp}
            searchRef={searchRef}
          />
        ) : null}
      </div>
    </>
  );
};

export default PositionOverview;
