import React from "react";

import FormContext from "./FormContext";

class Form extends React.Component {
  constructor(props) {
    super(props);

    const fields = {};
    if (props.defaultValues) {
      for (const [key, value] of Object.entries(props.defaultValues)) {
        fields[key] = { value };
      }
    }

    this.state = {
      fields,
      set: this.set.bind(this),
      get: this.get.bind(this),
    };

    if (props.unsafe_valueSetter) {
      props.unsafe_valueSetter((field, value) => {
        this.set(field, value);
      });
    }
  }

  set(name, value) {
    let error = "";
    try {
      error = this.props.validation[name](value);
    } catch (e) {}
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [name]: {
          value,
          error,
        },
      },
    });
  }

  get(name) {
    return this.state.fields[name] || {};
  }

  _setError(name, error) {
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [name]: {
          ...this.state.fields[name],
          error,
        },
      },
    });
  }

  hasErrors() {
    let hasError = false;
    for (const [name, validationFn] of Object.entries(this.props.validation)) {
      const error = validationFn(this.state.fields[name]?.value);
      if (error) {
        this._setError(name, error);
        hasError = true;
        break;
      }
    }
    return hasError;
  }

  async submit() {
    if (!this.hasErrors()) {
      const errors = await this.props?.onSubmit(this.values());
      if (errors) {
        for (const [field, error] of Object.entries(errors)) {
          this._setError(field, error);
        }
      }
    }
  }

  values() {
    const ret = {};
    for (const [name, value] of Object.entries(this.state.fields)) {
      ret[name] = value.value;
    }
    return ret;
  }

  render() {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          try {
            this.submit(e);
          } catch (e) {
            console.log(e);
          }
          return false;
        }}
      >
        <FormContext.Provider value={this.state}>{this.props.children}</FormContext.Provider>
      </form>
    );
  }
}

Form.defaultProps = {
  validation: {},
};

Form.contextType = FormContext;

export default Form;
