import { getOrderStatus, createOrder, createPayWithInvoice, deleteOrder, updateOrder } from "../api/payment";

const SHOPPING_CART_ORDER = "ngft-shopping-cart-order";

const ShoppingCart = {
  _set(crewId, order) {
    try {
      const stored = JSON.parse(localStorage.getItem(SHOPPING_CART_ORDER)) || {};
      stored[crewId] = order;
      localStorage.setItem(SHOPPING_CART_ORDER, JSON.stringify(stored));
    } catch (e) {}
  },
  _get(crewId) {
    try {
      const stored = JSON.parse(localStorage.getItem(SHOPPING_CART_ORDER));
      return (stored || {})[crewId];
    } catch (e) {}
    return null;
  },
  getCrewItems(crewId) {
    const order = this._get(crewId);
    return order?.items || [];
  },
  async updateOrderStatus(crewId) {
    const order = this._get(crewId);
    if (order?.orderId) {
      const status = await getOrderStatus(order.orderId);
      if (status.status === "paid") {
        this._set(crewId, null);
      }
    }
  },
  async clear(crewId) {
    /*const order = this._get(crewId);
    if (order?.orderId) {
      await deleteOrder(order.orderId);
    }*/
    this._set(crewId, null);
  },
  async add(crewId, item) {
    const order = this._get(crewId) || { items: [] };
    if (!order.items.find((i) => i.courseId === item.courseId && i.userId === item.userId)) {
      order.items.push(item);
      this._set(crewId, order);
    }
    if (order?.orderId) {
      await updateOrder(order.orderId, order.items);
    }
  },
  async removeItem(crewId, item) {
    const order = this._get(crewId);
    if (order) {
      order.items = order.items.filter((i) => !(i.courseId === item.courseId && i.userId === item.userId));
      this._set(crewId, order);
    }
    if (order?.orderId) {
      await updateOrder(order.orderId, order.items);
    }
  },
  setOrderId(crewId, orderId) {
    const order = this._get(crewId);
    this._set(crewId, { ...order, orderId });
  },
  async checkout(crewId, billingId) {
    /*const order = this._get(crewId);
    if (order.orderId) {
      await updateOrder(order.orderId, order.items);
      const status = await getOrderStatus(order.orderId);
      return status;
    } else {
      const items = this.getCrewItems(crewId);
      const newOrder = await createOrder(items);
      this.setOrderId(crewId, newOrder?.orderId);
      return newOrder;
    }*/
    const items = this.getCrewItems(crewId);
    const newOrder = await createOrder(items, billingId);
    return newOrder;
  },
  async payWithInvoice(crewId, billingId, shoppingItems) {
    const items = this.getCrewItems(crewId);
    const newPayWithInvoice = await createPayWithInvoice(items, billingId, shoppingItems, "processing");
    return newPayWithInvoice;
  },
  async payWithInvoiceQR(crewId, billingId, shoppingItems) {
    const items = this.getCrewItems(crewId);
    const newPayWithInvoice = await createPayWithInvoice(items, billingId, shoppingItems, "processing", "QR");
    return newPayWithInvoice;
  },
};

export default ShoppingCart;
