import React from "react";
import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "../../components/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import request from "../../api/request";
import QrCode2Icon from "@mui/icons-material/QrCode2";

export async function getSSID() {
  const ssid = await request(`/sts`);
  return ssid;
}

const downloadOrder = async (orderId) => {
  const ssid = await getSSID();
  window.open(`${process.env.REACT_APP_API_URL}/files/download/invoice/${orderId}?ssid=${ssid}`, "_blank");
};

const downloadQRCode = async (filename) => {
  const ssid = await getSSID();
  const qrCodeFilename = filename.replace("Invoice_NGFT_", "Invoice_NGFT_QR_");
  window.open(`${process.env.REACT_APP_API_URL}/files/download/invoice/${qrCodeFilename}?ssid=${ssid}`, "_blank");
};

function formatDate(string) {
  return new Date(string).toLocaleString("de-CH");
}

const OrderItem = ({ order }) => {
  return (
    <TableRow key={order.order_id}>
      <TableCell>{order.order_id}</TableCell>
      <TableCell>{order.buyer}</TableCell>
      <TableCell>{formatDate(order.created_at)}</TableCell>
      <TableCell>
        {order.items.map((d, index) => (
          <li key={`${order.order_id}-${index}`}>
            {d.course_name} - {d.user_name}
          </li>
        ))}
      </TableCell>
      <TableCell>{order.payment_type}</TableCell>
      <TableCell>{order.total}</TableCell>
      <TableCell>{order.status}</TableCell>
      <TableCell>
        <Button title="Download Invoice" icon={<GetAppIcon />} onClick={() => downloadOrder(order.invoice_name)} />
        <Button title="Download QR code" icon={<QrCode2Icon />} onClick={() => downloadQRCode(order.invoice_name)} />
      </TableCell>
    </TableRow>
  );
};

export default OrderItem;
