import React from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import Icon from "@mui/icons-material/SubdirectoryArrowRight";

import Actions from "./Actions";
import CertificateStatus from "./CertificateStatus";
import CourseStatusIcon from "../CourseStatusIcon";

const Certificate = ({ certificate, variant }) => {
  const style = {};
  let iconWidth = "2%";

  if (variant === "borderless") {
    style.border = 0;
    iconWidth = "8%";
  }
  return (
    <TableRow style={style}>
      <TableCell style={{ ...style, width: iconWidth }}>
        {variant === "borderless" ? <Icon /> : null}
        <CourseStatusIcon status={certificate.status} />
      </TableCell>
      <TableCell style={style}>
        ({certificate.id}) {certificate.name}
      </TableCell>
      <TableCell style={style}>
        {certificate.validPeriod && !isNaN(certificate.validPeriod) ? `${certificate.validPeriod} months` : ""}
      </TableCell>
      <TableCell style={{ ...style, width: "15%" }}>
        <CertificateStatus validUntil={certificate.validUntil} status={certificate.status} />
      </TableCell>
      <TableCell style={{ ...style, width: "12%" }} align={"justify"}>
        {certificate.dependence ? (
          `Depends on  (${certificate.dependenceId}) ${certificate.dependenceName}`
        ) : (
          <Actions certificate={certificate} />
        )}
      </TableCell>
    </TableRow>
  );
};
export default Certificate;
