import { useTranslation } from "react-i18next";

import { Box, FormControl } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import Button from "../../components/Button";
import { Autocomplete, Select } from "../../components/Form";
import { SelectableObject, SelectableWithIdObject } from "../../components/Form/types";
import { PositionValidPeriod } from "src/api/positionValidPeriods";

interface PositionPeriodRowProps {
  item: PositionValidPeriod;
  onChange: (updatedItem: PositionValidPeriod) => void;
  onRemove: () => void;
  allPositions: SelectableObject[];
  allPeriods: SelectableWithIdObject[];
}

const PositionPeriodRow = ({ item, onChange, onRemove, allPositions, allPeriods }: PositionPeriodRowProps) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2),
        })}
      >
        <Autocomplete
          sx={{ width: "100%" }}
          label={`${t("course.select_position")}*`}
          items={allPositions}
          selected={item.position}
          required
          onChange={(updatedItem) => onChange(new PositionValidPeriod({ ...item, position: updatedItem }))}
        />
        <Select
          sx={{ width: "100%" }}
          label={`${t("course.select_period")}*`}
          items={allPeriods}
          value={item.validPeriod || ""}
          required
          onChange={(value) =>
            onChange(
              new PositionValidPeriod({
                ...item,
                validPeriod: value,
              }),
            )
          }
        />
        <Button title={t("course.remove_position_period")} icon={<ClearIcon />} onClick={onRemove} />
      </Box>
    </FormControl>
  );
};

export default PositionPeriodRow;
