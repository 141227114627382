import React, { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TrainingModuleStatus from "../../components/TrainingModuleStatus";
import Table from "@mui/material/Table";
import { convertDateTime } from "../../services/formatters";

const UserScheduledSlots = ({ scheduleSlots }) => {
  return (
    <>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "25%" }}>Event Type</TableCell>
            <TableCell style={{ width: "25%" }}>Location</TableCell>
            <TableCell style={{ width: "25%" }}>Start Date</TableCell>
            <TableCell style={{ width: "25%" }}>End Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scheduleSlots.map((row) => (
            <TableRow key={row.id}>
              <TableCell> {row.event_type.charAt(0).toUpperCase() + row.event_type.slice(1)}</TableCell>
              <TableCell> {row.location}</TableCell>
              <TableCell> {row.start_date_text}</TableCell>
              <TableCell> {row.end_date_text}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default UserScheduledSlots;
