import { mapValues } from "lodash";

import request from "./request";
import CertificateService from "../services/certificate";

export const getUserCourses = async (crewId, userId) => {
  const res = await request(`/crew/${crewId}/user/${userId}/operations`);
  return res;
};

export const getShortUserInfo = async (userId) => {
  const res = await request(`/user/${userId}/short`);
  return res;
};

/* Retrieve and validate user certificates */
export const getUserCertificates = async (userId = null, crewId = null, fulldescription = false) => {
  let url = "";
  const query = [];
  if (userId) {
    url = `/user/${userId}/certificates`;
  } else {
    url = `/user/certificates/all`;
  }
  if (crewId) {
    query.push(`crewId=${crewId}`);
  }
  if (fulldescription) {
    query.push(`description=true`);
  }
  if (query.length) {
    url += "?" + query.join("&");
  }

  const json = await request(url);
  if (userId) {
    return CertificateService.map(json);
  }
  return mapValues(json, CertificateService.map);
};

export const getUserProperties = async (crewId, userId = null) => {
  let url;
  if (userId) {
    url = `/user/${userId}/properties/${crewId}`;
  } else {
    url = `/user/properties/${crewId}/all`;
  }
  return await request(url);
};

export const addUserProperty = async (crewId, userId, property) => {
  let url = "";
  if (userId) {
    url = `/user/${userId}/properties/${crewId}`;
  } else {
    url = `/user/properties/${crewId}`;
  }

  const json = await request.post(url, property);
  return json;
};

export const removeUserProperty = async (crewId, userId, property) => {
  let url = "";
  if (userId) {
    url = `/user/${userId}/properties/${crewId}`;
  } else {
    url = `/user/properties/${crewId}`;
  }
  url += "?";
  if (property.categoryId) {
    url += `categoryId=${property.categoryId}`;
  }
  if (property.positionId) {
    url += `&positionId=${property.positionId}`;
  }
  if (property.operationId) {
    url += `&operationId=${property.operationId}`;
  }

  const response = await request.delete(url);
  return response;
};

export const addAircraftSpecialization = async (data) => {
  const json = await request(`user/aircraft`, "POST", data);
  return json;
};

export const deleteAircraftSpecialization = async (data) => {
  const json = await request(`user/aircraft`, "DELETE", data);
  return json;
};

export const getUserAircraft = async (crewId, userId = null) => {
  let url = "";
  if (userId) {
    url = `/user/${userId}/aircraft/${crewId}`;
  } else {
    url = `/user/aircraft/${crewId}`;
  }
  const json = await request(url);
  return json;
};

export const getCrewHideAdminCourses = async (crewId) => {
  const json = await request(`/crew/${crewId}/hideAdminCourses`);
  return json;
};

export const getUsers = async () => {
  const json = await request(`/user`);
  return json;
};

export const getTrainersOrAssessmentOrChecker = async () => {
  const json = await request(`/user/trainers/`);
  return json;
};

export const getUserInfo = async (userId) => {
  const json = await request(`/user/${userId}`);
  return json;
};

export const editUser = async (userId, userData) => {
  const json = await request(`/user/${userId}`, "PUT", userData);
  return json;
};

export const getMyInfo = async () => {
  const json = await request(`/user/me`);
  return json;
};

export const createUser = async (values) => {
  const json = await request.post(`/user`, values);

  if (!json.ok) {
    return json.error;
  }

  return null;
};

export const checkExistUser = async (firstname, lastname, birthday) => {
  const json = await request(`/user/check/${firstname}/${lastname}/${birthday}`);
  //const json = { ok: true, error: null}
  return json;
};

export const getUserRoles = async (userId) => {
  const json = await request(`/user/${userId}/roles`);
  return json;
};

export const sendInviteEmail = async (crewId, email, roles) => {
  const json = await request.post(`/invite/${crewId}`, { email, roles });
  return json;
};

export const checkEmailExist = async (email) => {
  const resp = await request(`/user/check/${email}`);
  return resp;
};

export const changeUserStatus = async (userId, status) => {
  const json = await request.put(`/user/${userId}/status`, { status });
  return json;
};

export const changeUserInvoice = async (userId, invoice) => {
  const json = await request.put(`/user/${userId}/invoice`, { invoice });
  return json;
};

export const getUserBillingAddresses = async () => {
  const addresses = await request(`/user/me/addresses`);
  return addresses;
};

export const getBillingAddress = async (id) => {
  const address = await request(`/user/me/addresses/${id}`);
  return address;
};

export const updateBillingAddress = async (id, address) => {
  const result = await request.put(`/user/me/addresses/${id}`, address);
  return result;
};

export const createBillingAddress = async (address) => {
  const result = await request.post(`/user/me/addresses`, address);
  return result;
};

export const deleteBillingAddress = async (id) => {
  const result = await request.delete(`/user/me/addresses/${id}`, id);
  return result;
};

export const updateEnableCloud = async (userId, crewId, enableCloud) => {
  const result = await request.put(`/user/enablecloud/${userId}/${crewId}`, {
    enableCloud,
  });
  return result;
};
