import React, { useState, useEffect } from "react";

import PostAddIcon from "@mui/icons-material/PostAdd";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";

import Confirm from "../../components/Confirm";
import CustomCourseItem from "./CustomCourseItem";

import { getCustomCourses, updateCustomCourse, deleteCustomCourseById, getCompanyCourses } from "../../api/courses";

const CustomCoursesTable = ({ match, history }) => {
  const { crewId } = match.params;
  const [courses, setCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id) {
    setSelectedCourseId(id);
    setIsDeleteModalShow(true);
  }

  function onEdit(id) {
    history.push(`${match.url}/edit/${id}`);
  }
  async function onStatusChange(id, newStatus) {
    await updateCustomCourse(id, { status: newStatus });

    setRefresh(refresh * -1);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteCustomCourseById(selectedCourseId);
    }
    setIsDeleteModalShow(false);

    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      if (crewId) {
        const companyCourses = await getCompanyCourses(crewId);
        setCourses(companyCourses);
      } else {
        const newCourses = await getCustomCourses();
        setCourses(newCourses);
      }
    })();
  }, [crewId, refresh]);

  return (
    <>
      <h2>Custom Courses Table</h2>
      <TableComponent
        TopComponent={
          <Button size="small" onClick={() => history.replace(`${match.url}/add`)}>
            <PostAddIcon />
            Add new course
          </Button>
        }
        header={[
          { title: "ID", sort: "id", style: { width: "5%" } },
          {
            title: "Course Full Name",
            sort: "displayName",
            style: { width: "50%" },
          },
          { title: "Default validity period", sort: "validPeriod" },
          { title: "Theme", sort: "courseCategory" },
          { title: "Status", sort: "status" },
          { title: "Actions" },
        ]}
        renderChildren={(course) => (
          <CustomCourseItem
            key={course.id}
            onEdit={onEdit}
            onDelete={showDeleteModal}
            onStatusChange={onStatusChange}
            course={course}
          />
        )}
        items={courses}
        search={["name", "id"]}
        pagination
      />
      {isDeleteModalShow && selectedCourseId ? (
        <Confirm title={"Delete Course"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the custom course?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default CustomCoursesTable;
