import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Button from "../../../components/Button";
import TableComponent from "../../../components/TableComponent";
import Confirm from "../../../components/Confirm";
import { getTrainings, deleteTrainingCourseById } from "../../../api/training";

import { convertDateTime } from "../../../services/formatters";

const CourseTraining = ({ match, history }) => {
  const [trainings, setTrainings] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id, courseId) {
    setSelectedId(id);
    setSelectedCourseId(courseId);
    setIsDeleteModalShow(true);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteTrainingCourseById(selectedId, selectedCourseId);
    }
    setIsDeleteModalShow(false);
    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      const res = await getTrainings();

      setTrainings(res);
    })();
  }, [refresh]);

  return (
    <>
      <Typography variant="h4">Course Trainings</Typography>
      {trainings ? (
        <TableComponent
          TopComponent={
            <Button size="small" onClick={() => history.push(`/trainings/add`)}>
              <PostAddIcon />
              Add New Training
            </Button>
          }
          search={["displayname", "id"]}
          header={[
            { title: "ID", style: { width: "5%" } },
            { title: "course ID", style: { width: "5%" } },
            { title: "Name", style: { width: "10%" } },
            { title: "Position", style: { width: "10%" } },
            { title: "Status", style: { width: "10%" } },
            { title: "E-learning", style: { width: "5%" } },
            { title: "Classroom", style: { width: "5%" } },
            { title: "Practical", style: { width: "5%" } },
            { title: "Start Date", style: { width: "10%" } },
            { title: "End Date", style: { width: "10%" } },
            { title: "Slots", style: { width: "5%" } },
            //{ title: "Inscribe", style: { width: "5%" } },
            { title: "Actions", style: { width: "10%" } },
          ]}
          items={trainings}
          renderChildren={(training) => (
            <TableRow key={training.id}>
              <TableCell>{training.id}</TableCell>
              <TableCell>{training.courseId}</TableCell>
              <TableCell>{training.displayname}</TableCell>
              <TableCell>{training.positions}</TableCell>
              <TableCell>{training.status}</TableCell>
              <TableCell>{training.isELearning === 1 ? "Yes" : "No"}</TableCell>
              <TableCell>{training.isClassroom === 1 ? "Yes" : "No"}</TableCell>
              <TableCell>{training.isPractical === 1 ? "Yes" : "No"}</TableCell>
              <TableCell>{convertDateTime(training.trainingStartDate)}</TableCell>
              <TableCell>{convertDateTime(training.trainingEndDate)}</TableCell>
              <TableCell>{training.trainingSlot}</TableCell>
              {/*   <TableCell>{training.userInscribe}</TableCell> */}

              <TableCell>
                <Button
                  icon={<EditIcon />}
                  onClick={() => history.push(`/trainings/edit/${training.id}`)}
                  title="Edit training"
                />
                <Button
                  icon={<AddCircleIcon />}
                  onClick={() => history.push(`/trainings/inscribe/${training.id}`)}
                  title="Inscribe users"
                />
                {/*                 
                <Button
                  icon={<MoreTimeIcon />}
                  onClick={() => history.push(`/trainings/schedule/trainer/${training.id}`)}
                  title="Schedule training"
                />
                */}
                {training.isPractical == 1 && (
                  <Button
                    icon={<DateRangeIcon />}
                    onClick={() => history.push(`/trainings/schedule/practical/${training.id}`)}
                    title="Schedule practical"
                  />
                )}

                <Button
                  icon={<DeleteForeverIcon />}
                  onClick={() => showDeleteModal(training.id, training.courseId)}
                  title="Delete training"
                />
              </TableCell>
            </TableRow>
          )}
        />
      ) : null}

      {isDeleteModalShow && selectedId ? (
        <Confirm title={"Delete Operation"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the operation?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default CourseTraining;
