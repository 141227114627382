import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";

import TextField from "@mui/material/TextField";
import Button from "../../components/Button";
import { updateSpecialization, getSpecializationById } from "../../api/specializations";

import "./index.scss";

const SPECIALIZATION_ERROR_TEXT = "Please enter the name of specialization";

const SpecializationsEdit = ({ match, history }) => {
  const [newSpecializationName, setNewSpecializationName] = useState("");
  const [isSpecializationFetching, setIsSpecializationFetching] = useState(false);

  useEffect(() => {
    (async () => {
      setIsSpecializationFetching(true);
      const specialization = await getSpecializationById(match.params.specializationId);
      setNewSpecializationName(specialization.name);
      setIsSpecializationFetching(false);
    })();
  }, [match.params.specializationId]);

  async function editSpecializationName() {
    if (!newSpecializationName) return;
    const specializationId = match.params.specializationId;

    const result = await updateSpecialization(specializationId, {
      name: newSpecializationName,
    });
    if (result.ok) {
      history.push("/specializations");
    }
  }

  return (
    <>
      <h2>Edit specialization</h2>
      <Paper variant="outlined" style={{ padding: "1rem 1.5rem" }}>
        {!isSpecializationFetching ? (
          <>
            <TextField
              className="edit-field"
              variant="outlined"
              size="small"
              label="Specialization name*"
              value={newSpecializationName}
              onChange={(event) => setNewSpecializationName(event.target.value)}
              error={!newSpecializationName}
              helperText={newSpecializationName ? null : SPECIALIZATION_ERROR_TEXT}
            />
            <Button title="Save specialization" onClick={editSpecializationName}>
              Save
            </Button>
            <Button title="Go back" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </>
        ) : null}
      </Paper>
    </>
  );
};
export default SpecializationsEdit;
