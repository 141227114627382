import React, { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "../../components/Button";
import { getNgftApp } from "../../api/ngftApp.js";
import { StyledSwitch } from "../../components/Base";
import { updateNews } from "../../api/news";

const NewsItem = ({ onEdit, onDelete, news }) => {
  const [source, setSource] = useState([]);
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    (async () => {
      let newSources = await getNgftApp();
      setSource(newSources);
    })();
  }, [refresh]);

  function getAppName(id) {
    if (source.length > 0) {
      let name = source.filter((app) => app.id == id)[0]["name"];
      return name;
    }
  }

  const onStatusChange = (news, published) => {
    news.published = published;
    const result = updateNews(news.id, {
      source: news.source,
      title: news.title,
      description: news.description,
      ext_link: news.ext_link,
      published: news.published,
    });
    setRefresh(refresh * -1);
  };

  return (
    <TableRow>
      <TableCell>{news.id}</TableCell>
      <TableCell>{getAppName(news.source)}</TableCell>
      <TableCell>{news.title}</TableCell>
      <TableCell>{news.description}</TableCell>
      <TableCell>{news.link}</TableCell>
      <TableCell>
        <StyledSwitch
          title="Change published newsapps"
          checked={news.published === "Yes"}
          onChange={() => onStatusChange(news, news.published === "Yes" ? "No" : "Yes")}
        />
      </TableCell>
      <TableCell>
        <Link style={{ marginRight: "1rem" }} onClick={() => onEdit(news.id)}>
          <Button title="Edit news" icon={<EditIcon />} />
        </Link>
        <Link onClick={() => onDelete(news.id)}>
          <Button title="Delete news" icon={<DeleteForeverIcon color="error" />} />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default NewsItem;
