import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { WithPermission, hasPermission } from "../../services/permissions";

import UserTitle from "../../components/UserTitle";
import { getUserInfo } from "../../api/user";
import { StyledTabs } from "../../components/Base";

const MemberEditNavigation = ({ match, history }) => {
  const { crewId, userId, pageId } = match.params;

  const [user, setUser] = useState();
  useEffect(() => {
    (async () => {
      const userInfo = await getUserInfo(userId);
      setUser(userInfo);
    })();
  }, [userId]);
  const navigateTo = (_, value) => {
    const subpage = v(value);
    history.push(`/crew/${crewId}/users/${userId}/edit/${subpage}`);
  };

  const pages = ["user"];
  const titles = ["Edit Member"];

  if (hasPermission("advanced_user_management")) {
    pages.push("roles");
    titles.push("Assign Role");
  }

  const rv = (subpage) => pages.indexOf(subpage);
  const v = (i) => pages[i];
  return (
    <>
      {user ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{titles[rv(pageId)]}</Typography>
          <UserTitle id={`${user.id}`} name={user.name} email={user.email} />
        </Box>
      ) : null}

      <StyledTabs variant="fullWidth" value={rv(pageId)} indicatorColor="primary" onChange={navigateTo}>
        <Tab label="Edit" />

        <Tab label="Assign Role" onChange={() => navigateTo(null, pages.indexOf("roles"))} />
      </StyledTabs>
    </>
  );
};

export default MemberEditNavigation;
