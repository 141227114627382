import React, { useState, useEffect } from "react";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BuildIcon from "@mui/icons-material/Build";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FlightIcon from "@mui/icons-material/Flight";
import SchoolIcon from "@mui/icons-material/School";
import SpeedIcon from "@mui/icons-material/Speed";
import WorkIcon from "@mui/icons-material/Work";
import { updateEnableCloud } from "../../api/user";
import CircularProgress from "@mui/material/CircularProgress";
import { Text, StyledSwitch } from "../Base";
import Button from "../Button";

import { useCrewMembersActions } from "../../context/CrewMembersActionsContext";

const UserActions = ({ userId }) => {
  const actions = useCrewMembersActions();

  return (
    <>
      <Button title={"Edit user name and properties"} icon={<EditIcon />} onClick={() => actions.edit(userId)} />
      {actions.canAssignRoles() ? (
        <Button
          title={"Assign member role in company"}
          icon={<AccountCircleIcon />}
          onClick={() => actions.roles(userId)}
        />
      ) : null}
      <Button
        title={"Remove user from company"}
        icon={<DeleteIcon color="error" />}
        onClick={() => actions.remove(userId)}
      />
    </>
  );
};

const UserProperties = ({ userId }) => {
  const actions = useCrewMembersActions();

  return (
    <>
      <Button title={"User positions"} icon={<WorkIcon />} onClick={() => actions.positions(userId)} />
      <Button title={"User operations"} icon={<BuildIcon />} onClick={() => actions.operations(userId)} />
      <Button title={"User aircraft"} icon={<FlightIcon />} onClick={() => actions.aircraft(userId)} />
      <Button title={"User specializations"} icon={<SpeedIcon />} onClick={() => actions.specializations(userId)} />
      <Button title={"All Certificates"} icon={<SchoolIcon />} onClick={() => actions.allCertificates(userId)} />
    </>
  );
};

export const UserRow = ({ user, crewId, crewInfo }) => {
  const actions = useCrewMembersActions();
  const [selectEnableCloud, setSelectEnableCloud] = useState(user.enableCloud);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = React.useState(false);

  return (
    <>
      <TableRow key={user.id}>
        <TableCell>{user.id}</TableCell>
        {/* <TableCell>{user.username}</TableCell> */}
        <TableCell>
          <Text title={user.roles?.join(", ") || ""}>{user.fullname}</Text>
        </TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>
          {user.country}
          {user.city ? ` / ${user.city}` : ""}
        </TableCell>
        {/*         
      <TableCell>
            {loading && (
                <CircularProgress />
            )}
          <StyledSwitch
              disabled = {loading}
              title="Enable Cloud"
              checked={user.enableCloud === 'yes' }
              onChange={ async () => {
                   setLoading(true);
                  if (user.enableCloud === 'no'){
                    setSelectEnableCloud('yes')
                    user.enableCloud = 'yes';
                  } else {
                    setSelectEnableCloud('no')
                    user.enableCloud = 'no';
                  }
                  //await new Promise(r => setTimeout(r, 3000));
                  updateEnableCloud(user.id , crewId , user.enableCloud)
                  setLoading(false);


                }
              }
            />
        </TableCell>
 */}
        <TableCell>
          <UserProperties userId={user.id} />
        </TableCell>
        <TableCell>
          <UserActions userId={user.id} />
        </TableCell>
      </TableRow>
    </>
  );
};
