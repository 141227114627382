import request from "./request";

const cid = (c) => c.courseId;

export const calculatePrice = async (items, selectedBillingId) => {
  const courses = items.filter((c) => !c.isBundle).map(cid);
  const bundles = items.filter((c) => c.isBundle).map(cid);
  const users = items.map((u) => u.userId);

  const queryParams = [
    ...courses.map((cid) => `courses[]=${cid}`),
    ...bundles.map((bid) => `bundles[]=${bid}`),
    ...users.map((uid) => `u[]=${uid}`),
    `billingId=${selectedBillingId}`,
  ].join("&");
  const json = await request(`/payment/price?${queryParams}`);
  return json;
};

export const createOrder = async (items, billingId) => {
  const json = await request.post(`/payment/order`, {
    items,
    billingId,
  });
  return json;
};

export const payWithStripe = async (token, priceForStripe, currency, items, billingId, shoppingItems, paymentType) => {
  const resp = await request.post(`/payment/stripe`, {
    priceForStripe,
    currency,
    token,
    items,
    billingId,
    shoppingItems,
    paymentType,
  });
  return resp;
};

export const createPayWithInvoice = async (items, billingId, shoppingItems, paymentType, status, invoiceQR) => {
  const json = await request.post(`/payment/invoice`, {
    items,
    billingId,
    shoppingItems,
    status,
    paymentType,
    invoiceQR,
  });
  return json;
};

export const updateOrder = async (orderId, items) => {
  const json = await request.put(`/payment/order/${orderId}`, items);
  return json;
};

export const deleteOrder = async (orderId) => {
  const status = await request.delete(`/payment/order/${orderId}`);
  return status;
};

export const getOrderStatus = async (orderId) => {
  const json = await request(`/payment/order/${orderId}`);
  return json;
};

export const getAvailableProducts = async (crewId, userId) => {
  const json = await request(`/payment/products?crewId=${crewId}&userId=${userId}`);
  return json;
};

export const getUserBoughtCourses = async (userId, crewId) => {
  const json = await request(`/payment/products/${userId}?crewId=${crewId}`);
  return json;
};

export const getWoocommerceProducts = async () => {
  const json = await request(`/payment/products/woocommerce`);
  return json;
};

export const getActiveSubscriptions = async (crewId) => {
  const url = crewId ? `/payment/subscriptions?crewId=${crewId}` : `/payment/subscriptions/all`;
  return await request(url);
};

export const cancelSubscription = async (subId, bundleId, userId) => {
  const json = await request.delete(`/payment/subscriptions/${subId}?bundleId=${bundleId}&userId=${userId}`);
  return json;
};
