import React from "react";

import { Switch, Route } from "react-router";
import NgftAppsAdd from "../../features/ngft-apps-add";
import NgftAppsTable from "../../features/ngft-apps-table";
import NgftAppsEdit from "../../features/ngft-apps-edit";

const NgftApps = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={NgftAppsTable} />
        <Route exact path={`${match.path}/add_ngft_apps`} component={NgftAppsAdd} />
        <Route exact path={`${match.path}/edit/:ngftAppsId`} component={NgftAppsEdit} />
      </Switch>
    </>
  );
};

export default NgftApps;
