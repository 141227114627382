import React from "react";

import { Switch, Route } from "react-router";
import CoursesGroupsAdd from "../../features/courses-groups-add";
import CoursesGroupsTable from "../../features/courses-groups-table";
import CoursesGroupsEdit from "../../features/courses-groups-edit";

const CoursesGroups = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={CoursesGroupsTable} />
        <Route exact path={`${match.path}/add_courses_group`} component={CoursesGroupsAdd} />
        <Route exact path={`${match.path}/:groupCourseId`} component={CoursesGroupsEdit} />
      </Switch>
    </>
  );
};

export default CoursesGroups;
