import CertificateService from "../../services/certificate";

const filterByAircraft = (aircraftIds) => (course) =>
  !course.specializationId && course.aircraftTypeId && aircraftIds.includes(course.aircraftTypeId);

const byPosOrOperation =
  (categoryId, positionId, operationId = null) =>
  (course) =>
    !course.hidden &&
    course.categoryId === categoryId &&
    (!course.positionId || course.positionId === positionId) &&
    (!course.operationId || !operationId || course.operationId === operationId);

const toDisplayObject = (certificates, metadata) => (aircraftCourses) => {
  const { categoryId, positionId, operationId } = aircraftCourses;
  const title = `${metadata.categories.find((category) => category.id === categoryId).name} → ${
    metadata.positions.find((position) => position.id === positionId).name
  }${operationId ? " → " + metadata.operations.find((operation) => operation.id === operationId).name : ""}`;

  const aircraftObj = {};
  for (const course of aircraftCourses.courses) {
    const aid = course.aircraftTypeId;
    if (!aircraftObj[aid]) aircraftObj[aid] = [];
    const matched = CertificateService.mapToCourse(course, certificates);
    aircraftObj[aid].push(matched);
  }
  for (const customCourse of aircraftCourses.customCourses) {
    const aid = customCourse.aircraftTypeId;
    if (!aircraftObj[aid]) aircraftObj[aid] = [];
    const matched = CertificateService.mapToCustomCourse(customCourse, certificates);
    aircraftObj[aid].push(matched);
  }

  for (const group of aircraftCourses.groups) {
    const aid = group.aircraftTypeId;
    if (!aircraftObj[aid]) aircraftObj[aid] = [];
    const matched = CertificateService.mapToGroup(group, certificates);
    aircraftObj[aid].push(matched);
  }

  const aircraft = Object.entries(aircraftObj).map(([aircraftId, certificates]) => ({
    title: metadata.aircraft.find((a) => a.id === +aircraftId).name,
    status: CertificateService.groupStatus(certificates),
    certificates,
  }));
  return {
    title,
    status: CertificateService.groupStatus(aircraft),
    aircraft,
  };
};

const AircraftService = {
  getAircraft(aircraft, courses, properties) {
    // Get aircraft IDs that are assigned to the crew
    const crewAircraftIds = aircraft.map(({ id }) => id).filter((id) => courses.relations.crewAircraft?.includes(id));

    // Filter courses, custom courses and groups by aircraft type
    const aircraftTypeFilter = filterByAircraft(crewAircraftIds);
    const aircraftCourses = courses.courses.filter(aircraftTypeFilter);
    const aircraftCustomCourses = courses.customCourses.filter(aircraftTypeFilter);
    const aircraftGroups = courses.groups.filter(aircraftTypeFilter);

    // Group courses by position and operation
    return (
      properties
        .filter((property) => property.positionId)
        .map((property) => {
          const positionFilter = byPosOrOperation(property.categoryId, property.positionId, property.operationId);
          const filteredCourses = aircraftCourses.filter(positionFilter);
          const filteredCustomCourses = aircraftCustomCourses.filter(positionFilter);
          const filteredGroups = aircraftGroups.filter(positionFilter);

          return {
            categoryId: property.categoryId,
            positionId: property.positionId,
            operationId: property.operationId,
            courses: filteredCourses,
            customCourses: filteredCustomCourses,
            groups: filteredGroups,
          };
        })
        // keep entries that have at least one course
        .filter((result) => result.courses.length || result.customCourses.length || result.groups.length)
    );
  },
  toDisplayObject(ops, certificates, metadata) {
    return ops.map(toDisplayObject(certificates, metadata));
  },
};

export default AircraftService;
