import React, { useState, useEffect } from "react";
import PostAddIcon from "@mui/icons-material/PostAdd";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import Confirm from "../../components/Confirm";
import ScheduleItem from "./scheduleItem";
import { getTrainingDetails } from "../../api/training";
import { deleteScheduleSlotById } from "../../api/schedule.js";
import { getEventScheduled } from "../../api/schedule";
import useCrew from "../../hooks/useCrew";

const ScheduleTable = ({ match, history }) => {
  const [usersScheduleList, setUsersScheduleList] = useState([]);
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [selectedTrainingId, setSelectedTrainingId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);
  const [selectedEventType, setEventType] = useState([]);
  const { crew } = useCrew();

  function showDeleteModal(id) {
    setSelectedSlotId(id);
    setIsDeleteModalShow(true);
  }

  function onAdd(trainingId, userId, eventType) {
    //history.push(`/trainings/training-complete/${userCrewTraining.training_id}`)}
    history.push(`${match.url}/schedule-add/${eventType}/${trainingId}/${userId}`);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteScheduleSlotById(selectedSlotId);
    }
    setIsDeleteModalShow(false);
    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      const trainingId = match.params.trainingId;
      const eventType = match.params.eventType;
      setEventType(eventType);
      const trainingRes = await getTrainingDetails(trainingId);

      setSelectedTrainingId(trainingId);
      const usersAbleTo = await getEventScheduled(eventType, trainingId, crew.id);

      const usersAbleToEnriched = usersAbleTo.map((obj) => ({
        ...obj,
        courseName: trainingRes.displayname,
        trainingId: trainingId,
      }));
      setUsersScheduleList(usersAbleToEnriched);
    })();
  }, [refresh]);

  return (
    <>
      <h2>Scheduled {selectedEventType}</h2>
      <TableComponent
        TopComponent={
          <Button
            size="small"
            onClick={() => history.push(`${match.url}/schedule-add/${selectedEventType}/${selectedTrainingId}/all`)}
          >
            <PostAddIcon />
            Add slot to all
          </Button>
        }
        search={["fullname", "user_id"]}
        header={[
          { title: "User ID", sort: "user_id", style: { width: "5%" } },
          { title: "User" },
          { title: "Trainer Course" },
          { title: "Location" },
          { title: "Start" },
          { title: "End" },
          { title: "Actions", style: { width: "7%" } },
        ]}
        renderChildren={(slots, trainingCourse) => (
          <ScheduleItem
            key={slots.user_id}
            onDelete={showDeleteModal}
            onAdd={onAdd}
            slots={slots}
            eventType={selectedEventType}
          />
        )}
        items={usersScheduleList}
        pagination
      />
      <Button title="Go back" type="button" onClick={() => history.goBack()}>
        Back
      </Button>
      {isDeleteModalShow && selectedSlotId ? (
        <Confirm title={"Delete Operation"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the slot?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default ScheduleTable;
