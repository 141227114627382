import React from "react";

import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { StyledSwitch } from "../../components/Base";
import Button from "../../components/Button";

const SpecializationItem = ({ onEdit, onDelete, onStatusChange, specialization }) => {
  return (
    <TableRow>
      <TableCell>{specialization.id}</TableCell>
      <TableCell>{specialization.name}</TableCell>
      <TableCell>
        <StyledSwitch
          title="Change specialization status"
          checked={specialization.status === "active"}
          onChange={() => onStatusChange(specialization.id, specialization.status === "active" ? "passive" : "active")}
        />
      </TableCell>
      <TableCell>
        <Link style={{ marginRight: "1rem" }} onClick={() => onEdit(specialization.id)}>
          <Button title="Edit specialization" icon={<EditIcon />} />
        </Link>
        <Link onClick={() => onDelete(specialization.id)}>
          <Button title="Delete specialization" icon={<DeleteForeverIcon color="error" />} />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default SpecializationItem;
