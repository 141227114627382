import { useCallback, useEffect, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";

import useCrew from "../../hooks/useCrew";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getCrewUsersTrainings } from "../../api/training";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { useShoppingCart } from "../../context/ShoppingCartContext";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { Link } from "react-router-dom";
import GetAppIcon from "@mui/icons-material/GetApp";
import { openExportWindow } from "../../api/";

function formatDate(string) {
  if (string) {
    return new Date(string).toLocaleString("de-CH", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    });
  } else {
    return "Missing";
  }
}

const CrewUsersTrainings = ({ match, history }) => {
  const { crew } = useCrew();
  const crewId = crew.id;
  const shoppingCart = useShoppingCart();
  const [crewUsersTrainings, setCrewUsersTrainings] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await getCrewUsersTrainings(crewId);
      setCrewUsersTrainings(res);
    })();
  }, [crew]);

  const addToCart = useCallback(
    (crewUsersTraining) => {
      const itemToAdd = {
        ...crewUsersTraining.trainingCourse,
        ...(crewUsersTraining.trainingHierarchy.highestCourseId && {
          highestInTrainingHierarchyCourseId: crewUsersTraining.trainingHierarchy.highestCourseId,
          highestInTrainingHierarchyCourseName: crewUsersTraining.trainingHierarchy.highestCourseName,
        }),
      };

      shoppingCart.actions.add(itemToAdd, crewUsersTraining.userId);
    },
    [shoppingCart.actions],
  );

  const getAddToCartTitle = useMemo(() => {
    return (crewUsersTraining) => {
      if (crewUsersTraining.trainingHierarchy.highestCourseId) {
        return "Add to cart: highest training in hierarchy";
      } else {
        const { id, displayName, price } = crewUsersTraining.trainingCourse;
        return `Add to cart: (${id}) ${displayName}, ${price}`;
      }
    };
  }, []);

  return (
    <>
      <Typography variant="h4">Crew Users Training</Typography>
      {crewUsersTrainings && (
        <TableComponent
          header={[
            { title: "User ID", style: { width: "5%" } },
            { title: "User" },
            { title: "Training" },
            { title: "Position" },
            { title: "Valid Until" },
            { title: "Start Date" },
            { title: "End Date" },
            { title: "Actions", style: { width: "10%" } },
          ]}
          renderChildren={(crewUsersTraining) => (
            <TableRow key={crewUsersTraining.unique_key}>
              <TableCell>{crewUsersTraining.userId}</TableCell>
              <TableCell>{crewUsersTraining.fullname}</TableCell>
              <TableCell>{crewUsersTraining.trainingName}</TableCell>
              <TableCell>{crewUsersTraining.trainingPositions}</TableCell>
              <TableCell>{formatDate(crewUsersTraining.validUntil)}</TableCell>
              <TableCell>{formatDate(crewUsersTraining.trainingStartDate)}</TableCell>
              <TableCell>{formatDate(crewUsersTraining.trainingEndDate)}</TableCell>

              <TableCell>
                {crewUsersTraining.bought || crewUsersTraining.trainingHierarchy.boughtByCourseId ? (
                  <>
                    {!crewUsersTraining.hasCertificate && (
                      <Button
                        title={`Training bought: ${crewUsersTraining.trainingHierarchy.boughtByCourseId ? "by a higher training in the hierarchy" : `(${crewUsersTraining.trainingCourseId})`}`}
                        icon={<AssignmentTurnedInIcon />}
                        onClick={() => {}}
                      />
                    )}
                    <Link
                      to={`/training-users/user/details/${crewUsersTraining.trainingHierarchy.highestTrainingId || crewUsersTraining.trainingId}/${crewUsersTraining.userId}/${crewUsersTraining.trainingHierarchy.boughtByCourseId || crewUsersTraining.trainingCourseId}`}
                    >
                      <Button
                        title={`Checking progress: ${crewUsersTraining.trainingHierarchy.boughtByCourseId ? "by a higher training in the hierarchy" : `(${crewUsersTraining.trainingCourseId})`}`}
                        icon={<PublishedWithChangesIcon />}
                      />
                    </Link>
                    {crewUsersTraining.hasCertificate && (
                      <Button
                        title={`Download Certificate${crewUsersTraining.trainingHierarchy.passedByCourseId ? " (by a higher training in the hierarchy)" : ""}`}
                        icon={<GetAppIcon />}
                        onClick={() => openExportWindow(crewUsersTraining.trainingCourseId, crewUsersTraining.userId)}
                      />
                    )}
                  </>
                ) : crewUsersTraining.hasCertificate ? (
                  <Button
                    title={`Download Certificate${crewUsersTraining.trainingHierarchy.passedByCourseId ? " (by a higher training in the hierarchy)" : ""}`}
                    icon={<GetAppIcon />}
                    onClick={() => openExportWindow(crewUsersTraining.trainingCourseId, crewUsersTraining.userId)}
                  />
                ) : (
                  <Button
                    title={getAddToCartTitle(crewUsersTraining)}
                    icon={<AddShoppingCartIcon />}
                    onClick={() => addToCart(crewUsersTraining)}
                  />
                )}
              </TableCell>
            </TableRow>
          )}
          search={["userId", "fullname", "trainingName"]}
          pagination
          items={crewUsersTrainings}
        />
      )}
    </>
  );
};

export default CrewUsersTrainings;
