import { FC } from "react";
import Input from "./Input";

interface TextareaProps {
  name: string;
  label: string;
  rows?: number;
}

const Textarea: FC<TextareaProps> = ({ name, label, rows = 3 }) => (
  <Input name={name} label={label} rows={rows} multiline />
);

export default Textarea;
