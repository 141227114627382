import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { Row } from "../Base";
import Button from "../Button";
import Autocomplete from "../Form/StyledAutocomplete";

const UserRoleForm = ({ selected, available, onSave, onCancel, rowFormat = (element) => element }) => {
  const [selectedUserRoles, setSelectedUserRoles] = useState(selected);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setSelectedUserRoles(selected);
  }, [selected]);

  const save = async () => {
    setMessage(null);
    setError(null);
    const selectedIds = selectedUserRoles?.map((r) => r.id);
    const success = await onSave(selectedIds);
    if (success) {
      setMessage("Saved roles for member");
    } else {
      setError("Error saving roles, please try again later");
    }
  };

  const cancel = () => {
    setSelectedUserRoles([]);
    onCancel && onCancel();
  };

  return (
    <>
      <Row>
        <Box fontStyle="italic">
          If you do not select a role then the current invite member will be the only "Company Member".
        </Box>
      </Row>
      <Row>
        {rowFormat(
          <Autocomplete
            multiple
            label="User Roles"
            selected={selectedUserRoles}
            items={available}
            onChange={(items) => {
              setSelectedUserRoles(items);
            }}
          />,
        )}
      </Row>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Button onClick={save} title="Save current changes">
            Save
          </Button>
          <Button onClick={cancel} title="Discard current changes">
            Cancel
          </Button>
        </Box>
        {message ? <Typography color="success">{message}</Typography> : null}
      </Box>
      {error ? (
        <Box mt={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : null}
    </>
  );
};

export default UserRoleForm;
