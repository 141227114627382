import React from "react";

import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { StyledSwitch } from "../../components/Base";
import Button from "../../components/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import QrCode2Icon from "@mui/icons-material/QrCode2";

import request from "../../api/request";

export async function getSSID() {
  const ssid = await request(`/sts`);
  return ssid;
}

function formatDate(string) {
  return new Date(string).toLocaleString("de-CH");
}

const downloadOrder = async (filename) => {
  const ssid = await getSSID();
  window.open(`${process.env.REACT_APP_API_URL}/files/download/invoice/${filename}?ssid=${ssid}`, "_blank");
};

const downloadQRCode = async (filename) => {
  const ssid = await getSSID();
  const qrCodeFilename = filename.replace("Invoice_NGFT_", "Invoice_NGFT_QR_");
  window.open(`${process.env.REACT_APP_API_URL}/files/download/invoice/${qrCodeFilename}?ssid=${ssid}`, "_blank");
};

const OrderItem = ({ onDelete, onRefund, onStatusChange, order }) => {
  return (
    <TableRow key={order.order_id}>
      <TableCell>{order.order_id}</TableCell>
      <TableCell>{order.company_name}</TableCell>
      <TableCell>{order.buyer}</TableCell>
      <TableCell>
        {order.items.map((d, index) => (
          <li key={`${order.order_id}-${index}`}>
            {d.course_name} - {d.user_name}
          </li>
        ))}
      </TableCell>
      <TableCell>{order.payment_type}</TableCell>
      <TableCell>{order.total}</TableCell>

      <TableCell>{formatDate(order.created_at)}</TableCell>
      <TableCell>{order.status}</TableCell>
      <TableCell>
        <StyledSwitch
          title="Set to paid"
          checked={order.status === "paid"}
          onChange={() => onStatusChange(order.order_id, order.status === "paid" ? "processing" : "paid")}
        />
      </TableCell>
      <TableCell>
        <Button title="Download Invoice" icon={<GetAppIcon />} onClick={() => downloadOrder(order.invoice_name)} />
        <Button title="Download QR code" icon={<QrCode2Icon />} onClick={() => downloadQRCode(order.invoice_name)} />

        <Link onClick={() => onRefund(order.order_id, order.buyer_id)}>
          <Button title="Refund order" icon={<ArrowBackIcon />} />
        </Link>

        <Link onClick={() => onDelete(order.order_id, order.buyer_id)}>
          <Button title="Delete order" icon={<DeleteForeverIcon color="error" />} />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default OrderItem;
