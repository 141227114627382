import { FC, PropsWithChildren, createContext, useContext, useState, useEffect } from "react";
import ShoppingCartService from "../services/payments";
import useCrew from "../hooks/useCrew";

interface Certificate {
  id: number;
  name: string;
  isBundle: boolean;
  highestInTrainingHierarchyCourseId?: number;
  highestInTrainingHierarchyCourseName?: string;
  highestInTrainingHierarchyTrainingId?: number;
}

export interface ShoppingCartItem {
  courseId: number;
  courseName: string;
  userId: number;
  crewId: number;
  isBundle: boolean;
}

interface ShoppingCartActions {
  clear: () => Promise<void>;
  add: (certificate: Certificate, userId: number) => Promise<void>;
  remove: (item: ShoppingCartItem) => Promise<void>;
  checkout: (billingId: number) => Promise<boolean | void>;
  payWithInvoice: (billingId: number, shoppingItems: ShoppingCartItem[]) => Promise<boolean>;
}

interface ShoppingCartContextType {
  items: ShoppingCartItem[];
  actions: ShoppingCartActions;
}

const ShoppingCartContext = createContext<ShoppingCartContextType | null>(null);

export const ShoppingCartProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [shoppingCartItems, setShoppingCartItems] = useState<ShoppingCartItem[]>([]);
  const { crew } = useCrew();

  useEffect(() => {
    (async () => {
      try {
        await ShoppingCartService.updateOrderStatus(crew.id);
        const storedItems = ShoppingCartService.getCrewItems(crew.id);
        setShoppingCartItems(storedItems);
      } catch {
        console.log("Failed to update shopping cart.");
        setShoppingCartItems([]);
      }
    })();
  }, [crew]);

  const clear = async () => {
    await ShoppingCartService.clear(crew.id);
    setShoppingCartItems(ShoppingCartService.getCrewItems(crew.id));
  };

  const add = async (certificate: Certificate, userId: number) => {
    if (!certificate) {
      return;
    }
    const item: ShoppingCartItem = {
      courseId: certificate.highestInTrainingHierarchyCourseId || certificate.id,
      courseName: certificate.highestInTrainingHierarchyCourseName || certificate.name,
      userId,
      crewId: crew.id,
      isBundle: certificate.isBundle,
    };
    await ShoppingCartService.add(crew.id, item);
    setShoppingCartItems(ShoppingCartService.getCrewItems(crew.id));
  };

  const remove = async (item: ShoppingCartItem) => {
    await ShoppingCartService.removeItem(crew.id, item);
    setShoppingCartItems(ShoppingCartService.getCrewItems(crew.id));
  };

  const checkout = async (billingId: number) => {
    const order = await ShoppingCartService.checkout(crew.id, billingId);
    if (order?.url) {
      await clear();
      window.location.href = order.url;
    } else {
      console.log(
        `failed to create order, there should be an error message in the server's log; crewId=${crew.id} billingId=${billingId}`,
      );
      return false;
    }
  };

  const payWithInvoice = async (billingId: number, shoppingItems: ShoppingCartItem[]) => {
    const orderPayWithInvoice = await ShoppingCartService.payWithInvoice(crew.id, billingId, shoppingItems);

    if (orderPayWithInvoice) {
      return orderPayWithInvoice.orderId !== "error";
    }
    console.log(
      `failed to create order, there should be an error message in the server's log; crewId=${crew.id} billingId=${billingId}`,
    );
    return false;
  };

  const contextValue: ShoppingCartContextType = {
    items: shoppingCartItems,
    actions: { clear, add, remove, checkout, payWithInvoice },
  };

  return <ShoppingCartContext.Provider value={contextValue}>{children}</ShoppingCartContext.Provider>;
};

export const useShoppingCart = () => {
  const context = useContext(ShoppingCartContext);
  if (context === null) {
    throw new Error("useShoppingCart must be used within a ShoppingCartProvider");
  }
  return context;
};

export default ShoppingCartContext;
