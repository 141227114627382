import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form } from "../../components/Form";
import Autocomplete from "../../components/Form/StyledAutocomplete";
import { BasePaper, Row } from "../../components/Base";
import Button from "../../components/Button";
import {
  createAssociationPositionProcessStep,
  updateAssociationPositionProcessStep,
  getAssociationPositionProcessStepById,
} from "../../api/associate-position-process-step";
import { getPositions } from "../../api/positions";
import { getProcessStep } from "../../api/process-step";

const EditProcessStep = ({ match, history }) => {
  const { associatePositionProcessStepId } = match.params;
  const isAdd = !associatePositionProcessStepId;
  const [loaded, setLoaded] = useState(false);
  const [Position, setPosition] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [processStep, setProcessStep] = useState(null);
  const [selectedProcessStep, setSelectedProcessStep] = useState();
  const [selectedStatus, setStatus] = useState("active");

  useEffect(() => {
    (async () => {
      const positionRes = await getPositions();
      setPosition(positionRes);
      const processStepRes = await getProcessStep();
      setProcessStep(processStepRes);

      if (associatePositionProcessStepId) {
        const associationPositionProcessStep =
          await getAssociationPositionProcessStepById(associatePositionProcessStepId);
        const positionReturn = positionRes.find((c) => c.id === associationPositionProcessStep[0].positionId);
        setSelectedPosition(positionReturn);
        const processStepIds = associationPositionProcessStep[0].data.map((item) => item.processStepId);
        const processStepReturn = processStepRes.filter((c) => processStepIds.includes(c.id));
        setSelectedProcessStep(processStepReturn);
      }
      setLoaded(true);
    })();
  }, [associatePositionProcessStepId]);

  const goBack = () => history.goBack();

  const onSubmit = async (values) => {
    const positionId = selectedPosition.id;
    const processStepIds = selectedProcessStep.map((c) => c.id);

    let result;
    if (isAdd) {
      result = await createAssociationPositionProcessStep(positionId, processStepIds);
    } else {
      result = await updateAssociationPositionProcessStep(positionId, processStepIds);
    }
    if (result.ok) {
      history.push("/associate-position-process-step");
    }
  };

  const title = isAdd ? "Add new Associaton" : "Edit associaton";

  return (
    <>
      <Typography variant="h4">
        <Button icon={<ArrowBackIcon />} onClick={goBack} />
        {title}
      </Typography>
      {loaded ? (
        <BasePaper>
          <Form onSubmit={onSubmit}>
            <Row>
              <Autocomplete
                label="Position"
                items={Position}
                selected={selectedPosition}
                onChange={(items) => {
                  setSelectedPosition(items);
                }}
                formatLabel={(item) => `(${item.id}) ${item.name}`}
              />
            </Row>
            <Row>
              <Autocomplete
                label="Process Step List"
                items={processStep}
                selected={selectedProcessStep}
                multiple
                onChange={(items) => {
                  setSelectedProcessStep(items);
                }}
                formatLabel={(item) => `(${item.id}) ${item.name}`}
              />
            </Row>
            <Row>
              <Button type="submit">Save</Button>
              <Button onClick={goBack}>Cancel</Button>
            </Row>
          </Form>
        </BasePaper>
      ) : null}
    </>
  );
};

export default EditProcessStep;
