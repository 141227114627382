import React, { useState, useEffect } from "react";
import PostAddIcon from "@mui/icons-material/PostAdd";

import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import Confirm from "../../components/Confirm";

import NgftAppsItem from "./NgftAppsItem";

import { getNgftApp, deleteNgftAppdById } from "../../api/ngftApp.js";

const NgftAppsTable = ({ match, history }) => {
  const [ngftAppsList, ngftAppList] = useState([]);
  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id) {
    setSelectedOperationId(id);
    setIsDeleteModalShow(true);
  }

  function onEdit(id) {
    history.push(`${match.url}/edit/${id}`);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteNgftAppdById(selectedOperationId);
    }
    setIsDeleteModalShow(false);

    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      const ngftAppsList = await getNgftApp();
      ngftAppList(ngftAppsList);
    })();
  }, [refresh]);

  return (
    <>
      <h2>NGFT APPs</h2>
      <TableComponent
        TopComponent={
          <Button size="small" onClick={() => history.push(`${match.path}/add_ngft_apps`)}>
            <PostAddIcon />
            Add NGFT APP
          </Button>
        }
        header={[
          { title: "ID", sort: "id", style: { width: "5%" } },
          { title: "Name" },
          { title: "Short Name" },
          { title: "Color" },
          { title: "Text color" },
          { title: "Svg Icon" },
          { title: "Actions", style: { width: "7%" } },
        ]}
        renderChildren={(ngft_app) => (
          <NgftAppsItem key={ngft_app.id} onDelete={showDeleteModal} onEdit={onEdit} ngft_app={ngft_app} />
        )}
        items={ngftAppsList}
        search={["name", "id"]}
        pagination
      />
      {isDeleteModalShow && selectedOperationId ? (
        <Confirm title={"Delete Operation"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the NGFT APPs?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default NgftAppsTable;
