import { useCallback, useContext, useMemo, useState, MouseEvent } from "react";

import MuiSelect, { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, IconButton, InputLabel, MenuItem } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import FormContext from "./FormContext";
import { SelectableObject } from "./types";

interface SelectProps {
  label: string;
  items: SelectableObject[];
  // TODO: replace "" for not selected value with something more meaningful
  value: SelectableObject | "";
  required?: boolean;
  onChange: (value: SelectableObject | "") => void;
  sx?: Record<string, any>;
}

const createLabelId = (label: string): string => label.toLowerCase().split(" ").join("-");

const BaseSelect = ({ label, items, value, required, onChange, ...rest }: SelectProps) => {
  const labelId = useMemo(() => createLabelId(label), [label]);
  const [requiredError, setRequiredError] = useState<boolean>(false);

  const handleRequired = useCallback(
    (e: any) => {
      if (!required) return;

      const hasValue = value || (e && e.id) || (e && e.target && "selected" in e.target);

      setRequiredError(e === "cleared" || !hasValue);
    },
    [value, required],
  );

  const handleClear = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      onChange("");
      handleRequired("cleared");
    },
    [onChange, handleRequired],
  );

  return (
    <FormControl variant="outlined" size="small" fullWidth>
      <InputLabel
        id={`${labelId}-label`}
        sx={(theme) => ({
          color: requiredError ? theme.palette.error.main : theme.palette.primary.main,
        })}
      >
        {label}
      </InputLabel>
      <MuiSelect
        value={value && value.getId()}
        onChange={(e: SelectChangeEvent<number | string>) => {
          onChange(items.find((item) => item.isEqual(e.target.value)) ?? "");
          handleRequired(e);
        }}
        onClose={handleRequired}
        onBlur={handleRequired}
        labelId={`${labelId}-label`}
        label={label}
        error={requiredError}
        endAdornment={
          value ? (
            <IconButton size="small" style={{ marginRight: 8 }} onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          ) : null
        }
        {...rest}
      >
        {items.map((item, index) => {
          const itemLabelId = createLabelId(item?.getDisplayName ? item.getDisplayName() : String(index));
          return (
            <MenuItem key={item.getId()} value={item.getId()} id={`${itemLabelId}-item`}>
              {item?.getDisplayName ? item.getDisplayName() : String(index)}
            </MenuItem>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
};

export const Select = (props: SelectProps) => <BaseSelect {...props} />;

interface SelectFormProps {
  name: string;
  label: string;
  items: SelectableObject[];
}

const SelectForm = ({ name, label, items, ...rest }: SelectFormProps) => {
  const context = useContext(FormContext);
  const value = context.get(name)?.value ?? "";

  const change = (newValue?: SelectableObject | "") => {
    context.set(name, newValue ?? "");
  };

  return <BaseSelect label={label} items={items} value={value} onChange={change} {...rest} />;
};

export default SelectForm;
