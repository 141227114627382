import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import useCrew from "../../hooks/useCrew";
import AccordingTable from "../../components/AccordingTable";

const UserOverview = () => {
  const { t } = useTranslation();
  const { crew } = useCrew();
  const [rows, setRows] = useState([]);
  const [expandTable, seteExpandTable] = useState(true);
  const [refresh, setRefresh] = useState(1);

  // show only for admin crew
  if (crew?.id === 0) {
    return <Typography variant="h2">{t("welcome_message")} Admin</Typography>;
  }

  const nextCourses = [
    {
      name: "SMS",
      location: "Zürich",
      date: "11.10.2022",
      showCartIcon: "true",
      showBoughtIcon: 1,
      showPlayIcon: 1,
    },
    {
      name: "DG",
      location: "Biel",
      date: "11.10.2022",
    },
    {
      name: "Ex1",
      location: "Zürich",
      date: "11.10.2022",
    },
    {
      name: "Ex2",
      location: "Zürich",
      date: "11.10.2022",
    },
    {
      name: "SMS",
      location: "Biel",
      date: "11.10.2022",
    },
    {
      name: "DG",
      location: "Zürich",
      date: "11.10.2022",
    },
    {
      name: "Ex1",
      location: "Zürich",
      date: "11.10.2022",
    },
    {
      name: "Ex2",
      location: "Zürich",
      date: "11.10.2022",
    },
  ];

  const myTriningCourses = [
    {
      name: "XX",
      location: "Biel",
      date: "11.10.2022",
    },
    {
      name: "SSSS",
      location: "Zürich",
      date: "11.10.2022",
    },
    {
      name: "DDDD",
      location: "Biel",
      date: "11.10.2022",
    },
  ];

  const myTriningAssessments = [];
  const myTriningChecks = [
    {
      name: "DDDD",
      location: "Biel",
      date: "11.10.2022",
    },
    {
      name: "XX",
      location: "Biel",
      date: "11.10.2022",
    },
  ];

  return (
    <div>
      <Typography variant="h2">{t("welcome_message")} User Training Overview</Typography>
      {/*      
      <AccordingTable title="My next Courses" numDefaultItems="3" items={nextCourses}> </AccordingTable> 
      <AccordingTable title="My next Trainings"  numDefaultItems="2" items={myTriningCourses}> </AccordingTable> 
      <AccordingTable title="My next Assessments"   numDefaultItems="2" items={myTriningAssessments}> </AccordingTable> 
      <AccordingTable title="My next Checks"   numDefaultItems="2" items={myTriningChecks}> </AccordingTable> 
 */}
    </div>
  );
};

export default UserOverview;
