import { mapCourseShort, CourseShort } from "./courses";
import request from "./request";

import { SelectableWithIdObject } from "src/components/Form/types";

interface BundleBase {
  active: boolean;
  countries: number[];
  crews: number[];
  displayname: string;
  description?: string;
  isRefresher: boolean;
  price?: number;
  refresher?: number;
}

export interface BundleResponse extends BundleBase {
  id: number;
  courses: CourseShort[];
  validPeriod: number;
}

export class BundleResponse extends SelectableWithIdObject implements BundleResponse {
  public displayname: string;
  public description: string;
  public active: boolean;
  public isRefresher: boolean;
  public price: number | undefined;
  public refresher: number | undefined;
  public courses: CourseShort[];
  public validPeriod: number;
  public countries: number[];
  public crews: number[];
  constructor(data: BundleResponse) {
    super(data.id);
    this.displayname = data.displayname;
    this.description = data.description;
    this.active = data.active;
    this.isRefresher = data.isRefresher;
    this.price = data.price;
    this.refresher = data.refresher;
    this.courses = data.courses.map(mapCourseShort);
    this.validPeriod = data.validPeriod;
    this.countries = data.countries;
    this.crews = data.crews;
  }

  // TODO: simplify above with somethig like this:
  // constructor(public data: BundleResponse) {
  //   super(data.id);
  //   Object.assign(this, { ...data });
  //   this.courses = data.courses.map(mapCourseShort);
  // }

  getDisplayName(): string {
    return `(${this.id}) ${this.displayname}`;
  }
}

export interface BundleRequest extends BundleBase {
  courses: number[];
  validPeriod?: number;
}

export const mapBundle = (data: BundleResponse): BundleResponse => {
  return new BundleResponse(data);
};

export const getBundle = async (bundleId: string): Promise<BundleResponse> => {
  const response = await request(`/bundles/${bundleId}`);
  return mapBundle(response);
};

export const createBundle = async (bundle: BundleRequest) => {
  const response = await request.post(`/bundles`, bundle);
  return response;
};

export const updateBundle = async (bundleId: string, bundle: BundleRequest) => {
  const response = await request.put(`/bundles/${bundleId}`, bundle);
  return response;
};

export const getBundles = async ({
  activeOnly,
  refresher,
}: { activeOnly?: boolean; refresher?: boolean } = {}): Promise<BundleResponse[]> => {
  const params = new URLSearchParams();
  if (activeOnly) params.append("enabled", "true");
  if (refresher) params.append("refresher", "true");

  const url = `/bundles/${params.toString() ? `?${params.toString()}` : ""}`;
  const bundles = await request(url);
  return bundles.map(mapBundle);
};

export const changeBundleStatus = async (bundleId: string, status: number): Promise<{ active: number }> => {
  const response = await request.put(`/bundles/status/${bundleId}`, {
    active: status ? 1 : 0,
  });

  return response;
};

export const changeBundleRefresher = async (
  bundleId: string,
  isRefresher: number,
): Promise<{ isRefresher: number }> => {
  const response = await request.put(`/bundles/refresh/${bundleId}`, {
    isRefresher: isRefresher ? 1 : 0,
  });

  return response;
};
