import request from "./request";

export const getAssociateOperationProcessStep = async () => {
  const json = await request(`/associate-operation-process-step`);
  return json;
};

//getpassCondition
export const getAssociationPositionProcessStepById = async (associateOperationProcessStepId) => {
  const processStep = await request(`associate-operation-process-step/${associateOperationProcessStepId}`);
  return processStep;
};

export const createAssociationPositionProcessStep = async (operationId, processStepIds) => {
  const response = await request.post(`associate-operation-process-step`, {
    operationId,
    processStepIds,
  });
  return response;
};

export const updateAssociationPositionProcessStep = async (operationId, processStepIds) => {
  const response = await request.put(`associate-operation-process-step/${operationId}`, { processStepIds });
  return response;
};

export const deleteAssociateOperationProcessStepById = async (associateOperationProcessStepId) => {
  const json = await request(`associate-operation-process-step/${associateOperationProcessStepId}`, "DELETE");
  return json;
};
