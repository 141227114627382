import { sortBy, prop } from "ramda";
import { COURSE_STATUS } from "../../constants";

const sortByName = sortBy(prop("name"));

export const formatCourses = (courses) =>
  sortBy(
    prop("name"),
    courses.map((c) => ({
      id: c.course_id,
      name: c.fullname,
    })),
  );

const CrewCoursesService = {
  formatProducts(products, certificates) {
    return sortByName(
      products.map((p) => {
        const certificate = certificates.find((cert) => cert.id === p.id);

        return {
          id: p.id,
          status: certificate?.status || COURSE_STATUS.MISSING,
          price: p.price,
          name: p.name,
          type: p.type,
          visible: true, // all products available for buy are visible
          passedByCourseName: certificate?.passedByCourseName,
        };
      }),
    );
  },
  formatBundles(bundles) {
    return sortByName(
      bundles.map((bundle) => ({
        isBundle: true,
        name: bundle.name,
        id: bundle.id,
        price: bundle.price,
        description: bundle.description,
        courses: bundle.courses || [],
      })),
    );
  },
};

export default CrewCoursesService;
