import React from "react";

import { Switch, Route } from "react-router";
import GroupAircraftTable from "../../features/group-aircraft-table";
import GroupAircraftAdd from "../../features/group-aircraft-add";
import GroupAircraftEdit from "../../features/group-aircraft-edit";

const GroupAircraft = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={GroupAircraftTable} />

        <Route exact path={`${match.url}/:crewId/add_group_aircraft`} component={GroupAircraftAdd} />

        <Route exact path={`${match.url}/:groupAircraftId`} component={GroupAircraftEdit} />
      </Switch>
    </>
  );
};

export default GroupAircraft;
