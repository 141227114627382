import React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const UserTitle = ({ email, id }) => {
  return (
    <Box m={"1.25rem 0"}>
      <Typography variant="body1">
        #<Typography variant="button">{id}</Typography>, <Typography variant="button">{email || "-"}</Typography>
      </Typography>
    </Box>
  );
};

UserTitle.propTypes = {
  email: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UserTitle;
