import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableComponent from "../../components/TableComponent";
import Button from "../../components/Button";
import IdGenerator from "../../utils/IdGenerator";

import { Row } from "../../components/Form";
import { getAircraftSpecialization } from "../../api/crew";
import { addAircraftSpecialization, deleteAircraftSpecialization, getUserAircraft } from "../../api/user";
import { StyledPaper } from "../../components/Base";

const idGenerator = new IdGenerator();

const AircraftSpecialization = ({ match, history }) => {
  const [aircraftList, setAircraftList] = useState([]);
  const [specialization, setSpecialization] = useState({});
  const [selectedAircraft, setSelectedAircraft] = useState({});
  const [assignedList, setAssignedList] = useState([]);
  const [refresh, setRefresh] = useState(1);

  const { crewId, userId } = match.params;
  useEffect(() => {
    (async () => {
      const newAircraft = await getAircraftSpecialization(crewId);
      setAircraftList(newAircraft);
      const userAircraft = await getUserAircraft(crewId, userId);
      const newAssignedList = [];
      for (const aircraft of userAircraft) {
        const crewAircraft = newAircraft.find((item) => item.id === aircraft.id);
        // skip if not found
        if (!crewAircraft) continue;
        newAssignedList.push({ ...aircraft, name: crewAircraft.name });
      }

      // expand specializations array and add keyId
      const expandedAssignedList = [];
      for (const aircraft of newAssignedList) {
        if (!aircraft.specializations) {
          expandedAssignedList.push({
            id: aircraft.id,
            name: aircraft.name,
            keyId: idGenerator.getGeneratorId(),
          });
        } else {
          aircraft.specializations.forEach((specializationId) => {
            const specializationName = newAircraft
              .find((aircraftListItem) => aircraftListItem.id === aircraft.id)
              ?.specializations.find((specialization) => specialization.id === specializationId)?.name;

            const expandedAircraft = {
              keyId: idGenerator.getGeneratorId(),
              specialization: {
                id: specializationId,
                name: specializationName,
              },
              name: aircraft.name,
              id: aircraft.id,
            };
            expandedAssignedList.push(expandedAircraft);
          });
        }
      }
      setAssignedList(expandedAssignedList);
    })();
  }, [crewId, userId, refresh]);

  function setAircraft(value) {
    setSelectedAircraft(value);
    setSpecialization({});
  }

  async function addNewAircraftSpecialization() {
    await addAircraftSpecialization({
      crewId,
      userId,
      typeId: selectedAircraft.id,
      specializationId: specialization?.id ? specialization.id : null,
    });
    setSpecialization({});
    setSelectedAircraft({});

    setRefresh(refresh * -1);
  }

  async function removeAircraftSpecialization(id) {
    const assignedAircraft = assignedList.find((item) => item.id === id);
    await deleteAircraftSpecialization({
      crewId,
      userId,
      typeId: assignedAircraft.id,
      specializationId: assignedAircraft?.specialization?.id ? assignedAircraft?.specialization?.id : null,
    });
    setRefresh(refresh * -1);
  }
  return (
    <>
      <StyledPaper variant="outlined">
        {aircraftList.length ? (
          <Row>
            <Autocomplete
              onChange={(_, value) => setAircraft(value)}
              value={selectedAircraft || {}}
              options={aircraftList}
              size="small"
              inputValue={selectedAircraft?.name || ""}
              isOptionEqualToValue={(_, val) => val.id === selectedAircraft?.id}
              getOptionLabel={(item) => item?.name || ""}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Select Aircraft" />}
            />
          </Row>
        ) : null}

        {Object.keys(selectedAircraft).length && selectedAircraft.specializations.length ? (
          <Row>
            <Autocomplete
              onChange={(_, value) => setSpecialization(value)}
              value={specialization || {}}
              options={selectedAircraft.specializations}
              size="small"
              inputValue={specialization?.name || ""}
              isOptionEqualToValue={(_, val) => val.id === specialization?.id}
              getOptionLabel={(item) => item?.name || ""}
              renderInput={(params) => <TextField {...params} variant="outlined" label="Select Specialization" />}
            />
          </Row>
        ) : null}
        <Button title="Add new aircraft specialization" onClick={addNewAircraftSpecialization}>
          Add
        </Button>
        <Button title="Go back" onClick={() => history.goBack()}>
          Cancel
        </Button>
      </StyledPaper>
      <Typography variant="h4">Assigned aircraft</Typography>
      <TableComponent
        header={[{ title: "Aircraft" }, { title: "Specialization" }, { title: "Delete" }]}
        renderChildren={(aircraft) => (
          <TableRow key={aircraft.keyId}>
            <TableCell>{aircraft.name}</TableCell>
            <TableCell>{aircraft?.specialization?.name || ""} </TableCell>
            <TableCell>
              <Link onClick={() => removeAircraftSpecialization(aircraft.id)}>
                <Button
                  title="Delete Aircraft and related specializations"
                  icon={<DeleteForeverIcon color="error" />}
                />
              </Link>
            </TableCell>
          </TableRow>
        )}
        items={assignedList}
      />
    </>
  );
};

export default AircraftSpecialization;
