import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory, useRouteMatch } from "react-router-dom";

import UserContext from "../context/UserContext";
import CrewContext from "../context/CrewContext";

import Dashboard from "./dashboard";
import { isLoggedIn } from "../services/auth";
import UserSession from "../services/session";
import { getMyInfo } from "../api/user";

const PrivateRoute = ({ children, ...props }) => {
  const userLoggedIn = isLoggedIn();
  return <Route render={() => (userLoggedIn ? <Route {...props}>{children}</Route> : null)} />;
};

const WithUserContext = ({ children }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const crewId = match?.params?.crewId || UserSession.activeCrewId;
  const [user, setUser] = useState(null);
  const [activeCrew, setActiveCrew] = useState(null);

  const setCrew = (crew) => {
    // only for superadmin:
    // if there's a param in route other than crewId, go to home
    // if crewId: replace with new crewId
    let path;
    if (user?.crews?.find((c) => c.id === 0)) {
      const keys = Object.keys(match?.params || {});
      if (!match || keys.length > 1 || (keys.length && keys[0] !== "crewId")) {
        path = "/";
      } else {
        // stay where we are
        if (match.params.crewId != null) {
          // replace crewId
          path = history.location.pathname.replace(match.url, match.path.replace(":crewId", crew.id));
          // any other numeric params left in the url?
          const m = /\/(\d+)\/?/g.exec(path);
          if (m.length > 2) {
            // we bail, can't tell what those might be without the exact route match
            path = "/";
          }
        }
      }
    } else {
      path = "/";
    }
    setActiveCrew(crew);
    if (path && path !== history.location.pathname) {
      history.push(path);
    }
    UserSession.activeCrewId = crew.id;
  };

  useEffect(() => {
    getMyInfo()
      .then((userData) => {
        const crew = userData.crews.find((crew) => crew.id === +crewId);
        if (crew) {
          setActiveCrew(crew);
        } else {
          // set fake crew if there's no crews assigned
          setActiveCrew(
            userData.crews[0] || {
              name: userData.fullname,
            },
          );
        }
        setUser(userData);
      })
      .catch(() => {
        setUser(false);
      });
  }, []);

  return user ? (
    <UserContext.Provider value={user}>
      <CrewContext.Provider
        value={{
          crew: activeCrew,
          setCrew,
        }}
      >
        {children}
      </CrewContext.Provider>
    </UserContext.Provider>
  ) : user !== false ? null : (
    <Redirect to="/" />
  );
};

const Routes = () => (
  <Switch>
    <PrivateRoute path={["/crew/:crewId", "/"]}>
      <WithUserContext>
        <Dashboard />
      </WithUserContext>
    </PrivateRoute>
  </Switch>
);

export default Routes;
