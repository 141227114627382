import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import useUser from "../../hooks/useUser";
import React, { useState, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "../../components/Button";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import TableComponent from "../../components/TableComponent";
import { getUserCrewTrainings } from "../../api/training";
import { convertDateTime } from "../../services/formatters";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import useCrew from "../../hooks/useCrew";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const AssessorTrainings = ({ match, history }) => {
  const { t } = useTranslation();

  const [userCrewTrainings, setUserCrewTrainings] = useState([]);
  const user = useUser();
  const { crew } = useCrew();
  useEffect(() => {
    (async () => {
      const res = await getUserCrewTrainings("assessor", user.id, crew.id);
      setUserCrewTrainings(res);
    })();
  }, []);

  return (
    <>
      <Typography variant="h4">Assessor List</Typography>
      {userCrewTrainings ? (
        <TableComponent
          header={[
            { title: "ID", style: { width: "5%" } },
            { title: "Training" },
            { title: "Status" },
            { title: "e-learning" },
            { title: "Classroom" },
            { title: "Practical" },
            { title: "Assessor tasks done" },
            { title: "Actions" },
          ]}
          renderChildren={(userCrewTraining) => (
            <TableRow key={userCrewTraining.id}>
              <TableCell>{userCrewTraining.training_id}</TableCell>
              <TableCell>{userCrewTraining.displayname}</TableCell>
              <TableCell>{userCrewTraining.status}</TableCell>

              <TableCell>{userCrewTraining.is_e_learning ? "Yes" : "No"}</TableCell>
              <TableCell>{userCrewTraining.is_classroom ? "Yes" : "No"}</TableCell>
              <TableCell>{userCrewTraining.is_practical ? "Yes" : "No"}</TableCell>

              <TableCell>
                {userCrewTraining.allDone ? <CheckCircleIcon htmlColor="green" /> : <ErrorIcon htmlColor="red" />}
              </TableCell>

              <TableCell>
                <Button
                  icon={<PublishedWithChangesIcon />}
                  onClick={() => history.push(`/training-users/user/${userCrewTraining.training_id}`)}
                  title="Users Checking progress"
                />

                <Button
                  icon={<DateRangeIcon />}
                  onClick={() => history.push(`/trainings/schedule/assessor/${userCrewTraining.training_id}`)}
                  title="Schedule assessment"
                />

                <Button
                  icon={<AssignmentIcon />}
                  onClick={() => history.push(`/trainings/assessment/${userCrewTraining.training_id}`)}
                  title="Users assessment"
                />

                <Button
                  icon={<LibraryAddCheckIcon />}
                  onClick={() =>
                    history.push(
                      `/trainings/checking-progress/assessor/${userCrewTraining.training_id}/${userCrewTraining.instructor_complete}/${userCrewTraining.assessor_passed}/${userCrewTraining.checker_passed}`,
                    )
                  }
                  title="Sign-off Assessor"
                />
              </TableCell>
            </TableRow>
          )}
          search={["displayname", "fullname", "company_name"]}
          pagination
          items={userCrewTrainings}
        />
      ) : null}
    </>
  );
};

export default AssessorTrainings;
