// Use AutocompleteForm from ./Autocomplete.tsx instead of this

import { useContext } from "react";

import { Autocomplete as MaterialAutocomplete, TextField } from "@mui/material";

import FormContext from "./FormContext";

// TODO: fix this autocomplete, add multiple, remove excessive useless logic
const Autocomplete = ({ name, label, items }) => {
  const context = useContext(FormContext);
  const { value, error } = context.get(name);

  const change = (e, value, reason) => {
    let newValue = value;
    if (e.target?.value && !value) newValue = e.target?.value;

    // Check for matching string with item list
    if (!(newValue && typeof newValue === "object")) {
      const foundItem = items.find((item) => item?.name.toLowerCase() === newValue?.toLowerCase());
      if (foundItem) {
        newValue = foundItem;
      }
    }

    context.set(name, newValue);
  };

  return (
    <MaterialAutocomplete
      options={items}
      size="small"
      onChange={change}
      value={value || {}}
      inputValue={value?.name || value || ""}
      isOptionEqualToValue={(_, val) => val.id === value?.id}
      getOptionLabel={(item) => item?.name || ""}
      renderInput={(params) => (
        <TextField
          value={value?.name || value || ""}
          onChange={change}
          {...params}
          variant="outlined"
          label={label}
          error={!!error}
          {...(error ? { helperText: error } : {})}
        />
      )}
    />
  );
};

export default Autocomplete;
