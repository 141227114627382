import React, { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";

import TableComponent from "../../components/TableComponent";

import UserRow from "../../components/UserRow";

import { changeUserInvoice, changeUserStatus, getUsers } from "../../api/user";

const UsersTable = ({ match, history }) => {
  const [users, setUsers] = useState([]);

  const [refresh, setRefresh] = useState(1);

  async function onStatusChange(id, newStatus) {
    await changeUserStatus(id, newStatus);
    setRefresh(refresh * -1);
  }

  async function onInvoiceChange(id, newInvoiceStatus) {
    await changeUserInvoice(id, newInvoiceStatus);
    setRefresh(refresh * -1);
  }

  function onEdit(id) {
    history.push(`/users/${id}/edit`);
  }

  function onSetRole(id) {
    history.push(`/users/${id}/roles`);
  }

  function showCertificates(id) {
    history.push(`/user/${id}/certificates`);
  }

  useEffect(() => {
    (async () => {
      const users = await getUsers();
      setUsers(users);
    })();
  }, [refresh]);

  return (
    <div className="table-wrapper">
      <Typography variant="h4" fontSize="small" className="t-none">
        Users
      </Typography>
      <TableComponent
        header={[
          { title: "ID", sort: "id", style: { width: "5px", paddingRight: 0 } },
          { title: "Fullname", sort: "fullname" },
          { title: "Email", sort: "email" },
          { title: "Companies and Positions" },
          {
            title: "Country",
            sort: "country",
            style: { width: "5px", paddingRight: 0 },
          },
          { title: "Status", sort: "status" },
          { title: "Pay with Invoice", sort: "invoice" },
          { title: "Actions", style: { width: "120px" } },
        ]}
        renderChildren={(user) => (
          <UserRow
            key={user.id}
            onStatusChange={onStatusChange}
            onInvoiceChange={onInvoiceChange}
            onEdit={onEdit}
            onSetRole={onSetRole}
            onShowCertificates={showCertificates}
            user={user}
          />
        )}
        items={users}
        search={["fullname", "id", "email", "username", "name"]}
        pagination
        users={users}
      />
    </div>
  );
};

export default UsersTable;
