import React from "react";
import { Route } from "react-router-dom";

import PassCondition from "../../features/pass-condition";
import EditPassConditions from "../../features/pass-condition/edit";

const PassConditions = ({ match }) => (
  <>
    <Route path={`${match.path}/add`} component={EditPassConditions} />
    <Route path={`${match.path}/edit/:passConditionId`} component={EditPassConditions} />
    <Route exact path={`${match.path}`} component={PassCondition} />
  </>
);

export default PassConditions;
