import React from "react";

import { Switch, Route } from "react-router";
import PositionsAdd from "../../features/positions-add";
import PositionsEdit from "../../features/positions-edit";
import PositionsTable from "../../features/positions-table";

const Positions = ({ match, history }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={PositionsTable} />
        <Route exact path={`${match.path}/add_position`} component={PositionsAdd} />
        <Route exact path={`${match.path}/:positionId`} component={PositionsEdit} />
      </Switch>
    </>
  );
};

export default Positions;
