import React from "react";
import Typography from "@mui/material/Typography";

import OperationsService from "./service";
import ExpandableSection from "../../components/ExpandableSection";
import CertificateList from "../../components/Certificate/CertificateList";
import CourseStatusIcon from "../../components/CourseStatusIcon";

const OperationTitle = ({ operation: { title, status } }) => (
  <>
    <CourseStatusIcon status={status} />
    <Typography variant={"body1"} style={{ marginLeft: ".5rem" }}>
      {title}
    </Typography>
  </>
);

const Operations = ({ certificates, courses, properties, expand }) => {
  const operationsList = OperationsService.getOperations(
    properties,
    courses.courses,
    courses.customCourses,
    courses.groups,
    courses.relations,
  );
  const operations = OperationsService.toDisplayObject(operationsList, certificates, courses.relations);

  return (
    <>
      {operations.map((operation) => (
        <ExpandableSection
          key={operation.id}
          navId={operation.id}
          title={<OperationTitle operation={operation} />}
          expanded={expand}
        >
          <CertificateList certificates={operation.certificates} />
        </ExpandableSection>
      ))}
    </>
  );
};

export default Operations;
