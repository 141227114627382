import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";

import TextField from "@mui/material/TextField";
import Button from "../../components/Button";
import { updateGroupAircraft, getGroupAircraftById } from "../../api/group-aircraft";

import "./index.scss";

const CATEGORY_ERROR_TEXT = "Please enter the name of GroupAircraft";

const GroupAircraftEdit = ({ match, history }) => {
  const [newGroupAircraftName, setNewGroupAircraftName] = useState("");
  const [isGroupAircraftFetching, setIsGroupAircraftNameFetching] = useState(false);

  useEffect(() => {
    (async () => {
      setIsGroupAircraftNameFetching(true);
      const groupAircraft = await getGroupAircraftById(match.params.groupAircraftId);
      setNewGroupAircraftName(groupAircraft.name);
      setIsGroupAircraftNameFetching(false);
    })();
  }, [match.params.groupAircraftId]);

  async function editGroupAircraftName() {
    if (!newGroupAircraftName) return;
    const groupAircraftId = match.params.groupAircraftId;

    const result = await updateGroupAircraft(groupAircraftId, {
      name: newGroupAircraftName,
    });
    if (result.ok) {
      //history.push(match.url);
      history.goBack();
    }
  }

  return (
    <>
      <h2>Edit Group Aircraft</h2>
      <Paper variant="outlined" style={{ padding: "1rem 1.5rem" }}>
        {!isGroupAircraftFetching ? (
          <>
            <TextField
              className="edit-field"
              variant="outlined"
              size="small"
              label="Group aircraft name*"
              value={newGroupAircraftName}
              onChange={(event) => setNewGroupAircraftName(event.target.value)}
              error={!newGroupAircraftName}
              helperText={newGroupAircraftName ? null : CATEGORY_ERROR_TEXT}
            />
            <Button title="Save groupAircraft name" onClick={editGroupAircraftName}>
              Save GroupAircraft
            </Button>
            <Button title="Go back" onClick={() => history.goBack()}>
              Cancel
            </Button>
          </>
        ) : null}
      </Paper>
    </>
  );
};
export default GroupAircraftEdit;
