import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "../../components/Button";

import { Form, DatePicker, FileInput, Textarea, Validation } from "../Form";
import { StyledPaper } from "../Base";

const validation = {
  certificateFile: Validation.required,
  completionDate: Validation.required,
  validPeriod: Validation.noop,
  comment: Validation.noop,
};

const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10 Mb

const CertificateUpload = ({ course, isCustom, onSubmit }) => {
  const courseId = isCustom ? `cc_${course.id}` : `${course.id}`;

  const submit = async (values) => {
    if (values.certificateFile[0]?.size >= MAX_FILE_SIZE) {
      return {
        certificateFile: "File is too large. Maximum acceptable size is 10Mb",
      };
    }
    const error = await onSubmit(values);
    if (error) {
      return {
        certificateFile: `Error uploading file: ${error}`,
      };
    }
  };

  return (
    <StyledPaper variant="outlined">
      <Form onSubmit={submit} validation={validation}>
        <Box m={2}>
          <Typography variant="subtitle1">
            ({courseId}) {course.name}
          </Typography>
        </Box>
        <Box m={2}>
          <FileInput name="certificateFile" accept="image/*,.pdf" label="Certificate File*" />
        </Box>
        <Box m={2}>
          <Box marginRight={"1rem"} display={"inline-block"}>
            <DatePicker name="completionDate" disableFuture label="Completion Date*" />
          </Box>

          <Box display={"inline-block"}>
            <DatePicker name="validPeriod" label="Validity period" />
          </Box>

          <Box display={"inline-block"} alignItems="center" padding={2}>
            Leave blank if certificate never expires
          </Box>
        </Box>
        <Box m={2}>
          <Textarea name="comment" label="Comment" rows={5} />
        </Box>
        <Box m={2}>
          <Button type="submit" title="Save Certificate">
            Save
          </Button>
        </Box>
      </Form>
    </StyledPaper>
  );
};

export default CertificateUpload;
