import React, { useState, useEffect } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Button from "../../components/Button";

import { Form, DatePicker, InputForm, SelectForm, Row } from "../Form";
import { getCountriesList, getCountryByCode } from "../../services/countries";
import { getLanguagesList, getLanguageByCode } from "../../services/languages";
import { StyledPaper } from "../Base";

import useCrew from "../../hooks/useCrew";

const countries = getCountriesList();
const languages = getLanguagesList();

const EditMemberForm = ({ user, onSubmit }) => {
  const [selectEnable, setEnable] = useState("no");
  const [refresh, setRefresh] = useState(1);
  const { crew } = useCrew();
  const superadmin = crew.id === 0;

  return (
    <>
      <StyledPaper variant="outlined">
        {user ? (
          <>
            <Form
              onSubmit={onSubmit}
              validation={{}}
              defaultValues={{
                fullname: user.fullname,
                email: user.email,
                birthday: moment(user.birthday),
                country: getCountryByCode(user.country),
                city: user.city,
                language: getLanguageByCode(user.language),
              }}
            >
              <Row>
                <InputForm label="Fullname" name="fullname" />
              </Row>
              <Row>
                <InputForm label="Email" name="email" disabled={!superadmin} />
              </Row>
              {/* <Row>
                <InputForm name="password" label="Password" />
                <Button nosubmit onClick={genPassword}>Autogenerate</Button>
              </Row> */}
              <Row>
                <DatePicker name="birthday" disableFuture label="Birthday" />
              </Row>
              <Row>
                <SelectForm label="Country" name="country" items={countries} />
              </Row>
              <Row>
                <InputForm label="City" name="city" />
              </Row>
              <Row>
                <SelectForm label="Language" name="language" items={languages} />
              </Row>
              <Box>
                <Button type="submit" title="Save changes">
                  Save
                </Button>
              </Box>
            </Form>
          </>
        ) : null}
      </StyledPaper>
    </>
  );
};
export default EditMemberForm;
