import request from "./request";

export const getNgftApp = async () => {
  const json = await request(`/ngft_app`);

  return json;
};

export const deleteNgftAppdById = async (ngftAppId) => {
  const json = await request(`/ngft_app/${ngftAppId}`, "DELETE");
  return json;
};

export const addNgftApps = async (body) => {
  const response = await request(`/ngft_app`, "POST", body);
  return response;
};

export const getNgftAppById = async (ngftAppsId) => {
  const json = await request(`/ngft_app/${ngftAppsId}`);
  return json;
};

export const updateNgftApp = async (id, newNgftApps) => {
  const response = await request(`/ngft_app/${id}`, "PUT", newNgftApps);
  return response;
};

export const getNgftCrewApps = async () => {
  const json = await request(`/ngft_crew_app`);
  return json;
};

export const addNgftCrewApps = async (body) => {
  const response = await request(`/ngft_crew_app`, "POST", body);
  return response;
};

export const deleteNgftCrewApps = async (crewId, appId) => {
  const json = await request(`/ngft_crew_app/${crewId}/${appId}`, "DELETE");
  return json;
};

export const getNgftCrewAppsById = async (crewId) => {
  const json = await request(`/ngft_crew_app/${crewId}`);
  return json;
};

export const getCockpitPermitRoles = async () => {
  const json = await request(`/cockpit_role_permit`);
  return json;
};

export const addCockpitPermitRoles = async (body) => {
  const response = await request(`/cockpit_role_permit`, "POST", body);
  return response;
};

export const deleteCockpitPermitRoles = async (roleId) => {
  const json = await request(`/cockpit_role_permit/${roleId}`, "DELETE");
  return json;
};
