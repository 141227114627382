import { formatDate } from "../../services/formatters";

const byId = (courseId) => (certificate) => certificate.id === courseId;

const getDisplayObject =
  (userId, course, isCustom = false) =>
  (certificate) => ({
    id: certificate.id,
    uploadId: certificate.uploadId,
    name: course.name,
    completedDate: certificate.completedDate,
    validUntil: certificate.validUntil,
    fileName: certificate.certificateFile,
    userId,
    isCustom,
    uploadedAt: certificate.uploadedAt ? formatDate(certificate.uploadedAt) : "",
    approvedAt: certificate.approvedAt ? formatDate(certificate.approvedAt) : "",
    approvedBy: certificate.approvedBy,
  });

const HistoryService = {
  getHistory(certificate, allCourses, userCertificates, userId) {
    let courseId = certificate.id;
    let isCustom = false;
    let isGroup = !!certificate.group;
    const items = [];
    if (/cc_/.test(courseId)) {
      isCustom = true;
      courseId = +courseId.replace("cc_", "");
    }

    if (isGroup) {
      const cgroup = certificate.group;
      const group = allCourses.groups.find(
        (group) =>
          group.groupId === cgroup.id &&
          group.categoryId === cgroup.categoryId &&
          (!cgroup.positionId || group.positionId === cgroup.positionId) &&
          (!cgroup.operationId || group.operationId === cgroup.operationId),
      );
      if (group) {
        const courses = group?.courses || [];
        for (const course of courses) {
          const certificates = userCertificates.certificates.filter((certificate) => certificate.id === course.id);
          items.push(...certificates.map(getDisplayObject(userId, course, false)));
        }
      }
    } else {
      const cobj = isCustom ? allCourses.customCourses : allCourses.courses;
      const uobj = isCustom ? userCertificates.customCertificates : userCertificates.certificates;
      const course = cobj.find((c) => c.id === courseId);
      if (course) {
        const matched = uobj.filter(byId(course.id));
        items.push(...matched.map(getDisplayObject(userId, course, isCustom)));

        if (course.refreshers?.length > 0) {
          for (const refresher of course.refreshers) {
            const matched = userCertificates.certificates.filter(byId(refresher.id));
            items.push(...matched.map(getDisplayObject(userId, refresher)));
          }
        }
      }
    }
    return items;
    // return items.sort((a, b) => a.uploadId - b.uploadId);
  },
};

export default HistoryService;
