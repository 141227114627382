import React from "react";
import { Route } from "react-router-dom";

import ProcessStepTable from "../../features/process-step";
import EditProcessStep from "../../features/process-step/edit";

const ProcessStep = ({ match }) => (
  <>
    <Route path={`${match.path}/add`} component={EditProcessStep} />
    <Route path={`${match.path}/edit/:processStepId`} component={EditProcessStep} />
    <Route exact path={`${match.path}`} component={ProcessStepTable} />
  </>
);

export default ProcessStep;
