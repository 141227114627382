import { FC, useContext } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";

import FormContext from "./FormContext";

const INPUT_FORMAT = "YYYY-MM-DD HH:mm:ss";
const DISPLAY_FORMAT = "DD/MM/YYYY HH:mm";

interface DateTimePickerCustomProps {
  label: string;
  name: string;
  disableFuture?: boolean;
  disabled?: boolean;
}

const DateTimePickerCustom: FC<DateTimePickerCustomProps> = ({
  label,
  name,
  disableFuture = false,
  disabled = false,
}) => {
  const context = useContext(FormContext);

  const change = (momentDate: moment.Moment | null) => {
    context.set(name, momentDate ? momentDate.format(INPUT_FORMAT) : null);
  };

  const { value, error } = context.get(name);
  return (
    <DateTimePicker
      format={DISPLAY_FORMAT}
      value={value ? (moment.isMoment(value) ? value : moment(value, INPUT_FORMAT)) : null}
      disableFuture={disableFuture}
      disabled={disabled}
      label={label}
      onChange={change}
      ampm={false}
      slotProps={{
        textField: {
          placeholder: DISPLAY_FORMAT.toLowerCase(),
          helperText: DISPLAY_FORMAT.toLowerCase(),
          fullWidth: true,
          size: "small",
          error: !!error,
          ...(error ? { helperText: error } : {}),
        },
      }}
    />
  );
};

export default DateTimePickerCustom;
