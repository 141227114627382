import { useTranslation } from "react-i18next";

import UserCoursesPage from "../user-courses-page";

const MembersPage = ({ match, history }) => {
  const { t } = useTranslation();

  const headerCaptions = {
    positions: t("member_position_courses"),
    operations: t("member_operation_courses"),
    aircraft: t("member_aircraft_courses"),
    specializations: t("member_specialization_courses"),
    all: t("all_courses"),
    bundles: t("course_bundle.title_short"),
  };

  return (
    <UserCoursesPage
      match={match}
      history={history}
      userId={match.params.userId}
      crewId={match.params.crewId}
      showBackButton={true}
      headerCaptions={headerCaptions}
    />
  );
};

export default MembersPage;
