import React, { useState, useEffect } from "react";

import CrewMembersActions from "../../context/CrewMembersActionsContext";
import { useConfirm } from "../../context/ConfirmContext";

import { UserRow } from "../../components/UsersTable";
import TableComponent from "../../components/TableComponent";
import { getMembersList, removeMemberFromCrew, getCrewById } from "../../api/crew";

import useCrew from "../../hooks/useCrew";
import { formatLastFirstName } from "../../services/formatters";

import { hasPermission } from "../../services/permissions";

const MembersList = ({ match, history }) => {
  const crewId = match?.params?.crewId;
  const confirm = useConfirm();
  const { crew } = useCrew();
  const [loading, setLoading] = React.useState(false);

  const [members, setMembers] = useState(null);
  const [crewInfo, setCrewInfo] = useState(null);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    (async () => {
      const res = (await getMembersList(crewId)).sort((a, b) =>
        formatLastFirstName(a?.fullname).localeCompare(formatLastFirstName(b?.fullname)),
      );
      const crewInfo = await getCrewById(crewId);
      setCrewInfo(crewInfo);
      setMembers(res);
    })();
  }, [crewId, refresh]);

  const baseUrl = `/crew/${crewId}/members`;
  const nav = (userId, subpage) => history.push(`${baseUrl}/${userId}/${subpage}`);

  const userActions = {
    canAssignRoles() {
      return hasPermission("advanced_user_management");
    },
    remove(userId) {
      const member = members.find((user) => user.id === userId);
      confirm.show(
        `Remove member from company`,
        `Are you sure you want to remove ${member.fullname} from ${crew.name}?`,
        async () => {
          await removeMemberFromCrew(crewId, userId);
          setRefresh(refresh + 1);
        },
      );
    },
    edit(userId) {
      nav(userId, "edit/member");
    },
    roles(userId) {
      nav(userId, "edit/roles");
    },
    positions(userId) {
      nav(userId, "positions");
    },
    operations(userId) {
      nav(userId, "operations");
    },
    aircraft(userId) {
      nav(userId, "aircraft");
    },
    specializations(userId) {
      nav(userId, "specializations");
    },
    allCertificates(userId) {
      history.push(`/user/${userId}/certificates`);
    },
  };

  return (
    <>
      <h2>Members List</h2>

      {members ? (
        <CrewMembersActions.Provider value={userActions}>
          <TableComponent
            items={members}
            renderChildren={(user) => <UserRow key={user.id} user={user} crewId={crewId} crewInfo={crewInfo} />}
            header={[
              { title: "ID", sort: "id", style: { width: "2%" } },
              /* { title: "Username", sort: "username", style: { width: "20%" } }, */
              { title: "Full Name", sort: "fullname", style: { width: "26%" } },
              { title: "Email", sort: "email", style: { width: "22%" } },
              { title: "Country / City", style: { width: "10%" } },
              //{ title: "Enable Cloud", style: { width: "5%" } },
              { title: "Properties", style: { width: "20%" } },
              { title: "Actions", style: { width: "15%" } },
            ]}
            search={["username", "fullname", "email", "country", "city", "id", "roles"]}
            pagination
          />
        </CrewMembersActions.Provider>
      ) : null}
    </>
  );
};

export default MembersList;
