import { useTranslation } from "react-i18next";

import useUser from "../../hooks/useUser";
import useCrew from "../../hooks/useCrew";

import UserCoursesPage from "../user-courses-page";

const UserPage = ({ match }) => {
  const { t } = useTranslation();
  const user = useUser();
  const { crew } = useCrew();

  const headerCaptions = {
    positions: t("my_position_courses"),
    operations: t("my_operations_courses"),
    aircraft: t("my_aircraft_courses"),
    specializations: t("my_specializations_courses"),
    all: t("all_company_courses"),
    bundles: t("course_bundle.title_short"),
  };

  if (!user || !crew) {
    return null;
  }

  return (
    <UserCoursesPage match={match} user={user} userId={user.id} crewId={crew.id} headerCaptions={headerCaptions} />
  );
};

export default UserPage;
