import request from "./request";

const observation2Request = (observable) => ({
  name: observable.name,
  test_type: observable.test_type,
  area: observable.area,
  status: observable.status,
});

export const getObservables = async () => {
  const json = await request(`/observable`);
  return json;
};

//getpassCondition
export const getObservable = async (observationId) => {
  const observable = await request(`/observable/${observationId}`);
  return observable;
};

export const createObservable = async (observable) => {
  const response = await request.post(`/observable`, observation2Request(observable));
  return response;
};

export const updateObservable = async (observationId, observable) => {
  const response = await request.put(`/observable/${observationId}`, observation2Request(observable));
  return response;
};

export const deleteObservableById = async (observationId) => {
  const json = await request(`/observable/${observationId}`, "DELETE");
  return json;
};
