import React, { useState, useEffect } from "react";

import PostAddIcon from "@mui/icons-material/PostAdd";
import TableComponent from "../../components/TableComponent";
import Button from "../../components/Button";
import Confirm from "../../components/Confirm";
import GroupAircraftItem from "./GroupAircraftItem";

import { getCrewGroupAircraft, deleteGroupAircraftById, updateGroupAircraft } from "../../api/group-aircraft";

const GroupAircraftTable = ({ match, history }) => {
  const { crewId } = match.params;
  const [groupAircraft, setGroupAircraft] = useState([]);
  const [selectedGroupAircraftId, setSelectedGroupAircraftId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id) {
    setSelectedGroupAircraftId(id);
    setIsDeleteModalShow(true);
  }

  function onEdit(id) {
    //onClick={() => history.push(`${match.url}/${crewId}/add_group_aircraft`)}
    history.push(`${match.url}/${id}`);
  }

  async function onStatusChange(id, newStatus) {
    await updateGroupAircraft(id, { status: newStatus });

    setRefresh(refresh * -1);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteGroupAircraftById(selectedGroupAircraftId);
    }
    setIsDeleteModalShow(false);

    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      let newGroupAircraft = await getCrewGroupAircraft(crewId);
      setGroupAircraft(newGroupAircraft);
    })();
  }, [refresh]);

  return (
    <>
      <h2>Group Aircraft</h2>
      <TableComponent
        TopComponent={
          <Button size="small" onClick={() => history.push(`${match.url}/${crewId}/add_group_aircraft`)}>
            <PostAddIcon />
            Add Group Aircraft
          </Button>
        }
        header={[
          { title: "ID", sort: "id", style: { width: "5%" } },
          { title: "Name", sort: "name" },
          { title: "Status", sort: "status", style: { width: "15%" } },
          { title: "Actions", style: { width: "15%" } },
        ]}
        renderChildren={(groupAircraft) => (
          <GroupAircraftItem
            key={groupAircraft.id}
            onEdit={onEdit}
            onDelete={showDeleteModal}
            onStatusChange={onStatusChange}
            groupAircraft={groupAircraft}
          />
        )}
        items={groupAircraft}
        search={["name", "id"]}
        pagination
      />
      {isDeleteModalShow && selectedGroupAircraftId ? (
        <Confirm title={"Delete Group Aircraft"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the groupAircraft?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default GroupAircraftTable;
