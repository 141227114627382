import React, { useEffect, useState } from "react";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import Button from "../../components/Button";
import { Row, FlexRow, Separator } from "../../components/Base";
import { getUserBillingAddresses } from "../../api/user";

import BillingDialog from "./dialog";

const BillingAddressSelector = ({ onSelect }) => {
  const [addresses, setAddresses] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [showBillingDialog, setShowBillingDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    (async () => {
      const addresses = await getUserBillingAddresses();
      setAddresses(addresses);
      setSelectedId(addresses[0]?.id);
      onSelect(addresses[0]?.id);
    })();
  }, [refresh]);

  const showAddDialog = () => {
    setShowBillingDialog(true);
  };

  const hideDialog = () => {
    setShowBillingDialog(false);
    setRefresh(refresh + 1);
  };

  return (
    <>
      {addresses?.length ? (
        <FlexRow>
          <Select
            label="Billing Address"
            value={selectedId}
            onChange={(e) => {
              onSelect(e.target.value);
              setSelectedId(e.target.value);
            }}
          >
            {addresses.map((address) => (
              <MenuItem key={address.id} value={address.id}>
                {address.companyName}; {address.firstName} {address.lastName}, {address.addressLine1}, {address.city}{" "}
                {address.country}
              </MenuItem>
            ))}
          </Select>
          <Separator />
          <div style={{ display: "flex" }}>
            <Button
              onClick={() => {
                setSelectedId(null);
                showAddDialog();
              }}
              title="Add new billing address"
            >
              Add Address
            </Button>
            <Separator />
            <Button onClick={showAddDialog} title="Edit selected billing address">
              Edit Address
            </Button>
          </div>
        </FlexRow>
      ) : (
        <Row>
          <Button onClick={showAddDialog} title="Add Billing Address">
            Add Billing Address
          </Button>
        </Row>
      )}
      {showBillingDialog ? <BillingDialog id={selectedId} done={hideDialog} /> : null}
    </>
  );
};

export default BillingAddressSelector;
