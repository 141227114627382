import { createContext, useContext } from "react";

const CrewMembersActionsContext = createContext({
  canAssignRoles() {},
  edit(userId) {},
  roles(userId) {},
  remove(userId) {},
  positions(userId) {},
  operations(userId) {},
  aircraft(userId) {},
  specializations(userId) {},
});

export default CrewMembersActionsContext;

export const useCrewMembersActions = () => useContext(CrewMembersActionsContext);
