import React from "react";

import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { StyledSwitch } from "../../components/Base";
import Button from "../../components/Button";
import GetAppIcon from "@mui/icons-material/GetApp";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { LocalAtm } from "@mui/icons-material";

import request from "../../api/request";
import { useHistory } from "react-router-dom";

function formatDate(string) {
  const date = new Date(string);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedDate = `${day}.${month}.${year}`;
  return formattedDate;
}

const transactionItem = ({ transaction }) => {
  const history = useHistory();
  return (
    <TableRow>
      <TableCell>{transaction.id}</TableCell>
      <TableCell>{transaction.company}</TableCell>
      <TableCell>{transaction.similtatyCrewName}</TableCell>

      <TableCell>{transaction.price}</TableCell>
      <TableCell>
        {transaction.tranaction_info.split("; ").map((i, key) => {
          return <div key={key}>{i}</div>;
        })}
      </TableCell>

      <TableCell>{formatDate(transaction.bookg_date)}</TableCell>
      <TableCell>{formatDate(transaction.validity_date)}</TableCell>
      <TableCell>{transaction.reference}</TableCell>
      <TableCell>{transaction.matched}</TableCell>
      <TableCell>
        <Button
          icon={<LocalAtm />}
          onClick={() => history.push(`/orders/${transaction.similtatyCrewId}/${transaction.id}`)}
          title="Crew Orders"
        />
      </TableCell>
      {/* 
      <TableCell>
        <StyledSwitch
          title="Set to paid"
          checked={transaction.status === "paid"}
          onChange={() =>
            onStatusChange(
              transaction.transaction_id,
              transaction.status === "paid" ? "processing" : "paid"
            )
          }
        /> 
      </TableCell>

 */}
    </TableRow>
  );
};

export default transactionItem;
