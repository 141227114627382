import React, { useState, useEffect } from "react";
import TableComponent from "../../components/TableComponent";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import Confirm from "../../components/Confirm";
import OrderItem from "./OrderItem";
import Button from "../../components/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { getOrders, deleteOrderById, updateOrder, getTransactionById, getOrderCrew } from "../../api/orders";

function formatDate(string) {
  const date = new Date(string);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedDate = `${day}.${month}.${year}`;
  return formattedDate;
}

const OrdersTable = ({ match, history }) => {
  const crewId = match.params.crewId;
  const transactionId = match.params.transactionId;

  const [similarCompany, setSimilarCompany] = React.useState([]);
  const [orders, setOrders] = useState([]);
  const [transaction, setTransaction] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedbuyerId, setSelectedbuyerId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [isRefundModalShow, setIsRefundModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id, buyerId) {
    setSelectedOrderId(id);
    setSelectedbuyerId(buyerId);
    setIsDeleteModalShow(true);
  }

  function showRefundModal(id, buyerId) {
    setSelectedOrderId(id);
    setSelectedbuyerId(buyerId);
    setIsRefundModalShow(true);
  }

  function onEdit(id) {
    history.push(`${match.path}/${id}`);
  }

  async function onStatusChange(id, newStatus) {
    await updateOrder(id, { status: newStatus });
    setRefresh(refresh * -1);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      //await deleteOrderById(selectedOrderId);
      await updateOrder(selectedOrderId, {
        status: "cancelled",
        buyerId: selectedbuyerId,
      });
      setRefresh(refresh * -1);
    }
    setIsDeleteModalShow(false);

    setRefresh(refresh * -1);
  }

  async function confirmRefundModal(isConfirmed) {
    if (isConfirmed) {
      //await deleteOrderById(selectedOrderId);
      await updateOrder(selectedOrderId, {
        status: "refunded",
        buyerId: selectedbuyerId,
      });
      setRefresh(refresh * -1);
    }

    setIsRefundModalShow(false);

    setRefresh(refresh * -1);
  }

  const handleChange = async (event) => {
    const selectedOrders = await getOrderCrew(event.target.value);
    setOrders(selectedOrders);
  };

  useEffect(() => {
    (async () => {
      let newOrders = [];
      if (crewId) {
        newOrders = await getOrderCrew(crewId);
      } else {
        newOrders = await getOrders();
      }
      setOrders(newOrders);

      if (transactionId) {
        const trans = await getTransactionById(transactionId);
        setSimilarCompany(trans.companyListSorted);
        setTransaction(trans);
      }
    })();
  }, [refresh]);

  const goBack = () => history.goBack();
  return (
    <>
      {transaction ? (
        <>
          <h2>Transaction</h2>
          <Button title="Go back" type="button" onClick={() => history.goBack()}>
            Go back
          </Button>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>New Selection:</TableCell>
                  <TableCell>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      // TODO: this looks weird
                      value=""
                      onChange={handleChange}
                      label="Crew"
                    >
                      {similarCompany.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Crew from transaction:</TableCell>
                  <TableCell>{transaction.company}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Price:</TableCell>
                  <TableCell>{transaction.price}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Booking Date:</TableCell>
                  <TableCell>{formatDate(transaction.bookg_date)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Validity Date:</TableCell>
                  <TableCell>{formatDate(transaction.validity_date)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Info:</TableCell>
                  <TableCell>
                    {transaction.tranaction_info.split("; ").map((i, key) => {
                      return <div key={key}>{i}</div>;
                    })}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        ""
      )}

      <h2>Orders</h2>
      <TableComponent
        header={[
          { title: "ID", sort: "id", style: { width: "5%" } },
          { title: "Crew", sort: "crew" },
          { title: "Buyer", sort: "buyer", style: { width: "10%" } },

          { title: "Items", sort: "items" },
          { title: "Paid with", sort: "payment_type" },
          { title: "Total", sort: "total", style: { width: "5%" } },
          { title: "Date", sort: "date", style: { width: "10%" } },
          { title: "Status", sort: "status", style: { width: "7%" } },
          { title: "Set to paid", style: { width: "5%" } },
          { title: "Actions", style: { width: "13%" } },
        ]}
        renderChildren={(order) => (
          <OrderItem
            key={`order-${order.order_id}`}
            onEdit={onEdit}
            onDelete={showDeleteModal}
            onRefund={showRefundModal}
            onStatusChange={onStatusChange}
            order={order}
          />
        )}
        items={orders}
        search={["company_name", "order_id", "total", "status", "buyer"]}
        pagination
      />
      {isDeleteModalShow && selectedOrderId ? (
        <Confirm title={"Delete Order"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the order?</p>
        </Confirm>
      ) : null}
      {isRefundModalShow && selectedOrderId ? (
        <Confirm title={"Refund Order"} onConfirm={confirmRefundModal}>
          <p>Are you sure you want to refund the order?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default OrdersTable;
