import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Autocomplete from "../../../components/Form/StyledAutocomplete";
import { Form, InputForm, Validation, DateTimePickerCustom } from "../../../components/Form";
import { BasePaper, Row, StyledSwitch } from "../../../components/Base";
import Button from "../../../components/Button";
import { getMoodleCoursesForTraining } from "../../../api/courses";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createTraining, getTraining, updateTraining } from "../../../api/training";
import { getCrews } from "../../../api/crew";
import { getPositions } from "../../../api/positions";
import { getTrainersOrAssessmentOrChecker } from "../../../api/user";
import useUser from "../../../hooks/useUser";
import webBasedOptions from "../../../services/webBasedOptions.json";
import courseStatusOptions from "../../../services/courseStatus.json";
import classroomOptions from "../../../services/classroomOptions.json";
import practicalTrainingOptions from "../../../services/practicalTrainingOptions.json";
import Divider from "@mui/material/Divider";
import MuiAutocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

function uniqueList(duplicateList) {
  const uniq = duplicateList.filter((item, index) => {
    return (
      index ===
      duplicateList.findIndex((obj) => {
        return obj.id === item.id && obj.fullname === item.fullname;
      })
    );
  });
  return uniq;
}

const TrainingEditForm = ({ match, history }) => {
  const user = useUser();
  const { trainingId } = match.params;
  const isAdd = !trainingId;
  const [training, setTraining] = useState(null);
  const [courses, setCourses] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [eLearningMoodle, setELearningMoodle] = useState([]);
  const [selectedELearningMoodle, setELearningMoodleCourses] = useState([]);
  //const [classificationItems, setClassificationItems] = useState([]);
  const [classroomMoodle, setClassroomMoodle] = useState([]);
  const [selectedClassroomMoodle, setELearningClassroomMoodle] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedCrews, setSelectedCrews] = useState([]);
  const [crews, setCrews] = useState([]);
  const [Trainers, setTrainers] = useState([]);
  const [selectedTrainers, setSelectedTrainers] = useState([]);
  const [Instructors, setInstructors] = useState([]);
  const [selectedInstructors, setSelectedInstructors] = useState([]);
  const [Assessors, setAssessors] = useState([]);
  const [selectedAssessors, setSelectedAssessors] = useState([]);
  const [Checkers, setCheckers] = useState([]);
  const [selectedCheckers, setSelectedCheckers] = useState([]);
  const [selectedWebBasedOptions, setWebBasedOption] = useState();
  const [selectedClassroomOptions, setClassroomOptions] = useState(classroomOptions[0]);
  const [selectedPracticalTrainingOptions, setPracticalTrainingOptions] = useState([]);
  const [selectedCourseStatus, setCourseStatus] = useState("Active");
  const [isELearning, setIsELearning] = useState(0);
  const [isClassroom, setIsClassroom] = useState(0);
  const [isPractical, setIsPractical] = useState(0);
  const [selectedELearningPassContions, setselectedELearningPassContions] = useState([]);
  const [selectedClassroomPassContions, setselectedClassroomPassContions] = useState([]);
  const [selectedPracticalPassContions, setselectedPracticalPassContions] = useState([]);
  const [eLearningCheck, setELearningCheck] = useState(0);
  const [classroomAttendance, setClassroomAttendance] = useState(0);
  const [classroomCheck, setClassroomCheck] = useState(0);
  const [writtenTest, setWrittenTest] = useState(0);
  const [instructorComplete, setInstructorComplete] = useState(0);
  const [assessorPassed, setAssessorPassed] = useState(0);
  const [checkerPassed, setCheckerPassed] = useState(0);
  const [selectedClassificationItems, setSelectedClassificationItems] = useState([]);
  const [handleChecked, setHandleChecked] = useState(false);
  const [position, setPosition] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState([]);
  const [selectedTrainingContext, setTrainingContext] = useState("individual");

  const classificationItems = ["in den Gefahrenklassen 1 – 6, 8 + 9 inkl. RRE", "in den Gefahrenklassen 1 – 9"];

  const contextTrainingOptions = ["individual", "automatic"];
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    (async () => {
      const Positions = await getPositions();
      setPosition(Positions.map((elem) => elem.name));
      setCourseStatus(courseStatusOptions[0]);
      setELearningMoodleCourses({ displayname: "" });
      setWebBasedOption(webBasedOptions[0]);

      const crews = await getCrews();
      setCrews(crews);

      const trainersOrAssessmentOrChecker = await getTrainersOrAssessmentOrChecker();
      const Trainers = trainersOrAssessmentOrChecker.filter((value) => value.roleCode === "trainer");
      setTrainers(Trainers);
      const Instructors = trainersOrAssessmentOrChecker.filter((value) => value.roleCode === "instructor");
      setInstructors(Instructors);
      const Assessors = trainersOrAssessmentOrChecker.filter((value) => value.roleCode === "assessor");
      setAssessors(Assessors);

      const Checkers = trainersOrAssessmentOrChecker.filter((value) => value.roleCode === "checker");
      setCheckers(Checkers);

      const availableCourses = await getMoodleCoursesForTraining();
      setCourses(availableCourses);
      setELearningMoodle(availableCourses);
      setClassroomMoodle(availableCourses);

      if (trainingId) {
        const trainingRes = await getTraining(trainingId);
        setTrainingContext(trainingRes.context);
        setSelectedPosition(trainingRes.positions?.split("; "));
        setSelectedClassificationItems(trainingRes.classification?.split("; "));
        setELearningMoodleCourses(availableCourses.find((el) => el.id === trainingRes.eLearningMoodle));
        setELearningClassroomMoodle(availableCourses.find((el) => el.id === trainingRes.classroomMoodle));
        setTraining(trainingRes);
        setELearningCheck(trainingRes.eLearningCheck);
        setClassroomAttendance(trainingRes.classroomAttendance);
        setClassroomCheck(trainingRes.classroomCheck);
        setWrittenTest(trainingRes.writtenTest);
        setInstructorComplete(trainingRes.instructorComplete);
        setAssessorPassed(trainingRes.assessorPassed);
        setCheckerPassed(trainingRes.checkerPassed);
        const courseStatusReturn = courseStatusOptions.filter((i) => i.code === trainingRes.status)[0];
        setCourseStatus(courseStatusReturn || "");
        const classroomOptionsReturn = classroomOptions.filter((i) => i.code === trainingRes.classroom)[0];
        setClassroomOptions(classroomOptionsReturn || "");
        const webBasedOptionReturn = webBasedOptions.filter((i) => i.code === trainingRes.webBased)[0];
        setWebBasedOption(webBasedOptionReturn || "");
        setSelectedCourses(trainingRes.courses || []);
        setSelectedCrews(trainingRes.crews || []);
        setSelectedTrainers(uniqueList(trainingRes.trainers) || []);
        setSelectedInstructors(uniqueList(trainingRes.instructors) || []);
        setSelectedAssessors(uniqueList(trainingRes.assessors) || []);
        setSelectedCheckers(uniqueList(trainingRes.checkers) || []);
        setIsELearning(trainingRes.isELearning);
        setIsClassroom(trainingRes.isClassroom);
        setIsPractical(trainingRes.isPractical);

        if (trainingRes.crews.length === crews.length) {
          setHandleChecked(true);
        }

        if (trainingRes.practicalTraining) {
          const practicalTrainingList = trainingRes.practicalTraining.split(" ");
          const resPracticalTraining = practicalTrainingOptions.filter((i) => practicalTrainingList.includes(i.code));
          setPracticalTrainingOptions(resPracticalTraining);
        }
      }

      setLoaded(true);
    })();
  }, [trainingId]);

  const goBack = () => history.goBack();
  const onSubmit = async (values) => {
    const courses = selectedCourses.map((c) => c.id);
    const crews = selectedCrews.map((c) => c.id);
    const Trainers = selectedTrainers.map((c) => c.id);
    const Instructors = selectedInstructors.map((c) => c.id);
    const Assessors = selectedAssessors.map((c) => c.id);
    const Checkers = selectedCheckers.map((c) => c.id);

    values.classification = selectedClassificationItems;
    values.positions = selectedPosition;
    values.eLearningCheck = eLearningCheck;
    values.classroomCheck = classroomCheck;
    if (selectedELearningMoodle) {
      values.eLearningMoodle = selectedELearningMoodle.id;
    } else {
      values.eLearningMoodle = null;
    }
    if (selectedClassroomMoodle) {
      values.classroomMoodle = selectedClassroomMoodle.id;
    } else {
      values.classroomMoodle = null;
    }
    values.classroomAttendance = classroomAttendance;
    values.writtenTest = writtenTest;
    values.instructorComplete = instructorComplete;
    values.assessorPassed = assessorPassed;
    values.checkerPassed = checkerPassed;

    if (selectedCourseStatus) {
      values.status = selectedCourseStatus.code;
    } else {
      values.status = "";
    }

    values.contextTraining = selectedTrainingContext;

    if (selectedClassroomOptions) {
      values.classroom = selectedClassroomOptions.code;
      if (selectedClassroomOptions.code == "on_site") {
        values.virtualClassroomLink = "";
      } else {
        values.location = "";
      }
    } else {
      values.classroom = "";
    }
    values.isELearning = isELearning;
    values.isClassroom = isClassroom;
    values.isPractical = isPractical;

    let retVal = "";
    for (var i = 0; i < selectedPracticalTrainingOptions.length; i++) {
      retVal += selectedPracticalTrainingOptions[i].code + " ";
    }
    values.practicalTraining = retVal;
    let result;
    if (isAdd) {
      result = await createTraining(values, courses, crews, Trainers, Instructors, Assessors, Checkers);
    } else {
      result = await updateTraining(trainingId, values, courses, crews, Trainers, Instructors, Assessors, Checkers);
    }
    if (result.ok) {
      history.push("/trainings");
    }
  };

  const handleAllCrews = (event) => {
    if (event.target.checked) {
      setSelectedCrews(crews);
      setHandleChecked(true);
    } else {
      setSelectedCrews([]);
      setHandleChecked(false);
    }
  };

  const handleClassroomCheck = (event) => {
    if (event.target.checked) {
      setClassroomCheck(1);
    } else {
      setClassroomCheck(0);
    }
  };

  const handleWrittenTest = (event) => {
    if (event.target.checked) {
      setWrittenTest(1);
    } else {
      setWrittenTest(0);
    }
  };

  const handleELearningCheck = (event) => {
    if (event.target.checked) {
      setELearningCheck(1);
    } else {
      setELearningCheck(0);
    }
  };

  const handleClassroomAttendance = (event) => {
    if (event.target.checked) {
      setClassroomAttendance(1);
    } else {
      setClassroomAttendance(0);
    }
  };

  const handleInstructorComplete = (event) => {
    if (event.target.checked) {
      setInstructorComplete(1);
    } else {
      setInstructorComplete(0);
    }
  };

  const handleAssessorPassed = (event) => {
    if (event.target.checked) {
      setAssessorPassed(1);
    } else {
      setAssessorPassed(0);
    }
  };

  const handleCheckerPassed = (event) => {
    if (event.target.checked) {
      setCheckerPassed(1);
    } else {
      setCheckerPassed(0);
    }
  };

  const title = isAdd ? "Add new training" : "Edit training";

  return (
    <>
      <Typography variant="h4">
        <Button icon={<ArrowBackIcon />} onClick={goBack} />
        {title}
      </Typography>
      {loaded ? (
        <BasePaper>
          <Form
            defaultValues={{
              courseId: training?.courseId,
              displayname: training?.displayname,
              status: training?.status,
              trainingSlot: training?.trainingSlot,
              trainingStartDate: training?.trainingStartDate,
              trainingEndDate: training?.trainingEndDate,
              crew: training?.crewId,
              classroom: training?.classroom,
              location: training?.location,
              virtualClassroomLink: training?.virtualClassroomLink,
              classroomResourceLink: training?.classroomResourceLink,
              onlineTestLink: training?.onlineTestLink,
            }}
            validation={{
              displayname: Validation.required,
              trainingSlot: Validation.required,
              trainingStartDate: Validation.verifyDatetimeFormat,
              trainingEndDate: Validation.verifyDatetimeFormat,
            }}
            onSubmit={onSubmit}
          >
            <Row>
              <Autocomplete
                label="Context Training"
                items={contextTrainingOptions}
                selected={selectedTrainingContext}
                onChange={(items) => {
                  setTrainingContext(items);
                }}
                formatLabel={(item) => `${item}`}
              />
            </Row>

            <Row>
              <InputForm name="displayname" label="Training name*" />
            </Row>

            <Row>
              <MuiAutocomplete
                size="small"
                freeSolo
                multiple
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    setSelectedClassificationItems([...selectedClassificationItems, inputValue]);
                    setInputValue("");
                  }
                }}
                options={classificationItems}
                value={selectedClassificationItems || ""}
                onChange={(event, newSelectedValues) => {
                  setSelectedClassificationItems(newSelectedValues);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Classification (If free text: press enter to save)"
                    variant="outlined"
                  />
                )}
              />
            </Row>

            <Row>
              <Autocomplete
                multiple
                name="position"
                label="Position"
                items={position}
                selected={selectedPosition}
                onChange={(items) => {
                  setSelectedPosition(items);
                }}
                formatLabel={(item) => `${item}`}
              />
            </Row>

            <Row>
              <Autocomplete
                label="Course Status"
                items={courseStatusOptions}
                selected={selectedCourseStatus}
                onChange={(items) => {
                  setCourseStatus(items);
                }}
                formatLabel={(item) => `${item.name}`}
              />
            </Row>
            <Row>
              <InputForm name="trainingSlot" label="Training slots" />
            </Row>

            <Row>
              <DateTimePickerCustom label="Start date" name="trainingStartDate" />
            </Row>
            <Row>
              <DateTimePickerCustom label="End date" name="trainingEndDate" />
            </Row>

            <Row>
              <FormControlLabel
                control={<Checkbox onChange={handleAllCrews} checked={handleChecked} />}
                label="All crews"
              />
              <Autocomplete
                multiple
                name="crew"
                label="Crews to add"
                items={crews}
                selected={selectedCrews}
                onChange={(items) => {
                  setSelectedCrews(items);
                }}
                formatLabel={(item) => item.companyName}
              />
            </Row>
            <BasePaper>
              <Row>
                <StyledSwitch
                  title="is e-learning"
                  checked={isELearning === 1}
                  onChange={() => {
                    const changeIsELearning = isELearning === 1 ? 0 : 1;
                    setIsELearning(changeIsELearning);
                  }}
                />
                E-Learning Module
              </Row>
              {/* 
                        {isELearning == 1  &&
                        <Row>
                          <Autocomplete
                            label="Connected e-learning"
                            items={courses}
                            selected={selectedCourses}
                            multiple
                            onChange={(items) => {
                              setSelectedCourses(items);
                            }}
                            formatLabel={(item) => `(${item.id}) ${item.displayname}`}
                          />
                        </Row> 
                        }

                        {isELearning == 1  &&
                          <Row>
                            <Checkbox    checked={eLearningCheck === 1 } onChange={handleELearningCheck}/> E-Learning Test
                          </Row>
                        }
            */}
              {isELearning == 1 && (
                <Row>
                  <Autocomplete
                    label="E-learning test"
                    items={eLearningMoodle}
                    selected={selectedELearningMoodle}
                    onChange={(items) => {
                      setELearningMoodleCourses(items);
                    }}
                    formatLabel={(item) => `(${item.id}) ${item.displayname}`}
                  />
                </Row>
              )}
            </BasePaper>

            <BasePaper>
              <Row>
                <StyledSwitch
                  title="is classroom"
                  checked={isClassroom === 1}
                  onChange={() => {
                    const changeIsClassroom = isClassroom === 1 ? 0 : 1;
                    setIsClassroom(changeIsClassroom);
                  }}
                />
                Classroom Training Module
              </Row>

              {isClassroom == 1 && (
                <Row>
                  <Autocomplete
                    label="Classroom"
                    items={classroomOptions}
                    selected={selectedClassroomOptions}
                    onChange={(items) => {
                      setClassroomOptions(items);
                    }}
                    formatLabel={(item) => `${item.name}`}
                  />
                </Row>
              )}

              {isClassroom == 1 && selectedClassroomOptions.code === "on_site" && (
                <Row>
                  <InputForm name="location" label="Location" />
                </Row>
              )}
              {isClassroom == 1 && selectedClassroomOptions.code === "virtual_classroom" && (
                <Row>
                  <InputForm name="virtualClassroomLink" label="Vitual Classrorom Link" />
                </Row>
              )}
              {isClassroom == 1 && (
                <Row>
                  <InputForm name="classroomResourceLink" label="Link course documents" />
                </Row>
              )}

              {isClassroom == 1 && (
                <Row>
                  <Checkbox checked={classroomAttendance === 1} onChange={handleClassroomAttendance} /> Classroom
                  Attendance Check
                </Row>
              )}

              {isClassroom == 1 && (
                <Row>
                  <Checkbox checked={classroomCheck === 1} onChange={handleClassroomCheck} /> Classroom Test
                </Row>
              )}

              {classroomCheck == 1 && (
                <Row>
                  <Checkbox checked={writtenTest === 1} onChange={handleWrittenTest} /> Classroom Written Test
                </Row>
              )}

              {isClassroom == 1 && writtenTest == 0 && (
                <Row>
                  <Autocomplete
                    label="Classroom Moodle Test"
                    items={classroomMoodle}
                    selected={selectedClassroomMoodle}
                    onChange={(items) => {
                      setELearningClassroomMoodle(items);
                    }}
                    formatLabel={(item) => `${item.displayname}`}
                  />
                </Row>
              )}

              {isClassroom == 1 && selectedTrainingContext === "individual" && (
                <Row>
                  <Autocomplete
                    label="Trainers"
                    items={Trainers}
                    selected={selectedTrainers}
                    multiple
                    onChange={(items) => {
                      setSelectedTrainers(items);
                    }}
                    formatLabel={(item) => `(${item.id}) ${item.fullname}`}
                  />
                </Row>
              )}
            </BasePaper>

            <BasePaper>
              <Row>
                <StyledSwitch
                  title="is practical"
                  checked={isPractical === 1}
                  onChange={() => {
                    const changeIsPractical = isPractical === 1 ? 0 : 1;
                    setIsPractical(changeIsPractical);
                  }}
                />
                Practical Instruction Module
              </Row>

              {isPractical == 1 && (
                <>
                  <Row>
                    <Autocomplete
                      multiple
                      label="Practical Training"
                      items={practicalTrainingOptions}
                      selected={selectedPracticalTrainingOptions}
                      onChange={(items) => {
                        setPracticalTrainingOptions(items);
                      }}
                      formatLabel={(item) => `${item.name}`}
                    />
                  </Row>
                  <Divider />
                </>
              )}

              {isPractical == 1 && (
                <>
                  <Row>
                    <Checkbox checked={instructorComplete === 1} onChange={handleInstructorComplete} /> Confirm
                    Instruction completed
                  </Row>

                  {selectedTrainingContext === "individual" && (
                    <Row>
                      <Autocomplete
                        label="Instructor"
                        items={Instructors}
                        selected={selectedInstructors}
                        multiple
                        onChange={(items) => {
                          setSelectedInstructors(items);
                        }}
                        formatLabel={(item) => `(${item.id}) ${item.fullname}`}
                      />
                    </Row>
                  )}
                  <Divider />
                </>
              )}

              {isPractical == 1 && selectedPracticalTrainingOptions.some((el) => el.code === "face_to_face") && (
                <>
                  <Row>
                    <Checkbox checked={assessorPassed === 1} onChange={handleAssessorPassed} /> Practical assessment
                  </Row>

                  {selectedTrainingContext === "individual" && (
                    <Row>
                      <Autocomplete
                        label="Assessor"
                        items={Assessors}
                        selected={selectedAssessors}
                        multiple
                        onChange={(items) => {
                          setSelectedAssessors(items);
                        }}
                        formatLabel={(item) => `(${item.id}) ${item.fullname}`}
                      />
                    </Row>
                  )}
                  <Divider />
                </>
              )}

              {isPractical == 1 &&
                selectedPracticalTrainingOptions.some((el) => el.code === "simulation" || el.code === "helicopter") && (
                  <>
                    <Row>
                      <Checkbox checked={checkerPassed === 1} onChange={handleCheckerPassed} /> Checker Passed Check
                    </Row>
                    {selectedTrainingContext === "individual" && (
                      <Row>
                        <Autocomplete
                          label="Checker"
                          items={Checkers}
                          selected={selectedCheckers}
                          multiple
                          onChange={(items) => {
                            setSelectedCheckers(items);
                          }}
                          formatLabel={(item) => `(${item.id}) ${item.fullname}`}
                        />
                      </Row>
                    )}
                    <Divider />
                  </>
                )}
            </BasePaper>

            <Row>
              <Button type="submit">Save</Button>
              <Button onClick={goBack}>Cancel</Button>
            </Row>
          </Form>
        </BasePaper>
      ) : null}
    </>
  );
};

export default TrainingEditForm;
