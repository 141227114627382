import Paper from "@mui/material/Paper";

import Button from "../../components/Button";

import "./index.scss";
import { addNgftApps } from "../../api/ngftApp";

import { Form, Row, InputForm, Textarea, Validation } from "../../components/Form";

const validation = {
  name: Validation.required,
  shortName: Validation.required,
  color: Validation.required,
  textColor: Validation.required,
  icon: Validation.required,
};

const NgftAppsAdd = ({ history }) => {
  async function addNgftAppsForm(data) {
    const result = await addNgftApps(data);
    if (result.ok) {
      history.push("/ngft_apps");
    }
  }

  return (
    <>
      <h2>Add NGFT APP</h2>
      <Paper variant="outlined" style={{ padding: "1rem 1.5rem" }}>
        <Form onSubmit={addNgftAppsForm} validation={validation}>
          <Row>
            <InputForm label="Name app*" name="name" />
          </Row>
          <Row>
            <InputForm label="Short name*" name="shortName" />
          </Row>
          <Row>
            <InputForm label="Background Color*" name="color" />
          </Row>
          <Row>
            <InputForm label="Text color*" name="textColor" />
          </Row>
          <Row>
            <Textarea label="Icon*" name="icon" />
          </Row>

          <Row>
            <InputForm label="Link*" name="link" />
          </Row>

          <Button type="submit" title="Add NGFT APP">
            Add NGFT APP
          </Button>
          <Button title="Go back" type="button" onClick={() => history.goBack()}>
            Cancel
          </Button>
        </Form>
      </Paper>
    </>
  );
};

export default NgftAppsAdd;
