import { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Button from "../Button";
import { BasePaper, Row } from "../Base";
import Autocomplete from "../Form/StyledAutocomplete";

const EditProperties = ({ relations, onSubmit }) => {
  const categories = relations.map((category) => ({
    id: category.id,
    name: category.name,
  }));
  const [positions, setPositions] = useState([]);
  const [operations, setOperations] = useState([]);

  const [category, setCategory] = useState({});
  const [position, setPosition] = useState({});
  const [operation, setOperation] = useState([]);
  const [error, setError] = useState(null);
  const [enable, setEnable] = useState("no");

  const setCat = (category) => {
    setCategory(category);
    setPosition(null);
    setOperation([]);

    const cat = relations.find((c) => c?.id === category?.id);
    setPositions(cat?.positions);
    setOperations([]);
  };

  const setPos = (position) => {
    setPosition(position);
    setOperation([]);

    const pos = positions.find((p) => p?.id === position?.id);
    setOperations(pos?.operations);
  };

  const setOp = (operation) => {
    setOperation(operation);
  };

  const submit = async () => {
    if (!position?.id || !category?.id) {
      setError("Please select position");
      return;
    } else {
      setError(null);
    }
    const ops = operation || [];
    if (!operation?.length) {
      ops.push({ id: null });
    }
    const res = await onSubmit(
      ops.map((op) => ({
        categoryId: category?.id,
        positionId: position?.id,
        operationId: op.id,
      })),
    );
    if (res) {
      setCategory(null);
      setPosition(null);
      setOperation([]);
    } else {
      setError("Can not assign to position");
    }
  };

  return (
    <>
      <BasePaper>
        <Row display={"flex"}>
          <Box width={"33%"} mr={1}>
            <Autocomplete onChange={setCat} selected={category} items={categories} label="Select Category" />
          </Box>
          <Box width={"33%"} mr={1}>
            <Autocomplete label="Select Position" items={positions} selected={position} onChange={setPos} />
          </Box>
          <Box width={"33%"}>
            <Autocomplete label="Select Operation" onChange={setOp} selected={operation} items={operations} multiple />
          </Box>
        </Row>
        <Button title="Save changes" onClick={submit}>
          Save
        </Button>
        {error ? (
          <Box>
            <Typography color="error">{error}</Typography>
          </Box>
        ) : null}
      </BasePaper>
    </>
  );
};
export default EditProperties;
