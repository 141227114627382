import React from "react";

import ExpandableSection from "../../components/ExpandableSection";
import ExpandableSectionTitle from "../../components/ExpandableSection/ExpandableSectionTitle";

import AircraftList from "../../components/Certificate/Aircraft";
import SpecializationsService from "./service";

const Specializations = ({ certificates, courses, properties, aircraft }) => {
  const aircraftWithPositions = SpecializationsService.getSpecializations(aircraft, courses, properties);
  const specializationsDisplay = SpecializationsService.toDisplayObject(
    aircraftWithPositions,
    certificates,
    courses.relations,
  );
  return (
    <>
      {specializationsDisplay.map((displayObj, index) => (
        <ExpandableSection key={`specialization-${index}`} title={<ExpandableSectionTitle title={displayObj.title} />}>
          <AircraftList aircraft={displayObj.aircraft} />
        </ExpandableSection>
      ))}
    </>
  );
};

export default Specializations;
