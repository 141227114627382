import React, { useState, useEffect } from "react";
import PostAddIcon from "@mui/icons-material/PostAdd";

import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import Confirm from "../../components/Confirm";

import CoursesGroupItem from "./CoursesGroupItem";

import { getGroupsCourses, deleteCoursesGroupById } from "../../api/coursesGroups";

const CoursesGroupsTable = ({ match, history }) => {
  const [groups, setGroups] = useState([]);
  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id) {
    setSelectedOperationId(id);
    setIsDeleteModalShow(true);
  }
  function onEdit(id) {
    history.push(`${match.path}/${id}`);
  }
  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteCoursesGroupById(selectedOperationId);
    }
    setIsDeleteModalShow(false);

    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      const newGroups = await getGroupsCourses();
      setGroups(newGroups);
    })();
  }, [refresh]);

  return (
    <>
      <h2>Groups</h2>
      <TableComponent
        TopComponent={
          <Button size="small" onClick={() => history.push(`${match.path}/add_courses_group`)}>
            <PostAddIcon />
            Add courses group
          </Button>
        }
        header={[
          { title: "ID", sort: "id", style: { width: "5%" } },
          { title: "Group Name", sort: "groupName" },
          { title: "Related Courses" },
          { title: "Actions" },
        ]}
        renderChildren={(group) => (
          <CoursesGroupItem key={group.id} onDelete={showDeleteModal} onEdit={onEdit} group={group} />
        )}
        items={groups}
        search={["groupName", "id"]}
        pagination
      />
      {isDeleteModalShow && selectedOperationId ? (
        <Confirm title={"Delete Operation"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the operation?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default CoursesGroupsTable;
