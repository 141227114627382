import React from "react";
import ReactDOM from "react-dom/client";
import { initAuth } from "./services/auth";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./translation/en.json";
import de from "./translation/de.json";
import it from "./translation/it.json";
import fr from "./translation/fr.json";

import App from "./App";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "de", "it", "fr"],
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
      it: {
        translation: it,
      },
      fr: {
        translation: fr,
      },
    },
    lng: "en",
    fallbackLng: "en",
    detection: {
      order: ["cookie", "localStorage", "htmlTag", "path", "htmlTag"],
      caches: ["cookie"],
    },
    react: { useSuspense: false },
  });

(async () => {
  try {
    await initAuth();
  } catch (e) {}

  const root = ReactDOM.createRoot(document.getElementById("main") as HTMLElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
})();
