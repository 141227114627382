import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import EditIcon from "@mui/icons-material/Edit";
import PostAddIcon from "@mui/icons-material/PostAdd";

import TableComponent from "../../components/TableComponent";
import Button from "../../components/Button";
import { Row, StyledSwitch } from "../../components/Base";

import useCrew from "../../hooks/useCrew";

import { getFrats, toggleFratStatus } from "../../api/frat";

const FratList = ({ match, history }) => {
  const { crew } = useCrew();
  const [frats, setFrats] = useState([]);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    (async () => {
      const res = await getFrats(crew.id);
      setFrats(res);
    })();
  }, [crew, refresh]);

  const changeFratStatus = async (fratId) => {
    await toggleFratStatus(crew.id, fratId);
    setRefresh(refresh + 1);
  };

  return (
    <>
      <Row>
        <Typography variant="h4">Frat List</Typography>
      </Row>
      {frats ? (
        <TableComponent
          items={frats}
          search={["name"]}
          TopComponent={
            <Button size="small" onClick={() => history.push(`${match.url}/add`)}>
              <PostAddIcon /> Add Frat
            </Button>
          }
          header={[
            { title: "ID", sort: "id", style: { width: "10%" } },
            { title: "Name", sort: "name", style: { width: "70%" } },
            { title: "Status", style: { width: "10%" } },
            { title: "Actions", style: { width: "10%" } },
          ]}
          renderChildren={(frat) => (
            <TableRow>
              <TableCell>{frat.id}</TableCell>
              <TableCell>{frat.name}</TableCell>
              <TableCell>
                <StyledSwitch
                  title="Enable/Disable FRAT"
                  size="small"
                  checked={frat.status !== "disabled"}
                  onClick={() => changeFratStatus(frat.id)}
                />
              </TableCell>
              <TableCell>
                {frat.crewId === crew.id ? (
                  <Button onClick={() => history.push(`${match.url}/edit/${frat.id}`)} icon={<EditIcon />} />
                ) : null}
              </TableCell>
            </TableRow>
          )}
        />
      ) : null}
    </>
  );
};

export default FratList;
