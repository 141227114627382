import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "../../components/Button";

import { addSpecialization } from "../../api/specializations";

import "./index.scss";

const SPECIALIZATION_ERROR_TEXT = "Please enter the name of specialization";

const SpecializationsAdd = ({ history }) => {
  const [specializationName, setSpecializationName] = useState("");
  const [specializationErrorText, setSpecializationErrorText] = useState(null);

  function handleSpecializationName(value) {
    setSpecializationName(value);
    if (!value) return setSpecializationErrorText(SPECIALIZATION_ERROR_TEXT);
    setSpecializationErrorText(null);
  }

  async function addNewSpecialization() {
    if (!specializationName) return handleSpecializationName(specializationName);

    const result = await addSpecialization(specializationName);
    if (result.ok) {
      history.push("/specializations");
    }
  }

  return (
    <>
      <h2>Add specialization</h2>
      <Paper variant="outlined" style={{ padding: "1rem 1.5rem" }}>
        <TextField
          className="add-field"
          variant="outlined"
          size="small"
          label="Specialization name*"
          value={specializationName}
          onChange={(event) => handleSpecializationName(event.target.value)}
          error={!!specializationErrorText}
          helperText={specializationErrorText}
        />
        <Button title="Add new specialization" onClick={addNewSpecialization}>
          Add Specialization
        </Button>
        <Button title="Go back" onClick={() => history.goBack()}>
          Cancel
        </Button>
      </Paper>
    </>
  );
};

export default SpecializationsAdd;
