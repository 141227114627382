import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";
import { getUsersInscribed } from "../../api/training";
import AssignmentIcon from "@mui/icons-material/Assignment";

const UsersInscribedTraining = ({ match, history }) => {
  const [usersInscribed, setUsersInscribed] = useState([]);
  const [refresh, setRefresh] = useState(1);

  useEffect(() => {
    (async () => {
      const trainingId = match.params.trainingId;
      const usersInscribed = await getUsersInscribed(trainingId);
      setUsersInscribed(usersInscribed);
    })();
  }, [refresh]);

  return (
    <>
      <Typography variant="h4">Users Inscribed</Typography>
      {usersInscribed ? (
        <TableComponent
          header={[{ title: "User ID", style: { width: "5%" } }, { title: "User" }, { title: "Actions" }]}
          renderChildren={(userInscribed, index) => (
            <TableRow key={index}>
              <TableCell>{userInscribed.user_id}</TableCell>
              <TableCell>{userInscribed.User.fullname}</TableCell>
              <TableCell>
                <Button
                  icon={<PublishedWithChangesIcon />}
                  onClick={() =>
                    history.push(
                      `/training-users/user/progress/${userInscribed.training_id}/${userInscribed.user_id}/${userInscribed.course_id}`,
                    )
                  }
                  title="Check progress"
                />
                <Button
                  icon={<AssignmentIcon />}
                  onClick={() =>
                    history.push(`/training-users/user/assessment/${userInscribed.id}/${userInscribed.operation_id}`)
                  }
                  title="Take assessment"
                />
              </TableCell>
            </TableRow>
          )}
          search={["user_id", "fullname", "company_name"]}
          pagination
          items={usersInscribed}
        />
      ) : null}
      <Button title="Back" onClick={() => history.goBack()}>
        Back
      </Button>
    </>
  );
};

export default UsersInscribedTraining;
