import React, { useState, useEffect, useRef } from "react";

import Typography from "@mui/material/Typography";

import RolesTreeComponent from "../../components/RolesTree";
import RolesTreeForm from "../../components/RolesTree/RolesTreeForm";
import { useTranslation } from "react-i18next";
import {
  getRolesWithPermissions,
  setNewPermissions,
  deletePermissionFromRole,
  resetRolePermissions,
} from "../../api/roles";

import RolesService from "./service";

const RolesTree = ({ match }) => {
  const { crewId } = match.params;
  const scrollToRef = useRef();
  const [roles, setRoles] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [tree, setTree] = useState(null);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const response = await getRolesWithPermissions(crewId);
      const tree = RolesService.map(response, +crewId);
      setTree(tree);
      setRoles(response.roles);
      setPermissions(response.permissions);
    })();
  }, [refresh, crewId]);

  const onChange = (type, item) => {
    setError(null);
    if (type === "role") {
      setSelectedRole(item);
      const permissions = tree.find((r) => r.id === item?.id)?.permissions;
      setSelectedPermissions(permissions || []);
    } else {
      setSelectedPermissions(item || []);
    }
    scrollToRef.current.scrollIntoView();
  };

  const onSave = async () => {
    if (!selectedRole) {
      setError("Please select role");
    }
    const role = selectedRole.id;
    const permissions = selectedPermissions?.map((p) => p.id);

    const response = await setNewPermissions(role, permissions, crewId);
    if (response.ok) {
      onChange("role", null);
      setRefresh(refresh + 1);
    }
  };

  const onDelete = async (roleId, permissionId) => {
    const response = await deletePermissionFromRole(roleId, permissionId, crewId);
    if (response.ok) {
      setRefresh(refresh + 1);
    }
  };

  const onResetDefault = async (role) => {
    const response = await resetRolePermissions(role?.id, crewId);
    if (response.ok) {
      onChange("role", null);
      setRefresh(refresh + 1);
    }
  };

  return (
    <>
      <Typography variant="h4">{t("roles_and_permissions")}</Typography>
      {roles ? (
        <div ref={scrollToRef}>
          <RolesTreeForm
            roles={roles}
            permissions={permissions}
            selectedRole={selectedRole}
            selectedPermissions={selectedPermissions}
            onChange={onChange}
            onReset={onResetDefault}
            onSave={onSave}
            error={error}
          />{" "}
        </div>
      ) : null}
      {tree ? (
        <RolesTreeComponent
          onClick={(role) => {
            onChange("role", role);
          }}
          onRemove={onDelete}
          roles={tree}
        />
      ) : null}
    </>
  );
};

export default RolesTree;
