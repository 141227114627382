import React, { useState, useEffect } from "react";

import PostAddIcon from "@mui/icons-material/PostAdd";
import Button from "../../components/Button";
import TableComponent from "../../components/TableComponent";

import Confirm from "../../components/Confirm";
import AircraftItem from "./AircraftItem";

import { getAircraft, deleteAircraftById, updateAircraft } from "../../api/aircraft";

const AircraftTable = ({ match, history }) => {
  const [aircraft, setAircraft] = useState([]);
  const [selectedAircraftId, setSelectedAircraftId] = useState(null);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [refresh, setRefresh] = useState(1);

  function showDeleteModal(id) {
    setSelectedAircraftId(id);
    setIsDeleteModalShow(true);
  }

  function onEdit(id) {
    history.push(`${match.path}/${id}`);
  }

  async function onStatusChange(id, newStatus) {
    await updateAircraft(id, { status: newStatus });

    setRefresh(refresh * -1);
  }

  async function confirmDeleteModal(isConfirmed) {
    if (isConfirmed) {
      await deleteAircraftById(selectedAircraftId);
    }
    setIsDeleteModalShow(false);

    setRefresh(refresh * -1);
  }

  useEffect(() => {
    (async () => {
      let newAircraft = await getAircraft();
      setAircraft(newAircraft);
    })();
  }, [refresh]);

  return (
    <>
      <h2>Aircraft</h2>
      <TableComponent
        TopComponent={
          <Button size="small" onClick={() => history.push(`${match.path}/add_aircraft`)}>
            <PostAddIcon />
            Add new aircraft
          </Button>
        }
        header={[
          { title: "ID", sort: "id", style: { width: "5%" } },
          { title: "Name", sort: "name" },
          { title: "Status", sort: "status", style: { width: "15%" } },
          { title: "Actions", style: { width: "15%" } },
        ]}
        renderChildren={(aircraft) => (
          <AircraftItem
            key={aircraft.id}
            onEdit={onEdit}
            onDelete={showDeleteModal}
            onStatusChange={onStatusChange}
            aircraft={aircraft}
          />
        )}
        items={aircraft}
        search={["name", "id"]}
        pagination
      />
      {isDeleteModalShow && selectedAircraftId ? (
        <Confirm title={"Delete Aircraft"} onConfirm={confirmDeleteModal}>
          <p>Are you sure you want to delete the aircraft?</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default AircraftTable;
