import { SelectableWithIdObject } from "src/components/Form/types";
import { Category, mapCategory } from "./categories";
import request from "./request";
import { BasicResponse } from "./base";

interface PositionResponse {
  id: number;
  name: string;
  status?: string;
  category?: Category;
}

export type PositionRequest = {
  name: string;
  categoryId: number;
};

export class Position extends SelectableWithIdObject {
  public name: string;
  public status?: string;
  public category?: Category;

  constructor(data: PositionResponse) {
    super(data.id);
    this.name = data.name;
    this.status = data.status;
    if (data.category) {
      this.category = mapCategory(data.category);
    }
  }

  getDisplayName(): string {
    return this.name;
  }
}

export const mapPosition = (data: PositionResponse): Position => {
  return new Position(data);
};

export const getPositions = async (): Promise<Position[]> => {
  const response = await request(`/positions`);
  return response.map((data: PositionResponse) => mapPosition(data));
};

export const getPositionsActive = async (): Promise<Position[]> => {
  const response = await request(`/positions/active`);
  return response.map((data: PositionResponse) => mapPosition(data));
};

export const getPositionById = async (positionId: number): Promise<Position> => {
  const response = await request(`/positions/${positionId}`);
  return mapPosition(response);
};

export const addPosition = async (positionName: string, categoryId: number): Promise<BasicResponse> => {
  const response = await request(`/positions`, "POST", {
    name: positionName,
    categoryId: categoryId,
  });

  return response;
};

export const updatePosition = async (positionId: number, newPosition: PositionRequest): Promise<BasicResponse> => {
  const response = await request(`/positions/${positionId}`, "PUT", newPosition);

  return response;
};

export const deletePositionById = async (positionId: number): Promise<BasicResponse> => {
  const json = await request(`/positions/${positionId}`, "DELETE");

  return json;
};
